<template>
  <div>
    <label :class="error ? '' : ''">{{ label }}
      <a-tooltip v-if="tooltip" placement="right">
        <template #title>
          <span>{{ tooltip }}</span>
        </template>
        <i class="mdi mdi-information"/>
      </a-tooltip>
    </label>
    <div class="input-group">
      <a-textarea @input="onSubmit" :value="data" :name="id" class="form-control form-placeholder" />
      <div v-if="error" class="invalid-feedback error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-text-area',
  props: ['id', 'label', 'placeholder', 'data', 'error', 'tooltip'],
  data () {
    return {
      errors: false
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (e) {
      this.submit({ name: this.id, value: e.target.value })
    }
  }
}
</script>
