import { createStore } from 'vuex'

import { alertModule } from './alert.module'
import { authModule } from './auth.module'
import { surveyModule } from './survey.module'
import { jumbaModule } from './jumba.module'

export default createStore({
  modules: {
    alert: alertModule,
    auth: authModule,
    survey: surveyModule,
    jumba: jumbaModule
  }
})
