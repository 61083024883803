export function getAge (dateOfBirth) {
  if (dateOfBirth === null) {
    return 0
  }
  const newDateOfBirth = new Date(parseInt(dateOfBirth.substring(6, 10)), parseInt(dateOfBirth.substring(3, 5)), parseInt(dateOfBirth.substring(0, 2)))
  const ageDate = new Date(Date.now() - newDateOfBirth.getTime())
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function addTime (time) {
  return Date.now() + time
}

export function isExpired (expiresAt) {
  return expiresAt ? Date.now() > expiresAt : true
}
