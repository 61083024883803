<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.current_living_condition.title }}</h1>
          <p>{{ data.current_living_condition.description }}</p>
          <a-row align="middle" justify="center" class="pt-10 flex-row-align-center">
            <a-col span="10">
              <a-button
                  @click="onChange('current_living_condition.sections.section_1.questions.i_have_a_house.value', 0)"
                  size="large"
                  style="width: 100%"
                  :class="data.current_living_condition.sections.section_1.questions.i_have_a_house.value == 0 ? 'primary' : 'inverted'">
                {{ data.current_living_condition.sections.section_1.questions.i_have_a_house.options[0].label }}
              </a-button>
            </a-col>
            <a-col span="4" class="flex-align-center">
              <i class="mdi mdi-chevron-left" style="font-size: 1.5rem; float: left"></i>
              <strong
                  :class="submitted && data.current_living_condition.sections.section_1.questions.i_have_a_house.error !== null ? 'error-text' : ''"
                  style="text-align: center; font-size: 0.75rem">
                {{ data.current_living_condition.sections.section_1.questions.i_have_a_house.error }}
              </strong>
              <i class="mdi mdi-chevron-right" style="font-size: 1.5rem; float: right"></i>
            </a-col>
            <a-col span="10">
              <a-button
                  @click="onChange('current_living_condition.sections.section_1.questions.i_have_a_house.value', 1)"
                  size="large"
                  style="width: 100%"
                  :class="data.current_living_condition.sections.section_1.questions.i_have_a_house.value == 1 ? 'primary' : 'inverted'">
                {{ data.current_living_condition.sections.section_1.questions.i_have_a_house.options[1].label }}
              </a-button>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" class="pt-10 flex-row-align-center">
            <a-col span="10">
              <a-button
                  @click="onChange('current_living_condition.sections.section_1.questions.i_want_a_new_house.value', 0)"
                  size="large"
                  style="width: 100%"
                  :class="data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value == 0 ? 'primary' : 'inverted'">
                {{ data.current_living_condition.sections.section_1.questions.i_want_a_new_house.options[0].label }}
              </a-button>
            </a-col>
            <a-col span="4" class="flex-align-center">
              <i class="mdi mdi-chevron-left" style="font-size: 1.5rem; float: left"></i>
              <strong
                  :class="submitted && data.current_living_condition.sections.section_1.questions.i_want_a_new_house.error !== null ? 'error-text' : ''"
                  style="text-align: center; font-size: 0.75rem">
                {{ data.current_living_condition.sections.section_1.questions.i_want_a_new_house.error }}
              </strong>
              <i class="mdi mdi-chevron-right" style="font-size: 1.5rem; float: right"></i>
            </a-col>
            <a-col span="10">
              <a-button
                  @click="onChange('current_living_condition.sections.section_1.questions.i_want_a_new_house.value', 1)"
                  size="large"
                  style="width: 100%"
                  :class="data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value == 1 ? 'primary' : 'inverted'">
                {{ data.current_living_condition.sections.section_1.questions.i_want_a_new_house.options[1].label }}
              </a-button>
            </a-col>
          </a-row>
          <a-row align="middle" justify="center" class="pt-10 flex-row-align-center"
                 v-if="data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value == 0">
            <a-col span="10">
              <a-button
                  @click="onChange('current_living_condition.sections.section_1.questions.i_want_a_existing_house.value', 0)"
                  size="large"
                  style="width: 100%"
                  :class="data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.value == 0 ? 'primary' : 'inverted'">
                {{ data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.options[0].label }}
              </a-button>
            </a-col>
            <a-col span="4" class="flex-align-center">
              <i class="mdi mdi-chevron-left" style="font-size: 1.5rem; float: left"></i>
              <strong
                  :class="submitted && data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.error !== null ? 'error-text' : ''"
                  style="text-align: center; font-size: 0.75rem">
                {{ data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.error }}
              </strong>
              <i class="mdi mdi-chevron-right" style="font-size: 1.5rem; float: right"></i>
            </a-col>
            <a-col span="10">
              <a-button
                  @click="onChange('current_living_condition.sections.section_1.questions.i_want_a_existing_house.value', 1)"
                  size="large"
                  style="width: 100%"
                  :class="data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.value == 1 ? 'primary' : 'inverted'"
              >
                {{
                  data.current_living_condition.sections.section_1.questions.i_want_a_existing_house.options[1].label
                }}
              </a-button>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="24">
              <br/>
            </a-col>
          </a-row>
          <div v-if="data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value == 1"
               class="well">
            <a-row>
              <a-col span="24">
                <h2 class="pt-10">Huidige woning</h2>
                <a-row>
                  <a-col span="24" v-if="data.customer.address.street.value">
                    <my-jumba
                        id="my-jumba"
                        :postal_code="data.customer.address.postal_code.value"
                        :number="data.customer.address.number.value"
                        :addition="data.customer.address.addition.value"/>
                  </a-col>
                  <a-col :sm="24" v-if="!data.customer.address.street.value">
                    Geen adres opgegeven
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div v-if="data.current_living_condition.sections.section_1.questions.i_want_a_new_house.value == 0"
               class="well">
            <a-row>
              <a-col span="24">
                <h2 class="pt-10">Gewenste woning</h2>
                <a-row>
                  <a-col span="24">
                    <table class="ant-table">
                      <tbody class="ant-table-body">
                      <input-radio
                          id="current_living_condition.sections.section_1.questions.new_house.value"
                          :label="data.current_living_condition.sections.section_1.questions.new_house.title"
                          :data="data.current_living_condition.sections.section_1.questions.new_house.value"
                          :options="data.current_living_condition.sections.section_1.questions.new_house.options"
                          :error="submitted && data.current_living_condition.sections.section_1.questions.new_house.error !== null"
                      />
                      </tbody>
                    </table>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row class="pt-10" :gutter="10"
                   v-if="data.current_living_condition.sections.section_1.questions.new_house.value == 1">
              <a-col :sm="24" :md="24" :lg="18">
                <a-row :gutter="10">
                  <a-col :sm="24" :md="10">
                    <input-text
                        id="current_living_condition.sections.section_1.questions.postal_code.value"
                        :label="data.current_living_condition.sections.section_1.questions.postal_code.title"
                        :data="data.current_living_condition.sections.section_1.questions.postal_code.value"
                        :error="submitted && data.current_living_condition.sections.section_1.questions.postal_code.error !== null"
                    />
                  </a-col>
                  <a-col :sm="12" :md="7">
                    <input-text
                        id="current_living_condition.sections.section_1.questions.number.value"
                        :label="data.current_living_condition.sections.section_1.questions.number.title"
                        :data="data.current_living_condition.sections.section_1.questions.number.value"
                        :error="submitted && data.current_living_condition.sections.section_1.questions.number.error !== null"
                    />
                  </a-col>
                  <a-col :sm="12" :md="7">
                    <input-text
                        id="current_living_condition.sections.section_1.questions.addition.value"
                        :label="data.current_living_condition.sections.section_1.questions.addition.title"
                        :data="data.current_living_condition.sections.section_1.questions.addition.value"
                        :error="submitted && data.current_living_condition.sections.section_1.questions.addition.error !== null"
                    />
                  </a-col>
                </a-row>
              </a-col>
              <a-col :sm="24" :md="12" :lg="10">
                <jumba-single-component
                    id="current_living_condition.sections.section_1.questions.jumba"
                    :postal_code="data.current_living_condition.sections.section_1.questions.postal_code.value"
                    :number="data.current_living_condition.sections.section_1.questions.number.value"
                    :addition="data.current_living_condition.sections.section_1.questions.addition.value"/>
              </a-col>
            </a-row>
          </div>
          <div class="well mt-20">
            <a-row :gutter="50" class="pt-20 section-question">
              <a-col span="24">
                <h2>{{ data.current_living_condition.sections.section_5.title }}</h2>
              </a-col>
              <a-col class="pt-20">
                <strong
                    :class="submitted && data.current_living_condition.sections.section_5.questions.goal.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_5.questions.goal.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="current_living_condition.sections.section_5.questions.goal.value"
                    :label="data.current_living_condition.sections.section_5.questions.goal.title"
                    :data="data.current_living_condition.sections.section_5.questions.goal.value"
                    :options="data.current_living_condition.sections.section_5.questions.goal.options"
                    :error="submitted && data.current_living_condition.sections.section_5.questions.goal.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="current_living_condition.sections.section_5.questions.goal.explanation.value"
                                 :data="data.current_living_condition.sections.section_5.questions.goal.explanation.value"
                                 :placeholder="data.current_living_condition.sections.section_5.questions.goal.explanation.title"
                                 :label="data.current_living_condition.sections.section_5.questions.goal.explanation.title"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well">
            <h2 class="pt-20">{{ data.current_living_condition.sections.section_4.title }}</h2>
            <a-row v-if="data.customer.has_partner.value == 1" class="pt-20 section-question" :gutter="50">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_3.questions.whos_the_owner.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_3.questions.whos_the_owner.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="current_living_condition.sections.section_3.questions.whos_the_owner.value"
                    :label="data.current_living_condition.sections.section_3.questions.whos_the_owner.title"
                    :data="data.current_living_condition.sections.section_3.questions.whos_the_owner.value"
                    :options="data.current_living_condition.sections.section_3.questions.whos_the_owner.options"
                    :error="submitted && data.current_living_condition.sections.section_3.questions.whos_the_owner.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area
                    v-if="data.current_living_condition.sections.section_3.questions.whos_the_owner.value == 3"
                    id="current_living_condition.sections.section_3.questions.whos_the_owner.explanation.value"
                    :data="data.current_living_condition.sections.section_3.questions.whos_the_owner.explanation.value"
                    :label="data.current_living_condition.sections.section_3.questions.whos_the_owner.explanation.title"
                    :placeholder="data.current_living_condition.sections.section_3.questions.whos_the_owner.explanation.title"
                />
              </a-col>
            </a-row>
            <a-row v-if="data.customer.has_partner.value == 1" class="pt-20 section-question" :gutter="50">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_3.questions.whos_income.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_3.questions.whos_income.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="current_living_condition.sections.section_3.questions.whos_income.value"
                    :label="data.current_living_condition.sections.section_3.questions.whos_income.title"
                    :data="data.current_living_condition.sections.section_3.questions.whos_income.value"
                    :options="data.current_living_condition.sections.section_3.questions.whos_income.options"
                    :error="submitted && data.current_living_condition.sections.section_3.questions.whos_income.error !== null"
                />
              </a-col>
            </a-row>
<!--            <a-row class="pt-20">-->
<!--              <a-col></a-col>-->
<!--              <a-col>-->
<!--                <input-text-area-->
<!--                    :label="data.current_living_condition.sections.section_3.questions.whos_income.explanation.title"-->
<!--                    id="current_living_condition.sections.section_3.questions.whos_income.explanation.value"-->
<!--                    :placeholder="data.current_living_condition.sections.section_3.questions.whos_income.explanation.title"-->
<!--                    :data="data.current_living_condition.sections.section_3.questions.whos_income.explanation.value"-->
<!--                />-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row class="pt-20 section-question" :gutter="50">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_4.questions.live_style.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_4.questions.live_style.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="current_living_condition.sections.section_4.questions.live_style.value"
                    :label="data.current_living_condition.sections.section_4.questions.live_style.title"
                    :data="data.current_living_condition.sections.section_4.questions.live_style.value"
                    :options="data.current_living_condition.sections.section_4.questions.live_style.options"
                    :error="submitted && data.current_living_condition.sections.section_4.questions.live_style.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="current_living_condition.sections.section_4.questions.live_style.explanation.value"
                                 :label="data.current_living_condition.sections.section_4.questions.live_style.explanation.title"
                                 :placeholder="data.current_living_condition.sections.section_4.questions.live_style.explanation.title"
                                 :data="data.current_living_condition.sections.section_4.questions.live_style.explanation.value"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20 section-question" :gutter="50">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_4.questions.my_income_5_years.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_4.questions.my_income_5_years.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="current_living_condition.sections.section_4.questions.my_income_5_years.value"
                    :label="data.current_living_condition.sections.section_4.questions.my_income_5_years.title"
                    :data="data.current_living_condition.sections.section_4.questions.my_income_5_years.value"
                    :options="data.current_living_condition.sections.section_4.questions.my_income_5_years.options"
                    :error="submitted && data.current_living_condition.sections.section_4.questions.my_income_5_years.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area
                    id="current_living_condition.sections.section_4.questions.my_income_5_years.explanation.value"
                    :label="data.current_living_condition.sections.section_4.questions.my_income_5_years.explanation.title"
                    :placeholder="data.current_living_condition.sections.section_4.questions.my_income_5_years.explanation.title"
                    :data="data.current_living_condition.sections.section_4.questions.my_income_5_years.explanation.value"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20 section-question">
              <a-col>
                <strong>{{
                    data.current_living_condition.sections.section_4.questions.max_netto_mortgage.title
                  }}</strong>
              </a-col>
              <a-col :sm="24" :md="24" :lg="5"></a-col>
              <a-col :sm="24" :md="24" :lg="8">
                <input-money
                    label=""
                    id="current_living_condition.sections.section_4.questions.max_netto_mortgage.value"
                    :data="data.current_living_condition.sections.section_4.questions.max_netto_mortgage.value"
                    :error="submitted ? data.current_living_condition.sections.section_4.questions.max_netto_mortgage.error : null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area
                    id="current_living_condition.sections.section_4.questions.max_netto_mortgage.explanation.value"
                    :label="data.current_living_condition.sections.section_4.questions.max_netto_mortgage.explanation.title"
                    :placeholder="data.current_living_condition.sections.section_4.questions.max_netto_mortgage.explanation.title"
                    :data="data.current_living_condition.sections.section_4.questions.max_netto_mortgage.explanation.value"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20 section-question">
              <a-col>
                <strong>Veranderingen in uw persoonlijke of financiële situatie kunnen gevolgen hebben
                  voor de betaalbaarheid van uw hypotheeklasten. Hoe waarschijnlijk is het dat onderstaande
                  veranderingen binnen vijf jaar gaan plaatsvinden?</strong>
              </a-col>
              <a-col style="width:100%">
                <table class="ant-table">
                  <thead class="ant-table-head">
                    <tr class="ant-table-row">
                      <th class="ant-table-row-cell" colspan="0.5"></th>
                      <th class="ant-table-row-cell">Onwaarschijnlijk</th>
                      <th class="ant-table-row-cell">Kans is aanwezig</th>
                      <th class="ant-table-row-cell">Zeer waarschijnlijk</th>
                    </tr>
                  </thead>
                  <input-radio
                      id="current_living_condition.sections.section_4.questions.moving.value"
                      class="pt-5"
                      :label="data.current_living_condition.sections.section_4.questions.moving.title"
                      :data="data.current_living_condition.sections.section_4.questions.moving.value"
                      :options="data.current_living_condition.sections.section_4.questions.moving.options"
                      :error="submitted && data.current_living_condition.sections.section_4.questions.moving.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_4.questions.heritage.value"
                      class="pt-5"
                      :label="data.current_living_condition.sections.section_4.questions.heritage.title"
                      :data="data.current_living_condition.sections.section_4.questions.heritage.value"
                      :options="data.current_living_condition.sections.section_4.questions.heritage.options"
                      :error="submitted && data.current_living_condition.sections.section_4.questions.heritage.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_4.questions.family_expansion.value"
                      class="pt-5"
                      :label="data.current_living_condition.sections.section_4.questions.family_expansion.title"
                      :data="data.current_living_condition.sections.section_4.questions.family_expansion.value"
                      :options="data.current_living_condition.sections.section_4.questions.family_expansion.options"
                      :error="submitted && data.current_living_condition.sections.section_4.questions.family_expansion.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_4.questions.study_costs_children.value"
                      class="pt-5"
                      :label="data.current_living_condition.sections.section_4.questions.study_costs_children.title"
                      :data="data.current_living_condition.sections.section_4.questions.study_costs_children.value"
                      :options="data.current_living_condition.sections.section_4.questions.study_costs_children.options"
                      :error="submitted && data.current_living_condition.sections.section_4.questions.study_costs_children.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_4.questions.working_less.value"
                      class="pt-5"
                      :label="data.current_living_condition.sections.section_4.questions.working_less.title"
                      :data="data.current_living_condition.sections.section_4.questions.working_less.value"
                      :options="data.current_living_condition.sections.section_4.questions.working_less.options"
                      :error="submitted && data.current_living_condition.sections.section_4.questions.working_less.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_4.questions.other_possibility.value"
                      class="pt-5"
                      :label="data.current_living_condition.sections.section_4.questions.other_possibility.title"
                      :data="data.current_living_condition.sections.section_4.questions.other_possibility.value"
                      :options="data.current_living_condition.sections.section_4.questions.other_possibility.options"
                      :error="submitted && data.current_living_condition.sections.section_4.questions.other_possibility.error !== null"
                  />
                </table>
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area
                    id="current_living_condition.sections.section_4.questions.changes_financial_state_explanation.value"
                    :placeholder="data.current_living_condition.sections.section_4.questions.changes_financial_state_explanation.title"
                    :label="data.current_living_condition.sections.section_4.questions.changes_financial_state_explanation.title"
                    :data="data.current_living_condition.sections.section_4.questions.changes_financial_state_explanation.value"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20 section-question" :gutter="50">
              <a-col :sm="12" :md="12" :lg="16">
                <strong
                    :class="submitted && data.current_living_condition.sections.section_4.questions.savings.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_4.questions.savings.title }}
                </strong>
              </a-col>
              <a-col :sm="12" :md="12" :lg="8">
                <input-money
                    id="current_living_condition.sections.section_4.questions.savings.value"
                    :data="data.current_living_condition.sections.section_4.questions.savings.value"
                    :error="submitted && data.current_living_condition.sections.section_4.questions.savings.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20 section-question" :gutter="50">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_4.questions.saving_goals.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_4.questions.saving_goals.title }}
                  <small v-if="data.current_living_condition.sections.section_4.questions.saving_goals.error !== null">
                    {{ data.current_living_condition.sections.section_4.questions.saving_goals.error }}
                  </small>
                </strong>
              </a-col>
              <a-col>
                <div class="pt-5"
                     v-for="(question, q) in data.current_living_condition.sections.section_4.questions.saving_goals.questions" :key="question.value">
                  <input-check-box
                      :id="`current_living_condition.sections.section_4.questions.saving_goals.questions.${q}.value`"
                      :label="question.title"
                      :data="question.value"
                  />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20 section-question">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_4.questions.low_mortgage.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_4.questions.low_mortgage.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="current_living_condition.sections.section_4.questions.low_mortgage.value"
                    :label="data.current_living_condition.sections.section_4.questions.low_mortgage.title"
                    :data="data.current_living_condition.sections.section_4.questions.low_mortgage.value"
                    :options="data.current_living_condition.sections.section_4.questions.low_mortgage.options"
                    :error="submitted && data.current_living_condition.sections.section_4.questions.low_mortgage.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area
                    v-if="data.current_living_condition.sections.section_4.questions.low_mortgage.value == 1"
                    id="current_living_condition.sections.section_4.questions.low_mortgage.explanation.value"
                    :placeholder="data.current_living_condition.sections.section_4.questions.low_mortgage.explanation.title"
                    :label="data.current_living_condition.sections.section_4.questions.low_mortgage.explanation.title"
                    :data="data.current_living_condition.sections.section_4.questions.low_mortgage.explanation.value"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20 section-question">
              <a-col>
                <strong
                    :class="submitted && data.current_living_condition.sections.section_4.questions.loans_and_liabilities.error !== null ? 'error-text' : ''">
                  {{ data.current_living_condition.sections.section_4.questions.loans_and_liabilities.title }}
                  <small
                      v-if="data.current_living_condition.sections.section_4.questions.loans_and_liabilities.error !== null">
                    {{ data.current_living_condition.sections.section_4.questions.loans_and_liabilities.error }}
                  </small>
                </strong>
              </a-col>
              <a-col>
                <div class="pt-5"
                     v-for="(question, q) in data.current_living_condition.sections.section_4.questions.loans_and_liabilities.questions" :key="question.value">
                  <input-check-box
                      :id="`current_living_condition.sections.section_4.questions.loans_and_liabilities.questions.${q}.value`"
                      :label="question.title"
                      :data="question.value"
                  />
                </div>
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area
                    id="current_living_condition.sections.section_4.questions.saving_money_explanation.value"
                    :placeholder="data.current_living_condition.sections.section_4.questions.saving_money_explanation.title"
                    :label="data.current_living_condition.sections.section_4.questions.saving_money_explanation.title"
                    :data="data.current_living_condition.sections.section_4.questions.saving_money_explanation.value"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well">
            <h2 class="pt-20">{{ data.current_living_condition.sections.section_6.title }}</h2>
            <a-row class="section-question">
              <a-col class="ant-col-textarea">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="current_living_condition.sections.section_6.questions.have_will.value"
                      class="pt-5"
                      :data="data.current_living_condition.sections.section_6.questions.have_will.value"
                      :label="data.current_living_condition.sections.section_6.questions.have_will.title"
                      :options="data.current_living_condition.sections.section_6.questions.have_will.options"
                      :error="submitted && data.current_living_condition.sections.section_6.questions.have_will.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_6.questions.have_life_will.value"
                      class="pt-5"
                      :data="data.current_living_condition.sections.section_6.questions.have_life_will.value"
                      :label="data.current_living_condition.sections.section_6.questions.have_life_will.title"
                      :options="data.current_living_condition.sections.section_6.questions.have_life_will.options"
                      :error="submitted && data.current_living_condition.sections.section_6.questions.have_life_will.error !== null"
                  />
                  <input-radio
                      id="current_living_condition.sections.section_6.questions.is_will_old.value"
                      class="pt-5"
                      :data="data.current_living_condition.sections.section_6.questions.is_will_old.value"
                      :label="data.current_living_condition.sections.section_6.questions.is_will_old.title"
                      :options="data.current_living_condition.sections.section_6.questions.is_will_old.options"
                      :error="submitted && data.current_living_condition.sections.section_6.questions.is_will_old.error !== null"
                  />
                  <input-radio
                    id="current_living_condition.sections.section_6.questions.have_will_advice.value"
                    class="pt-5"
                    :data="data.current_living_condition.sections.section_6.questions.have_will_advice.value"
                    :label="data.current_living_condition.sections.section_6.questions.have_will_advice.title"
                    :options="data.current_living_condition.sections.section_6.questions.have_will_advice.options"
                    :error="submitted && data.current_living_condition.sections.section_6.questions.have_will_advice.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
          </div>
          <a-row :gutter="50" class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="current_living_condition" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CURRENT_LIVING_CONDITION, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import InputText from '../../../components/Input/InputText'
import InputRadio from '../../../components/Input/InputRadio'
import InputMoney from '../../../components/Input/InputMoney'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import InputTextArea from '../../../components/Input/InputTextArea'
import JumbaSingleComponent from '../../../components/Jumba/JumbaSingleComponent'
import MyJumba from '../../../components/Jumba/MyJumba'
import { alertService } from '@/services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-current-living-condition',
  components: {
    InputTextArea,
    InputRadioGroup,
    InputText,
    InputCheckBox,
    InputMoney,
    SideNav,
    InputRadio,
    MyJumba,
    JumbaSingleComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onChange (name, value) {
      this.submit({ name, value })
    },
    onPrevious (data) {
      router.push(previousRoute(CURRENT_LIVING_CONDITION, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: CURRENT_LIVING_CONDITION, data })
      if (data.status.current_living_condition) {
        router.push(nextRoute(CURRENT_LIVING_CONDITION, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
