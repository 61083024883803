<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.moving.title }}</h1>
          <p>{{ data.moving.description }}</p>
          <strong>Risico's:</strong>
          <ul>
            <li>De gewenste woning is niet realiseerbaar</li>
          </ul>
          <strong>Mogelijke oplossingen:</strong>
          <ul>
            <li>Spaargeld</li>
            <li>Verhuizen naar een andere woonplaats</li>
            <li>Versneld aflossen</li>
            <li>Meer werken</li>
            <li>Minder werken</li>
          </ul>
          <div class="well">
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24" :md="24">
                <h2>{{ data.moving.sections.section_31.title }}</h2>
                <p>{{ data.moving.sections.section_31.description }}</p>
              </a-col>
              <a-col :sm="24" :md="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="moving.sections.section_31.questions.i_expect_to_move.value"
                      :data="data.moving.sections.section_31.questions.i_expect_to_move.value"
                      :label="data.moving.sections.section_31.questions.i_expect_to_move.title"
                      :options="data.moving.sections.section_31.questions.i_expect_to_move.options"
                      :error="submitted && data.moving.sections.section_31.questions.i_expect_to_move.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="50" v-if="data.moving.sections.section_31.questions.i_expect_to_move.value == 1"
                   class="pt-20">
              <a-col :sm="12" :md="12" :lg="12">
                <strong>{{ data.moving.sections.section_31.questions.moving_in_years.title }}</strong>
              </a-col>
              <a-col :sm="12" :md="12" :lg="12">
                <input-select
                    id="moving.sections.section_31.questions.moving_in_years.value"
                    :data="data.moving.sections.section_31.questions.moving_in_years.value"
                    :options="data.moving.sections.section_31.questions.moving_in_years.options"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well" v-if="data.moving.sections.section_31.questions.i_expect_to_move.value == 1">
            <a-row :gutter="50" class="pt-20">
              <a-col span="24">
                <h2>{{ data.moving.sections.section_32.title }}</h2>
                <p>{{ data.moving.sections.section_32.description }}</p>
              </a-col>
              <a-col :sm="12" :md="14" :lg="12">
                <input-select
                    id="moving.sections.section_32.questions.jumba.home_type.value"
                    :label="data.moving.sections.section_32.questions.jumba.home_type.title"
                    :data="data.moving.sections.section_32.questions.jumba.home_type.value"
                    :options="data.moving.sections.section_32.questions.jumba.home_type.options"
                />
              </a-col>
              <a-col :sm="12" :md="10" :lg="6">
                <input-text
                    id="moving.sections.section_32.questions.jumba.search_postal_code.value"
                    :label="data.moving.sections.section_32.questions.jumba.search_postal_code.title"
                    :data="data.moving.sections.section_32.questions.jumba.search_postal_code.value"
                />
              </a-col>
              <a-col :sm="12" :md="14" :lg="6">
                <input-select
                    id="moving.sections.section_32.questions.jumba.search_radius.value"
                    :label="data.moving.sections.section_32.questions.jumba.search_radius.title"
                    :data="data.moving.sections.section_32.questions.jumba.search_radius.value"
                    :options="data.moving.sections.section_32.questions.jumba.search_radius.options"
                />
              </a-col>
            </a-row>
            <a-row :gutter="10" class="pt-20">
              <a-col :sm="24">
                <jumba-component
                    id="moving.sections.section_32.questions.jumba"
                    :value="data.moving.sections.section_32.questions.jumba.jumba_id.value"
                    :postal_code="data.moving.sections.section_32.questions.jumba.search_postal_code.value"
                    :radius="data.moving.sections.section_32.questions.jumba.search_radius.value"
                    :type="data.moving.sections.section_32.questions.jumba.home_type.value"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="moving" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile, MOVING, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import InputText from '../../../components/Input/InputText'
import InputSelect from '../../../components/Input/InputSelect'
import InputRadio from '../../../components/Input/InputRadio'
import JumbaComponent from '../../../components/Jumba/JumbaComponent'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-moving',
  components: {
    SideNav,
    InputText,
    InputSelect,
    InputRadio,
    JumbaComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['validate', 'submit']),
    onChangeMovingInYears (value) {
      this.submit({ name: 'moving.sections.section_31.questions.moving_in_years.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(MOVING, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: MOVING, data })
      if (data.status.moving) {
        router.push(nextRoute(MOVING, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
