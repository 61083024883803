<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="debug-block">
      <h1>Debug</h1>
      <p>Welkom op het debug-scherm. Hier kun je verschillende functionaliteiten testen.</p>
      <a-row :gutter="50" class="pt-10">
        <a-col span="12">
          <h2>{{ access_token !== null ? 'Account' : 'Inloggen' }}</h2>
          <a-row :gutter="50">
            <a-col span="18">
              <div>
                <label>Kies een uitnodigingstype</label>
                <div class="input-group">
                  <a-select :defaultValue="invitationType" style="width: 100%" @change="onSelectInvitationType"
                            :value="invitationType">
                    <a-select-option v-for="option in invitationTypes" :value="option.value" :key="option.value">{{
                        option.label
                      }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="50" class="pt-10">
            <a-col span="24">
              <a-button :disabled="status.loading" @click="onDebug" class="primary" v-if="access_token !== null">
                <img v-show="status.loading" alt="Laden..."
                     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                Intro
              </a-button>
              <a-button :disabled="status.loading" @click="onLogin" class="inverted" v-if="access_token === null">
                <img v-show="status.loading" alt="Laden..."
                     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                Inloggen
              </a-button>
              <a-button :disabled="status.loading" @click="onLogout" class="inverted" v-if="access_token !== null">
                <img v-show="status.loading" alt="Laden..."
                     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                Uitloggen
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col span="12">
          <h2>Gebruikersnaam testen</h2>
          <a-row :gutter="50">
            <a-col span="18">
              <form @submit.prevent="onTestUsername">
                <div class="form-group">
                  <label>Gebruikersnaam&nbsp;</label>
                  <a-tooltip placement="right">
                    <template #title>
                      <span>De gebruikersnaam mag geen !#$%^&*()+ bevatten en moet minimaal 4 karakters bevatten.</span>
                    </template>
                    <i class="mdi mdi-information"/>
                  </a-tooltip>
                  <a-input type="text" :value="username" label="Gebruikersnaam" placeholder="Gebruikersnaam"
                           name="username" v-on:keyup.enter="onTestUsername" @input="setUsername"
                           :class="{ 'form-control': true, 'is-invalid': submitted && !username }"/>
                  <div v-show="submitted && errors.username !== null" class="invalid-feedback error-text">
                    {{ errors.username }}
                  </div>
                  <div v-show="submitted && success.username !== null" class="valid-feedback success-text">
                    {{ success.username }}
                  </div>
                </div>
              </form>
            </a-col>
          </a-row>
          <a-row :gutter="50" class="pt-10">
            <a-col span="24">
              <a-button :disabled="status.loading" @click="onTestUsername" class="inverted">
                <img v-show="status.loading"
                     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>&nbsp;&nbsp;Testen
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-row :gutter="50" class="pt-20">
        <a-col span="12">
          <h2>Welke browser heb ik?</h2>
          <p>In de onderstaande lijst kun je raadplegen welke browser je gebruikt.</p>
          <table>
            <tr>
              <td><i :class="isChrome ? 'mdi mdi-check success-text' : 'mdi mdi-close error-text'"></i></td>
              <td>Chrome</td>
            </tr>
            <tr>
              <td><i :class="isFirefox ? 'mdi mdi-check success-text' : 'mdi mdi-close error-text'"></i></td>
              <td>Firefox</td>
            </tr>
            <tr>
              <td><i :class="isEdge ? 'mdi mdi-check success-text' : 'mdi mdi-close error-text'"></i></td>
              <td>Edge</td>
            </tr>
            <tr>
              <td><i :class="isIE ? 'mdi mdi-check success-text' : 'mdi mdi-close error-text'"></i></td>
              <td>Internet Explorer</td>
            </tr>
            <tr>
              <td><i :class="isSafari ? 'mdi mdi-check success-text' : 'mdi mdi-close error-text'"></i></td>
              <td>Safari</td>
            </tr>
            <tr>
              <td><i :class="isOpera ? 'mdi mdi-check success-text' : 'mdi mdi-close error-text'"></i></td>
              <td>Opera</td>
            </tr>
          </table>
        </a-col>
      </a-row>
      <a-row :gutter="50" class="pt-10">
        <a-col span="24">
          <h2>Browser downloaden</h2>
          <p>Kies één van de ondersteunde browsers.</p>
        </a-col>
        <a-col span="8" v-for="browser in browsers" :key="browser.name">
          <div :class="browsers.length > 1 ? 'cta center' : 'center'">
            <img :src="browser.logo" style="height: 160px; width: 160px"/>
            <h3>{{ browser.name }}</h3>
            <a-button class="primary" @click="getBrowser(browser)">
              Downloaden
            </a-button>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  FULL,
  getSupportedBrowsers,
  isChrome,
  isFirefox,
  isIE,
  isEdge,
  isSafari,
  isOpera,
  isMobile,
  router,
  getInvitationTypes
} from '../../../helpers'
import { alertService } from '@/services'

export default {
  name: 'DebugComponent',
  data () {
    return {
      username: '',
      submitted: false,
      invitationType: FULL,
      invitationTypes: getInvitationTypes(),
      browsers: getSupportedBrowsers(),
      isMobile: isMobile(),
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isEdge: isEdge(),
      isIE: isIE(),
      isSafari: isSafari(),
      isOpera: isOpera()
    }
  },
  computed: {
    ...mapState('auth', ['status', 'errors', 'success', 'access_token']),
    ...mapState('survey', ['style'])
  },
  methods: {
    ...mapActions('auth', ['setUser', 'testUsername', 'logout']),
    getBrowser (browser) {
      const win = window.open(browser.url, '_blank')
      win.focus()
    },
    onLogin (e) {
      if (this.invitationType) {
        const redirectSuccess = this.$route.query.redirect_success ? decodeURI(this.$route.query.redirect_success) : null
        const redirectFailure = this.$route.query.redirect_fail ? decodeURI(this.$route.query.redirect_fail) : null
        this.setUser({
          invitationType: this.invitationType, redirectSuccess, redirectFailure
        })
      } else {
        alertService.onError('Kies een uitnodigingstype')
      }
    },
    onDebug (e) {
      if (this.invitationType) {
        router.push(`debug_intro?invitationType=${this.invitationType}`)
      } else {
        alertService.onError('Kies een uitnodigingstype')
      }
    },
    onSelectInvitationType (invitationType) {
      this.invitationType = invitationType
    },
    onLogout (e) {
      this.logout({ redirect: '/login' })
    },
    setUsername (e) {
      this.username = e.target.value
    },
    onTestUsername (e) {
      this.submitted = true
      const { username } = this
      this.testUsername({ username })
    }
  }
}
</script>
