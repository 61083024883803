<template>
  <a-row>
    <a-col>
      <img v-show="data.status.loading"
           src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
      <div v-if="!data.status.loading">
        <a-card style="width: 250px; height: 250px;" v-if="data.data.result && data.data.result.image">
          <img :src="getImage(data.data.result.image)" slot="cover" style="max-width: 200px; max-height: 200px"/>
        </a-card>
        <div v-if="!data.data.result.image">
          <p>Geen resultaat</p>
          <a-button @click="onSearch" class="btn-small inverted">
            Opnieuw zoeken
          </a-button>
        </div>
      </div>
    </a-col>
    <a-col v-if="data.data.result && data.data.result.address" style="margin-left: 15px">
      <strong>{{ data.data.result.address.street }}
        {{ data.data.result.address.number }}{{ data.data.result.address.number_addition }}</strong><br/>
      <span>{{ data.data.result.address.postcode }} {{ data.data.result.address.city }}</span><br/>
    </a-col>
  </a-row>
</template>

<script>
import { mapActions } from 'vuex'
import { env } from '../../helpers/env'

export default {
  name: 'my-jumba',
  props: ['id', 'postal_code', 'number', 'addition'],
  computed: {
    data () {
      return this.$store.state.jumba
    }
  },
  mounted: function () {
    this.onSearch()
  },
  methods: {
    ...mapActions('jumba', ['properties']),
    getImage (value) {
      return value + `?validate=true&key=${env.jumbaAPIKey}`
    },
    getDescription (item) {
      if (!item.value_high) {
        return ''
      }
      return `€ ${(item.value_high).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.')}`
    },
    onSearch (e) {
      if (this.postal_code.length > 5 && this.number.length > 0) {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          if (this.addition) {
            this.properties({ id: `${this.postal_code}${this.number}${this.addition}`, limit: 1 })
          } else {
            this.properties({ id: `${this.postal_code}${this.number}`, limit: 1 })
          }
        }, 800)
      }
    }
  }
}
</script>
