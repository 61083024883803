<template>
  <a-row>
    <a-col :sm="24" :md="24" :lg="24">
      <a-button @click="onSearch" class="inverted" :disabled="data.status.loading">
        <img v-show="data.status.loading"
             src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>&nbsp;&nbsp;Zoek
        woningen
      </a-button>
      <br/><br/>
    </a-col>
    <a-col :sm="24" :md="24" :lg="24" v-if="data.data.results ? data.data.results.length > 0 : false">
      <strong>Selecteer de woning die uw voorkeur heeft:</strong><br/>
    </a-col>
    <a-col :sm="6" :md="6" :lg="6" v-for="item in getItems(data.data.results)" :key="item.id" style="display: inline;">
      <jumba-card :value="value" :item="item" @clicked="onSubmit(item)"/>
    </a-col>
  </a-row>
</template>

<script>
import { mapActions } from 'vuex'
import JumbaCard from './JumbaCard'
import { alertService } from '../../services'

export default {
  name: 'jumba-component',
  components: { JumbaCard },
  props: ['id', 'postal_code', 'radius', 'type', 'value'],
  computed: {
    data () {
      return this.$store.state.jumba
    }
  },
  mounted: function () {
    const postalCode = this.postal_code.trim()
    if (postalCode.length === 6) {
      this.onSearch()
    }
  },
  methods: {
    ...mapActions('jumba', ['search']),
    ...mapActions('survey', ['submit']),
    getItems (results) {
      const items = []
      results.forEach(result => {
        if (result.value_high > 0) {
          items.push(result)
        }
      })
      return items
    },
    onSearch (e) {
      const postalCode = this.postal_code.replace(/\s+/g, '')
      if (postalCode.length === 6) {
        const q = `postal_code=${postalCode}&radius=${this.radius}&type=${this.type}`
        this.search({ q, limit: 12, sort: 'price' })
      } else {
        alertService.onError('Postcode moet bestaan uit 6 karakters')
      }
    },
    onSubmit (item) {
      this.submit({ name: this.id + '.jumba_id.value', value: item.id })
      this.submit({ name: this.id + '.postal_code.value', value: item.address.postcode })
      this.submit({ name: this.id + '.number.value', value: item.address.number })
      this.submit({ name: this.id + '.number_addition.value', value: item.address.number_addition })
      this.submit({ name: this.id + '.street.value', value: item.address.street })
      this.submit({ name: this.id + '.city.value', value: item.address.city })
      this.submit({ name: this.id + '.value_low.value', value: item.value_low })
      this.submit({ name: this.id + '.value_high.value', value: item.value_high })
    }
  }
}
</script>
