<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.sustainability.title }}</h1>
          <p>{{ data.sustainability.description }}</p>
          <div class="well mt-20">
            <a-row :gutter="50" class="mt-20">
              <a-col span="24">
                <h2>{{ data.sustainability.sections.section_1.title }}</h2>
                <p>{{ data.sustainability.sections.section_1.description }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50">
              <a-col :sm="24" :md="24" :lg="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="sustainability.sections.section_1.questions.energy_saving_measures.value"
                      class="pt-5"
                      :data="data.sustainability.sections.section_1.questions.energy_saving_measures.value"
                      :label="data.sustainability.sections.section_1.questions.energy_saving_measures.title"
                      :options="data.sustainability.sections.section_1.questions.energy_saving_measures.options"
                      :error="submitted && data.sustainability.sections.section_1.questions.energy_saving_measures.error !== null"
                  />
                  <input-radio
                      id="sustainability.sections.section_1.questions.savings.value"
                      class="pt-5"
                      :data="data.sustainability.sections.section_1.questions.savings.value"
                      :label="data.sustainability.sections.section_1.questions.savings.title"
                      :options="data.sustainability.sections.section_1.questions.savings.options"
                      :error="submitted && data.sustainability.sections.section_1.questions.savings.error !== null"
                  />
                  <input-radio
                      id="sustainability.sections.section_1.questions.financing_sustainability.value"
                      class="pt-5"
                      :data="data.sustainability.sections.section_1.questions.financing_sustainability.value"
                      :label="data.sustainability.sections.section_1.questions.financing_sustainability.title"
                      :options="data.sustainability.sections.section_1.questions.financing_sustainability.options"
                      :error="submitted && data.sustainability.sections.section_1.questions.financing_sustainability.error !== null"
                  />
                  <input-text-area
                      class="mb-15 mt-15"
                      id="sustainability.sections.section_1.explanation.value"
                      :data="data.sustainability.sections.section_1.explanation.value"
                      :placeholder="data.sustainability.sections.section_1.explanation.title"
                      :label="data.sustainability.sections.section_1.explanation.title"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
          </div>
          <div class="well mt-20">
            <a-row :gutter="50" class="mt-20">
              <a-col span="24">
                <h2>{{ data.sustainability.sections.section_2.title }}</h2>
                <p>{{ data.sustainability.sections.section_2.description }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50">
              <a-col :sm="24" :md="24" :lg="16">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                    <input-radio
                        id="sustainability.sections.section_2.questions.interested_sustainability_housing.value"
                        class="pt-5"
                        :data="data.sustainability.sections.section_2.questions.interested_sustainability_housing.value"
                        :label="data.sustainability.sections.section_2.questions.interested_sustainability_housing.title"
                        :options="data.sustainability.sections.section_2.questions.interested_sustainability_housing.options"
                        :error="submitted && data.sustainability.sections.section_2.questions.interested_sustainability_housing.error !== null"
                    />
                    <input-radio
                        id="sustainability.sections.section_2.questions.options_sustainability_housing_mapping.value"
                        class="pt-5"
                        :data="data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.value"
                        :label="data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.title"
                        :options="data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.options"
                        :error="submitted && data.sustainability.sections.section_2.questions.options_sustainability_housing_mapping.error !== null"
                    />
                    <input-radio
                        id="sustainability.sections.section_2.questions.report_sustainability_housing.value"
                        class="pt-5"
                        :data="data.sustainability.sections.section_2.questions.report_sustainability_housing.value"
                        :label="data.sustainability.sections.section_2.questions.report_sustainability_housing.title"
                        :options="data.sustainability.sections.section_2.questions.report_sustainability_housing.options"
                        :error="submitted && data.sustainability.sections.section_2.questions.report_sustainability_housing.error !== null"
                    />
                    <input-radio
                        id="sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.value"
                        class="pt-5"
                        :data="data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.value"
                        :label="data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.title"
                        :options="data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.options"
                        :error="submitted && data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.error !== null"
                    />
                    <input-text-area
                        class="mb-15 mt-15"
                        id="sustainability.sections.section_2.explanation.value"
                        :data="data.sustainability.sections.section_2.explanation.value"
                        :placeholder="data.sustainability.sections.section_2.explanation.title"
                        :label="data.sustainability.sections.section_2.explanation.title"
                    />
                  </tbody>
                </table>
              </a-col>
              <a-col v-if="isEigenhuis" :sm="24" :md="24" :lg="8">
                <div class="info-block" v-html="data.sustainability.sections.section_2.questions.report_sustainability_housing.description" />
              </a-col>
            </a-row>
          </div>
          <div class="well mt-20" v-if="data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.value">
            <a-row :gutter="50" class="mt-20">
              <a-col span="24">
                <h2>{{ data.sustainability.sections.section_3.title }}</h2>
                <p>{{ data.sustainability.sections.section_3.description }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50">
              <a-col :sm="24" :md="24" :lg="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                    <tr class="ant-table-row">
                      <td class="ant-table-row-cell">
                        <p v-html="data.sustainability.sections.section_3.questions.reason_for_sustainability.title"></p>
                      </td>
                    </tr>
                    <tr class="ant-table-row">
                      <td class="ant-table-row-cell">
                        <div
                          class="pt-5"
                          v-for="(question, q) in data.sustainability.sections.section_3.questions.reason_for_sustainability.questions"
                          :key="question.value"
                        >
                            <input-check-box
                              :id="`sustainability.sections.section_3.questions.reason_for_sustainability.questions.${q}.value`"
                              :label="question.title"
                              :data="question.value"
                            />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </a-col>
            </a-row>
          </div>
          <div class="well mt-20" v-if="data.sustainability.sections.section_2.questions.discuss_with_advisor_sustainability_housing.value">
            <a-row :gutter="50" class="mt-20">
              <a-col span="24">
                <h2>{{ data.sustainability.sections.section_4.title }}</h2>
                <p>{{ data.sustainability.sections.section_4.description }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50">
              <a-col :sm="24" :md="24" :lg="16">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio-list
                      id="sustainability.sections.section_4.questions.financing_sustainability.value"
                      class="pt-5"
                      :data="data.sustainability.sections.section_4.questions.financing_sustainability.value"
                      :label="data.sustainability.sections.section_4.questions.financing_sustainability.title"
                      :options="data.sustainability.sections.section_4.questions.financing_sustainability.options"
                      :error="submitted && data.sustainability.sections.section_4.questions.financing_sustainability.error !== null"
                  />
                  <input-text-area
                      class="mb-15 mt-15"
                      id="sustainability.sections.section_4.explanation.value"
                      :data="data.sustainability.sections.section_4.explanation.value"
                      :placeholder="data.sustainability.sections.section_4.explanation.title"
                      :label="data.sustainability.sections.section_4.explanation.title"
                  />
                  </tbody>
                </table>
              </a-col>
              <a-col v-if="isEigenhuis" :sm="24" :md="24" :lg="8">
                <div class="info-block" v-html="data.sustainability.sections.section_4.questions.financing_sustainability.description" />
              </a-col>
            </a-row>
          </div>
          <a-row :gutter="50" class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="sustainability" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SUSTAINABILITY, isMobile, nextRoute, previousRoute, router, isEigenhuis } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import InputRadio from '../../../components/Input/InputRadio'
import InputRadioList from '../../../components/Input/InputRadioList'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import InputTextArea from '../../../components/Input/InputTextArea'
import { alertService } from '@/services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-sustainability',
  components: {
    InputTextArea,
    InputCheckBox,
    SideNav,
    InputRadio,
    InputRadioList,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile(),
      isEigenhuis: isEigenhuis()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onChange (name, value) {
      this.submit({ name, value })
    },
    onPrevious (data) {
      router.push(previousRoute(SUSTAINABILITY, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: SUSTAINABILITY, data })
      if (data.status.sustainability) {
        router.push(nextRoute(SUSTAINABILITY, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
