<template>
  <div class="form-check">
    <a-checkbox @change="onChange" class="form-check-input" :checked="data == 1" type="checkbox" :name="id"/>
    <label class="form-check-label">{{ label }}</label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-check-box',
  props: ['id', 'label', 'data', 'validation'],
  data () {
    return {
      errors: false
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onChange (e) {
      if (e.target.checked) {
        this.submit({ name: this.id, value: 1 })
      } else {
        this.submit({ name: this.id, value: 0 })
      }
    }
  }
}
</script>
