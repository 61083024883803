<template>
  <div>
    <tr class="ant-table-row">
      <td :class="error ? 'ant-table-row-cell error-text' : 'ant-table-row-cell'">{{ label }}
        <a-tooltip v-if="tooltip" placement="right">
          <template #title>
            <span>{{ tooltip }}</span>
          </template>
          <i class="mdi mdi-information"/>
        </a-tooltip>
      </td>
    </tr>
    <div class="mt-15">
      <div v-for="option in options" :key="option.value">
        <tr class="ant-table-row">
          <td class="ant-table-row-cell">
            <a-radio @change="onSubmit" :checked="data == option.value" :name="id" :value="option.value" :disabled="disabled"/>
            <label class="form-check-label" v-if="option.show">{{ option.label }}</label>
            <a-tooltip v-if="option.tooltip" placement="right">
              <template #title>
                <span v-html="option.tooltip" />
              </template>
              <i class="mdi mdi-information"/>
            </a-tooltip>
          </td>
        </tr>
        <tr class="ant-table-row" v-if="option.description">
          <td class="ant-table-row">
            <i v-html="option.description"></i>
          </td>
        </tr>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-radio-list',
  props: ['id', 'label', 'data', 'options', 'error', 'tooltip', 'disabled'],
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (e) {
      this.submit({ name: this.id, value: e.target.value })
    }
  }
}
</script>
