<template>
  <nav class="header">
    <div class="container">
      <div class="logo">
        <span class="logo-helper"></span>
        <img
            v-bind:src="style.logo"
            referrerpolicy="origin"
            style="min-height: 50px"
        />
        <img
            v-if="access_token !== null && officeLogo()"
            v-bind:src="officeLogo()"
            referrerpolicy="origin"
            style="margin-left: 1rem"
        />
      </div>
      <a href="/login" class="btn rounded" v-if="access_token === null">Inloggen</a>
      <a href="#" class="btn rounded" v-if="access_token !== null" v-on:click="onLogout()">Uitloggen</a>
      <a href="#" class="btn rounded" v-if="access_token !== null" v-on:click="openModal()"
         style="display: none; margin-right: 15px">Mijn account</a>
    </div>
    <a-modal v-model="showModal" title="Mijn account" @ok="onSubmit">
      <template slot="footer">
        <a-button key="back" class="btn rounded" @click="closeModal">
          Annuleren
        </a-button>
        <a-button key="submit" class="btn rounded" :disabled="status.loading" @click="onSubmit">
          Opslaan
        </a-button>
      </template>
      <form @submit.prevent="onSubmit">
        <div class="form-group">
          <label>Huidig wachtwoord</label>
          <a-input type="password" :value="password" placeholder="********" name="password"
                   @input="setPassword" v-on:keyup.enter="onSubmit"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password !== null" class="invalid-feedback error-text">
            {{ errors.password }}
          </div>
        </div>
        <div class="form-group">
          <label>Nieuw wachtwoord</label>
          <a-input type="password" :value="passwordNew" placeholder="********" name="passwordNew"
                   @input="setPasswordNew" v-on:keyup.enter="onSubmit"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password_new !== null" class="invalid-feedback error-text">
            {{ errors.password_new }}
          </div>
        </div>
        <div class="form-group">
          <label>Bevestig wachtwoord</label>
          <a-input type="password" :value="passwordConfirm" placeholder="********" name="passwordConfirm"
                   @input="setPasswordConfirm" v-on:keyup.enter="onSubmit"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password_confirm !== null" class="invalid-feedback error-text">
            {{ errors.password_confirm }}
          </div>
        </div>
      </form>
    </a-modal>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'NavBarComponent',
  components: {},
  data () {
    return {
      password: '',
      passwordConfirm: '',
      passwordNew: '',
      submitted: false,
      showModal: false
    }
  },
  computed: {
    ...mapState('auth', ['errors', 'status', 'access_token']),
    ...mapState('survey', ['style', 'customer'])
  },
  methods: {
    ...mapActions('auth', ['logout', 'changePassword']),
    openModal (e) {
      this.showModal = true
    },
    officeLogo () {
      if (this.customer.office.style.logo.value) {
        const contentType = this.customer.office.style.content_type.value
        return `data:${contentType};base64,${this.customer.office.style.logo.value}`
      }
      return null
    },
    closeModal (e) {
      this.showModal = false
    },
    onLogout (e) {
      this.logout({ redirect: '/login' })
    },
    onSubmit (e) {
      this.submitted = true
      const { password, passwordNew, passwordConfirm } = this
      this.changePassword({ password, passwordNew, passwordConfirm })
    },
    setPassword (e) {
      this.password = e.target.value
    },
    setPasswordConfirm (e) {
      this.passwordConfirm = e.target.value
    },
    setPasswordNew (e) {
      this.passwordNew = e.target.value
    }
  }
}
</script>

<style scoped>
.container {
  overflow: hidden;
}
</style>
