<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="login-block">
      <h1>Wachtwoord vergeten</h1>
      <p>Bent u uw wachtwoord vergeten? Vul het onderstaande formulier in om een nieuwe in te stellen. De gegevens
        worden naar uw e-mailadres toegestuurd.</p>
      <form @submit.prevent="onForgot">
        <div class="form-group">
          <label>Gebruikersnaam</label>
          <a-input type="text" :value="username" label="" placeholder="Gebruikersnaam"
                   name="username" v-on:keyup.enter="onForgot" @input="setUsername"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !username }"/>
          <div v-show="submitted && !username" class="invalid-feedback error-text">
            Gebruikersnaam is verplicht
          </div>
        </div>
        <div class="form-group">
          <a-button :disabled="status.loading" @click="onForgot" class="inverted">
            <img v-show="status.loading"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>&nbsp;&nbsp;Versturen
          </a-button>
        </div>
        <div class="form-group link" @click="onLogin">
          <span>Inloggen</span>
        </div>
        <div class="form-group link" @click="onForgotUsername">
          <span>Gebruikersnaam vergeten?</span>
        </div>
        <div class="form-group link">
          &copy; {{ this.style.name }}
        </div>
        <div class="form-group link slogan" v-if="this.style.use_slogan">
          {{ this.style.slogan }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isMobile } from '../../../helpers'

export default {
  name: 'forgot_password',
  data () {
    return {
      username: '',
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('auth', ['status', 'errors']),
    ...mapState('survey', ['style'])
  },
  methods: {
    ...mapActions('auth', ['forgotPassword']),
    setUsername (e) {
      this.username = e.target.value
    },
    onLogin (e) {
      this.$router.push('/login')
    },
    onForgotUsername (e) {
      this.$router.push('/forgot_username')
    },
    onForgot (e) {
      this.submitted = true
      const { username } = this
      if (username) {
        this.forgotPassword({ username })
      }
    }
  }
}
</script>
