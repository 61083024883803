import { alertService, authService } from '../services'
import { addTime, router } from '../helpers'
import * as Sentry from '@sentry/vue'

const auth = JSON.parse(localStorage.getItem('auth'))

export const initialState = {
  status: {
    loading: false
  },
  errors: {
    username: null,
    password: null,
    password_confirm: null,
    password_new: null
  },
  success: {
    username: null,
    password: null,
    password_confirm: null,
    password_new: null
  },
  invitation_token: null,
  redirect_success: null,
  redirect_fail: null,
  access_token: null,
  refresh_token: null,
  expires_in: null,
  username: '',
  iwize_integration: false,
  two_factor_required: false
}

const state = auth
  ? {
      status: {
        loading: false
      },
      errors: {
        username: null,
        password: null,
        password_confirm: null,
        password_new: null
      },
      success: {
        username: null,
        password: null,
        password_confirm: null,
        password_new: null
      },
      invitation_token: auth.invitation_token,
      redirect_success: auth.redirect_success,
      redirect_fail: auth.redirect_fail,
      access_token: auth.access_token,
      refresh_token: auth.refresh_token,
      expires_in: auth.expires_in,
      username: auth.username,
      iwize_integration: auth.iwize_integration,
      two_factor_required: auth.two_factor_required
    }
  : initialState

const actions = {
  testUsername ({ dispatch, commit }, { username }) {
    commit('testUsernameRequest', { username })

    if (!username) {
      commit('testUsernameFailure', { key: 'username', value: 'Gebruikersnaam is verplicht' })
    } else if (username.length < 4) {
      commit('testUsernameFailure', { key: 'username', value: 'Gebruikersnaam moet minimaal 4 tekens lang zijn' })
    } else if (/[^a-zA-Z0-9.@-_]/.test(username)) {
      commit('testUsernameFailure', { key: 'username', value: 'Gebruikersnaam mag geen speciale tekens bevatten' })
    } else {
      commit('testUsernameSuccess', { key: 'username', value: 'Dat ziet er goed uit!' })
    }
  },
  register ({ dispatch, commit }, { username, password, passwordConfirm, token }) {
    commit('registerFailure', { key: 'username', value: null })
    commit('registerFailure', { key: 'password', value: null })
    commit('registerFailure', { key: 'password_confirm', value: null })
    commit('registerRequest', { username })

    // Check
    let isUsername = true
    let isPassword = true

    // Username
    if (!username) {
      commit('registerFailure', { key: 'username', value: 'Er ging iets mis bij het genereren van de gebruikersnaam, neem contact op met uw adviseur' })
      isUsername = false
    } else if (username.length < 4) {
      commit('registerFailure', { key: 'username', value: 'Er ging iets mis bij het genereren van de gebruikersnaam, neem contact op met uw adviseur' })
      isUsername = false
    }

    // Password
    if (!password) {
      commit('registerFailure', { key: 'password', value: 'Vul een geldig wachtwoord in (minimaal 8 tekens)' })
      isPassword = false
    }

    // Confirm password
    if (!passwordConfirm) {
      commit('registerFailure', { key: 'password_confirm', value: 'Wachtwoord is verplicht' })
      isPassword = false
    } else if (password !== passwordConfirm) {
      commit('registerFailure', { key: 'password', value: 'Wachtwoorden komen niet overeen' })
      isPassword = false
    } else if (password.length < 8) {
      commit('registerFailure', { key: 'password', value: 'Het wachtwoord moet minimaal 8 tekens lang zijn' })
      isPassword = false
    }

    // Attempt
    if (isUsername && isPassword) {
      authService.register(username, password, token).then(response => {
        if (response.status === 200) {
          commit('registerSuccess', token)
          router.push('/login')
        } else {
          const error = response.error.errors.username ? response.error.errors.username[0] : 'Registreren mislukt'
          commit('registerFailure', { key: 'username', value: error })
        }
      }, (response) => {
        const error = response.error.errors.username ? response.error.errors.username[0] : 'Probeer een andere gebruikersnaam'
        commit('registerFailure', { key: 'username', value: error })
      }).catch((response) => {
        commit('registerFailure', { key: 'username', value: 'Er is iets mis gegaan' })
      })
    }
  },
  validate ({ dispatch, commit }, { token }) {
    commit('validateRequest', { token })
    authService.validate(token).then(response => {
      if (response.success) {
        commit('validateSuccess', response)
      } else {
        commit('validateFailure')
        alertService.onError('Uitnodiging al gebruikt')
        router.push('/login')
      }
    }).catch(error => {
      commit('validateFailure', error)
      alertService.onError('Uitnodiging niet geldig')
      router.push('/login')
    })
  },
  setUser ({ dispatch, commit }, { invitationType, redirectSuccess, redirectFailure }) {
    commit('setUserRequest')
    authService.setUser().then(response => {
      const data = response.data
      commit('setUserSuccess', data)
      data.redirect_success = redirectSuccess
      data.redirect_fail = redirectFailure
      data.expires_at = addTime(data.expires_in)
      localStorage.setItem('auth', JSON.stringify(data))
      router.push(`/debug_intro?invitationType=${invitationType}`)
    }).catch(error => {
      commit('setUserFailure', error)
      alertService.onError('Inloggen is mislukt')
    })
  },
  login ({ dispatch, commit }, { username, password, invitationToken, token, redirectSuccess, redirectFailure, twoFactorEnabled, twoFactorCode }) {
    commit('loginRequest')
    authService.login(username, password, token, twoFactorEnabled, twoFactorCode).then(response => {
      const twoFactorRequired = response?.response?.data?.message?.includes('allows two factor authentication')
      commit('twoFactorRequired', twoFactorRequired)
      if (twoFactorRequired) {
        authService.requestTwoFactorCode(username).then(response => {
          alertService.onSuccess('Er is een code naar je e-mail gestuurd. Controleer ook je spamfolder.')
        }).catch(error => { // eslint-disable-line
          alertService.onError('2FA code aanvragen mislukt')
        })
      }
      if (!response) {
        commit('loginFailure')
        alertService.onError('Login gegevens niet correct')
      } else {
        const data = response.data
        data.invitation_token = invitationToken
        data.redirect_success = redirectSuccess
        data.redirect_fail = redirectFailure
        // The token expires in 1 hour
        // When we make a custom implementation of vendor/laravel/passport/src/Http/Controllers/AccessTokenController.php:48
        // so that we can configure the expires_in time, we can return the original line:
        // data.expires_at = addTime(data.expires_in)
        data.expires_at = addTime(3600000)
        commit('loginSuccess', data)
        Sentry.addBreadcrumb({
          type: 'user_action',
          level: 'info',
          message: 'The user logged in',
          data: {
            username
          }
        })
        localStorage.clear()
        localStorage.setItem('auth', JSON.stringify(data))
        router.push('/intro').finally(() => {
          router.go(1)
        })
      }
    }).catch(error => {
      commit('loginFailure', error)
      alertService.onError('Inloggen is mislukt')
    })
  },
  forgotUsername ({ commit }, { email }) {
    commit('forgotUsernameRequest')
    authService.forgotUsername(email).then(response => {
      if (!response) {
        commit('forgotUsernameFailure')
        alertService.onError('Gebuikersnaam verandering is mislukt')
      } else {
        commit('forgotUsernameSuccess', response.data)
        router.push('/login')
      }
    }).catch(error => {
      commit('forgotUsernameFailure', error)
      alertService.onError('Something went wrong')
    })
  },
  forgotPassword ({ commit }, { username }) {
    commit('forgotPasswordRequest')
    authService.forgotPassword(username).then(response => {
      if (!response) {
        commit('forgotPasswordFailure')
        alertService.onError('Wachtwoordherstel is mislukt')
      } else {
        commit('forgotPasswordSuccess', response.data)
        alertService.onSuccess('Het wachtwoordherstel is verstuurd')
        router.push('/login')
      }
    }).catch(error => {
      commit('forgotPasswordFailure', error)
      alertService.onError('Something went wrong')
    })
  },
  changePassword ({ commit }, { currentPassword, newPassword, newPasswordConfirmation }) {
    commit('changePasswordFailure', { key: 'password', value: null })
    commit('changePasswordFailure', { key: 'password_confirm', value: null })
    commit('changePasswordFailure', { key: 'password_new', value: null })
    commit('changePasswordRequest')
    if (!currentPassword) {
      commit('changePasswordFailure', { key: 'password', value: 'Wachtwoord is verplicht' })
    }
    if (!newPassword) {
      commit('changePasswordFailure', { key: 'password_new', value: 'Wachtwoord is verplicht' })
    }
    if (!newPasswordConfirmation) {
      commit('registerFailure', { key: 'password_confirm', value: 'Wachtwoord is verplicht' })
    } else if (newPassword !== newPasswordConfirmation) {
      commit('registerFailure', { key: 'password_new', value: 'Wachtwoorden komen niet overeen' })
    } else if (newPassword.length < 8) {
      commit('registerFailure', { key: 'password_new', value: 'Het wachtwoord moet minimaal 8 karakters lang zijn' })
    } else {
      authService.changePassword(currentPassword, newPassword, newPasswordConfirmation).then(response => {
        if (!response) {
          commit('changePasswordFailure')
          alertService.onError('Wachtwoord veranderen is mislukt')
        } else {
          commit('changePasswordSuccess', response.data)
          alertService.onSuccess('Het wachtwoord is veranderd')
        }
      }).catch(error => {
        commit('changePasswordFailure', error)
        alertService.onError('Something went wrong')
      })
    }
  },
  resetPassword ({ commit }, { token, password, passwordConfirm }) {
    commit('resetPasswordFailure', { key: 'password', value: null })
    commit('resetPasswordFailure', { key: 'password_confirm', value: null })
    if (!password) {
      commit('resetPasswordFailure', { key: 'password', value: 'Wachtwoord is verplicht' })
    } else if (!passwordConfirm) {
      commit('resetPasswordFailure', { key: 'password_confirm', value: 'Bevestig het wachtwoord' })
    } else if (password !== passwordConfirm) {
      commit('resetPasswordFailure', { key: 'password', value: 'Wachtwoorden komen niet overeen' })
    } else if (password.length < 8) {
      commit('resetPasswordFailure', { key: 'password', value: 'Het wachtwoord moet minimaal 8 karakters lang zijn' })
    } else if (password) {
      authService.resetPassword(password, token).then(response => {
        if (response.status === 200) {
          commit('resetPasswordSuccess', token)
          alertService.onSuccess('Het wachtwoordherstel is geslaagd')
          router.push('/login')
        } else if (response.status >= 400 && response.status < 500) {
          commit('resetPasswordFailure')
          alertService.onError('Wachtwoordherstel mislukt: controleer uw gegevens')
        } else {
          commit('resetPasswordFailure')
          alertService.onError('Wachtwoordherstel mislukt: controleer uw gegevens')
        }
      }, () => {
        commit('resetPasswordFailure')
        alertService.onError('Wachtwoordherstel mislukt: controleer uw gegevens')
      }).catch(error => {
        commit('resetPasswordFailure', error)
        alertService.onError('Wachtwoordherstel mislukt: controleer uw gegevens')
      })
    }
  },
  logout ({ commit }, { redirect }) {
    authService.logout()
    commit('logout')

    if (typeof redirect === 'string' && redirect.startsWith('http')) {
      window.location.href = redirect
    } else if (typeof redirect === 'string') {
      router.push(redirect)
    } else {
      router.push('/login')
    }
  },
  getTenantSetting ({ dispatch, commit }, value) {
    commit('getTenantSettingRequest')
    authService.getTenantSetting(value).then(response => {
      const data = response.data
      commit('getTenantSettingSuccess', data)
    }).catch(error => {
      commit('fetTenantSettingsFailure', error)
    })
  }
}

const mutations = {
  setUserRequest (state) {
    state.status = { loading: true }
  },
  setUserFailure (state) {
    state.status.loading = false
  },
  setUserSuccess (state, data) {
    state.status.loading = false
    state.access_token = data.access_token
    state.refresh_token = data.refresh_token
    state.expires_in = data.expires_in
    state.redirect_success = data.redirect_success
    state.redirect_fail = data.redirect_fail
  },
  loginRequest (state) {
    state.status = { loading: true }
  },
  loginSuccess (state, data) {
    state.status = { loading: true }
    state.access_token = data.access_token
    state.refresh_token = data.refresh_token
    state.expires_in = data.expires_in
    state.invitation_token = data.invitation_token
    state.redirect_success = data.redirect_success
    state.redirect_fail = data.redirect_fail
  },
  loginFailure (state) {
    state.status.loading = false
    state.access_token = null
    state.refresh_token = null
    state.expires_in = null
    state.redirect_success = null
    state.redirect_fail = null
  },
  twoFactorRequired (state, data) {
    state.twoFactorEnabled = data
  },
  registerRequest (state) {
    state.status.loading = false
  },
  registerSuccess (state) {
    state.status.loading = false
  },
  registerFailure (state, data) {
    state.status.loading = false
    state.errors[data.key] = data.value
  },
  testUsernameRequest (state) {
    state.status.loading = false
  },
  testUsernameSuccess (state, data) {
    state.status.loading = false
    state.success[data.key] = data.value
    state.errors[data.key] = null
  },
  testUsernameFailure (state, data) {
    state.status.loading = false
    state.success[data.key] = null
    state.errors[data.key] = data.value
  },
  forgotUsernameRequest (state) {
    state.status.loading = false
  },
  forgotUsernameSuccess (state) {
    state.status.loading = false
  },
  forgotUsernameFailure (state) {
    state.status.loading = false
  },
  forgotPasswordRequest (state) {
    state.status.loading = false
  },
  forgotPasswordSuccess (state) {
    state.status.loading = false
  },
  forgotPasswordFailure (state) {
    state.status.loading = false
  },
  changePasswordRequest (state) {
    state.status.loading = false
  },
  changePasswordSuccess (state) {
    state.status.loading = false
  },
  changePasswordFailure (state, data) {
    state.status.loading = false
    state.errors[data.key] = data.value
  },
  resetPasswordRequest (state) {
    state.status.loading = false
  },
  resetPasswordSuccess (state) {
    state.status.loading = false
  },
  resetPasswordFailure (state, data) {
    state.status.loading = false
    state.errors[data.key] = data.value
  },
  validateRequest (state) {
    state.status.loading = false
  },
  validateSuccess (state, data) {
    state.status.loading = false
    state.username = data.username
  },
  validateFailure (state) {
    state.status.loading = false
  },
  logout (state) {
    state.status.loading = false
    state.access_token = null
    state.refresh_token = null
    state.expires_in = null
  },
  getTenantSettingRequest (state) {
    state.status.loading = false
  },
  getTenantSettingFailure (state) {
    state.status.loading = false
  },
  getTenantSettingSuccess (state, data) {
    state.status.loading = false
    state.iwize_integration = data
  }
}

export const authModule = {
  namespaced: true,
  state,
  actions,
  mutations
}
