<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="login-block">
      <h1>Wachtwoord herstellen</h1>
      <p>U kunt hieronder een nieuw wachtwoord aanmaken om verder te gaan.</p>
      <form @submit.prevent="onReset">
        <div class="form-group">
          <label>Wachtwoord</label>
          <a-input type="password" :value="password" placeholder="********" name="password"
                   @input="setPassword"
                   v-on:keyup.enter="onReset"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password !== null" class="invalid-feedback error-text">
            {{ errors.password }}
          </div>
        </div>
        <div class="form-group">
          <label>Bevestig wachtwoord</label>
          <a-input type="password" :value="passwordConfirm" placeholder="********" name="passwordConfirm"
                   @input="setPasswordConfirm"
                   v-on:keyup.enter="onReset"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password_confirm !== null" class="invalid-feedback error-text">
            {{ errors.password_confirm }}
          </div>
        </div>
        <div class="form-group">
          <a-button :disabled="status.loading" @click="onReset" class="inverted">
            <img v-show="status.loading"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>&nbsp;&nbsp;Opslaan
          </a-button>
        </div>
        <div class="form-group link">
          &copy; {{ this.style.name }}
        </div>
        <div class="form-group link slogan" v-if="this.style.use_slogan">
          {{ this.style.slogan }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isMobile } from '../../../helpers'

export default {
  name: 'reset_password',
  data () {
    return {
      password: '',
      passwordConfirm: '',
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('auth', ['status', 'errors']),
    ...mapState('survey', ['style'])
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    setPassword (e) {
      this.password = e.target.value
    },
    setPasswordConfirm (e) {
      this.passwordConfirm = e.target.value
    },
    onReset (e) {
      this.submitted = true
      const { password, passwordConfirm } = this
      const token = this.$route.query.token
      this.resetPassword({ token, password, passwordConfirm })
    }
  }
}
</script>
