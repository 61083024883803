<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <div v-if="this.isVisibleMortgages(data)">
            <h1>Geldverstrekkers</h1>
            <p>Let op: Dit zijn gegevens uit uw laatste belastingaangifte. Deze kunnen dus verouderd zijn.</p>
            <div v-for="(mortgage, m) in data.customer.mortgages" :key="m">
              <div class="well">
                <a-row :gutter="10">
                  <a-col :sm="16">
                    <h2>Hypotheek {{ data.customer.mortgages.length > 1 ? (m + 1) : '' }}</h2>
                  </a-col>
                  <a-col :sm="8">
                    <a-button class="btn-small danger right-align" @click="removeMortgage(m)">
                      <i class="mdi mdi-delete"/> Verwijderen
                    </a-button>
                  </a-col>
                </a-row>
                <a-row :gutter="10">
                  <a-col :sm="24" :md="12" class="pt-10">
                    <a-row :gutter="10">
                      <a-col :sm="24" :md="22">
                        <input-select
                            :id="`customer.mortgages.${m}.hdn_company_id.value`"
                            :label="mortgage.hdn_company_id.title"
                            :data="mortgage.hdn_company_id.value"
                            :options="lenders(data)"
                            :error="submitted ? mortgage.hdn_company_id.error : null"
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :sm="24" :md="12" class="pt-10">
                    <a-row :gutter="10">
                      <a-col :sm="24" :md="22">
                        <input-select
                            :id="`customer.mortgages.${m}.hdn_company_label_id.value`"
                            :label="mortgage.hdn_company_label_id.title"
                            :data="mortgage.hdn_company_label_id.value"
                            :options="products(data, mortgage.hdn_company_id.value, m)"
                            :error="submitted ? mortgage.hdn_company_label_id.error : null"
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row :gutter="10">
                  <a-col span="24" class="pt-20">
                    <a-table class="mortgage_table" :dataSource="mortgage.parts" :rowKey="['id','value']" :pagination="false" :defaultExpandAllRows="true">
                      <a-row v-slot="row">
                        <a-col>
                          <input-text
                              :id="`customer.mortgages.${m}.parts.${row.index}.leningdeelnummer.value`"
                              :label="row.value.leningdeelnummer.title"
                              :data="row.value.leningdeelnummer.value"
                              :error="submitted ? row.value.leningdeelnummer.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-select
                              :id="`customer.mortgages.${m}.parts.${row.index}.aflosvorm.value`"
                              :label="row.value.aflosvorm.title"
                              :data="row.value.aflosvorm.value"
                              :options="row.value.aflosvorm.options"
                              :error="submitted ? row.value.aflosvorm.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-money
                              :id="`customer.mortgages.${m}.parts.${row.index}.oorspronkelijke_hoogte_leningdeel.value`"
                              :label="row.value.oorspronkelijke_hoogte_leningdeel.title"
                              :data="row.value.oorspronkelijke_hoogte_leningdeel.value"
                              :error="submitted ? row.value.oorspronkelijke_hoogte_leningdeel.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-money
                              :id="`customer.mortgages.${m}.parts.${row.index}.huidige_hoogte_leningdeel.value`"
                              :label="row.value.huidige_hoogte_leningdeel.title"
                              :data="row.value.huidige_hoogte_leningdeel.value"
                              :error="submitted ? row.value.huidige_hoogte_leningdeel.error : null"
                              :disabled="row.value.huidige_hoogte_leningdeel.disabled ? row.value.huidige_hoogte_leningdeel.disabled : false"
                          />
                        </a-col>
                      </a-row>
                      <a-row v-slot="row">
                        <a-col>
                          <input-date
                              :id="`customer.mortgages.${m}.parts.${row.index}.ingangsdatum_leningdeel.value`"
                              :label="row.value.ingangsdatum_leningdeel.title"
                              :data="row.value.ingangsdatum_leningdeel.value"
                              :error="submitted ? row.value.ingangsdatum_leningdeel.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-date
                              :id="`customer.mortgages.${m}.parts.${row.index}.einddatum_leningdeel.value`"
                              :label="row.value.einddatum_leningdeel.title"
                              :data="row.value.einddatum_leningdeel.value"
                              :error="submitted ? row.value.einddatum_leningdeel.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-percentage
                              addonAfter="%"
                              :id="`customer.mortgages.${m}.parts.${row.index}.rentepercentage.value`"
                              :label="row.value.rentepercentage.title"
                              :data="row.value.rentepercentage.value"
                              :error="submitted ? row.value.rentepercentage.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-select
                              :id="`customer.mortgages.${m}.parts.${row.index}.interest_type.value`"
                              :label="row.value.interest_type.title"
                              :data="row.value.interest_type.value"
                              :options="row.value.interest_type.options"
                              :error="submitted ? row.value.interest_type.error : null"
                          />
                        </a-col>
                      </a-row>
                      <a-row v-slot="row">
                        <a-col>
                          <input-date
                              :id="`customer.mortgages.${m}.parts.${row.index}.einddatum_rentevastperiode.value`"
                              :label="row.value.einddatum_rentevastperiode.title"
                              :data="row.value.einddatum_rentevastperiode.value"
                              :error="submitted ? row.value.einddatum_rentevastperiode.error : null"
                              :allowClear="false"
                          />
                        </a-col>
                        <a-col>
                          <input-number
                              :id="`customer.mortgages.${m}.parts.${row.index}.rentevast_periode_in_jaren.value`"
                              :label="row.value.rentevast_periode_in_jaren.title"
                              :data="row.value.rentevast_periode_in_jaren.value"
                              :error="submitted ? row.value.rentevast_periode_in_jaren.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-radio-list
                              :id="`customer.mortgages.${m}.parts.${row.index}.nationale_hypotheekgarantie.value`"
                              :data="row.value.nationale_hypotheekgarantie.value"
                              :label="row.value.nationale_hypotheekgarantie.title"
                              :options="row.value.nationale_hypotheekgarantie.options"
                              :error="submitted ? row.value.nationale_hypotheekgarantie.error : null"
                          />
                        </a-col>
                        <a-col>
                          <input-money
                              v-if="showOpgebouwdeSpaarwaarde(row.value.aflosvorm.value)"
                              :id="`customer.mortgages.${m}.parts.${row.index}.opgebouwde_spaarwaarde.value`"
                              :label="row.value.opgebouwde_spaarwaarde.title"
                              :data="row.value.opgebouwde_spaarwaarde.value"
                              :error="submitted ? row.value.opgebouwde_spaarwaarde.error : null"
                          />
                        </a-col>
                      </a-row>
                      <a-table-column key="action" title="" v-slot="row">
                        <span v-if="row.index > 0">
                          <a-button class="btn-smaller danger right-align" @click="removeMortgagePart({m, p: row.index})">
                            <i class="mdi mdi-delete"/>
                          </a-button>
                        </span>
                      </a-table-column>
                    </a-table>
                  </a-col>
                </a-row>
                <a-row :gutter="10">
                  <a-col span="24">
                    <a-row :gutter="10">
                      <a-col span="24" class="pt-20">
                        <a-button @click="addMortgagePart({mortgage, m})" class="btn-small inverted float-right">
                          <i class="mdi mdi-plus"/>&nbsp;&nbsp;Leningdeel toevoegen
                        </a-button>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
                <a-row :gutter="10">
                  <a-col :sm="24" :md="16" class="pt-20">
                    <a-row :gutter="10">
                      <a-col :sm="24" :md="14">
                        <input-money
                            :id="`customer.mortgages.${m}.historische_woningwaarde.value`"
                            label="Historische woningwaarde (marktwaarde zoals bekend bij geldverstrekker)"
                            :data="mortgage.historische_woningwaarde.value"
                            :error="submitted ? mortgage.historische_woningwaarde.error : null"
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </div>
            <a-row :gutter="10" class="pb-20">
              <a-col span="24">
                <a-button v-if="data.customer.mortgages.length === 0" @click="addMortgage" class="inverted">
                  <i class="mdi mdi-plus"/>&nbsp;&nbsp;Hypotheek toevoegen
                </a-button>
                <a-button v-if="data.customer.mortgages.length > 0" @click="addMortgage" class="btn-small inverted">
                  <i class="mdi mdi-plus"/>&nbsp;&nbsp;Nog een hypotheek toevoegen
                </a-button>
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="mortgage_and_pension" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isMobile, MORTGAGE_AND_PENSION, nextRoute, previousRoute, router, showMortgages } from '@/helpers'
import SideNav from '../../../components/SideNav/SideNav'
import { alertService } from '@/services'
import InputSelect from '@/components/Input/InputSelect'
import InputNumber from '@/components/Input/InputNumber'
import InputDate from '@/components/Input/InputDate'
import InputMoney from '@/components/Input/InputMoney'
import InputPercentage from '@/components/Input/InputPercentage'
import InputText from '@/components/Input/InputText'
import InputRadioList from '@/components/Input/InputRadioList'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
export default {
  name: 'step-mortgage-and-pension',
  components: {
    InputText,
    InputNumber,
    InputPercentage,
    InputSelect,
    InputDate,
    InputMoney,
    InputRadioList,
    SideNav,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      mounted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('survey', ['aflosvormen']),
    data () {
      return this.$store.state.survey
    },
    console: () => console
  },
  mounted: function () {
    this.mounted = true
  },
  methods: {
    ...mapActions('survey', ['validate', 'submit', 'addMortgage', 'removeMortgage', 'addMortgagePart', 'removeMortgagePart']),
    isVisibleMortgages (data) {
      return showMortgages(data.customer.invitation.type.value)
    },
    onPrevious (data) {
      router.push(previousRoute(MORTGAGE_AND_PENSION, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    showOpgebouwdeSpaarwaarde (value) {
      if (value === 5) {
        return false
      } else if (value === 1) {
        return false
      } else if (value === 2) {
        return false
      } else if (value === 'none') {
        return false
      }
      return true
    },
    lenders (data) {
      const options = [{ value: 'none', label: 'Maak een keuze', show: true }]
      data.hdn_companies.companies.forEach((company) => {
        options.push({ value: company.id, label: company.name, show: true })
      })
      return options
    },
    products (data, geldverstrekkerId, mortgageIndexId) {
      const options = [{ value: 'none', label: 'Maak een keuze', show: true }]
      const inputId = `customer.mortgages.${mortgageIndexId}.hdn_company_label_id.value`
      const currentValue = data.customer.mortgages[mortgageIndexId].hdn_company_label_id.value
      if (!geldverstrekkerId) {
        return options
      }
      data.hdn_company_labels.labels.forEach((label) => {
        if (geldverstrekkerId === label.company_id) {
          options.push({ value: label.id, label: label.name, show: true })
        }
      })
      if (options.length === 2 && options[1]) {
        this.submit({ name: inputId, value: options[1].value })
      } else if (options.find(option => option.value === currentValue) === undefined) {
        this.submit({ name: inputId, value: 'none' })
      }
      return options
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: MORTGAGE_AND_PENSION, data })
      if (data.status.mortgage_and_pension) {
        router.push(nextRoute(MORTGAGE_AND_PENSION, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
