<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <a-row v-if="tooOld(data.customer.date_of_birth.value)">
            <a-col :sm="24">
              <h1>{{ data.incapacity_work.title }}</h1>
              <p>{{ data.incapacity_work.description }}</p>
              <p>Volgens onze gegevens kunt u deze pagina overslaan.</p>
            </a-col>
          </a-row>
          <a-row v-if="!tooOld(data.customer.date_of_birth.value)">
            <a-col :sm="24">
              <h1>{{ data.incapacity_work.title }}</h1>
              <p>{{ data.incapacity_work.description }}</p>
              <strong>Mogelijke gevolgen:</strong>
              <ul>
                <li>Uw netto hypotheeklasten gaan omhoog</li>
                <li>Uw besteedbaar inkomen neemt af</li>
              </ul>
            </a-col>
            <a-col :sm="24">
              <div class="well">
                <a-row class="pt-20" :gutter="50">
                  <a-col span="24">
                    <h2>{{ data.incapacity_work.sections.section_61.title }}</h2>
                    <p>{{ data.incapacity_work.sections.section_61.description }}</p>
                  </a-col>
                  <a-col :xs="24">
                    <strong
                        :class="submitted && data.incapacity_work.sections.section_61.questions.current_home.error !== null ? 'error-text' : ''">
                      {{ data.incapacity_work.sections.section_61.questions.current_home.title }}
                    </strong>
                  </a-col>
                  <a-col :xs="24">
                    <input-radio-group
                        id="incapacity_work.sections.section_61.questions.current_home.value"
                        :data="data.incapacity_work.sections.section_61.questions.current_home.value"
                        :label="data.incapacity_work.sections.section_61.questions.current_home.title"
                        :options="data.incapacity_work.sections.section_61.questions.current_home.options"
                        :error="submitted && data.incapacity_work.sections.section_61.questions.current_home.error !== null"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-20" :gutter="50"
                       v-if="data.incapacity_work.sections.section_61.questions.current_home.value == 0">
                  <a-col :sm="24" :md="12">
                    <h3>Het huis waarin ik zou willen wonen:</h3>
                  </a-col>
                  <a-col :sm="24" :md="12">
                    <h3>In de buurt van:</h3>
                  </a-col>
                  <a-col :sm="24" :md="12">
                    <input-select
                        id="incapacity_work.sections.section_61.questions.jumba.home_type.value"
                        :label="data.incapacity_work.sections.section_61.questions.jumba.home_type.title"
                        :data="data.incapacity_work.sections.section_61.questions.jumba.home_type.value"
                        :options="data.incapacity_work.sections.section_61.questions.jumba.home_type.options"
                        :error="submitted && data.incapacity_work.sections.section_61.questions.jumba.home_type.error !== null"
                    />
                  </a-col>
                  <a-col :xs="12" :md="8" :lg="6">
                    <input-text
                        id="incapacity_work.sections.section_61.questions.jumba.search_postal_code.value"
                        :label="data.incapacity_work.sections.section_61.questions.jumba.search_postal_code.title"
                        :data="data.incapacity_work.sections.section_61.questions.jumba.search_postal_code.value"
                        :error="submitted && data.incapacity_work.sections.section_61.questions.jumba.search_postal_code.error !== null"
                    />
                  </a-col>
                  <a-col :xs="12" :md="8" :lg="6">
                    <input-select
                        id="incapacity_work.sections.section_61.questions.jumba.search_radius.value"
                        :label="data.incapacity_work.sections.section_61.questions.jumba.search_radius.title"
                        :data="data.incapacity_work.sections.section_61.questions.jumba.search_radius.value"
                        :options="data.incapacity_work.sections.section_61.questions.jumba.search_radius.options"
                        :error="submitted && data.incapacity_work.sections.section_61.questions.jumba.search_radius.error !== null"
                    />
                  </a-col>
                  <a-col :sm="24" class="pt-20">
                    <jumba-component
                        id="incapacity_work.sections.section_61.questions.jumba"
                        :value="data.incapacity_work.sections.section_61.questions.jumba.jumba_id.value"
                        :postal_code="data.incapacity_work.sections.section_61.questions.jumba.search_postal_code.value"
                        :radius="data.incapacity_work.sections.section_61.questions.jumba.search_radius.value"
                        :type="data.incapacity_work.sections.section_61.questions.jumba.home_type.value"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-20" :gutter="50">
                  <a-col :sm="24" :md="24" :lg="24">
                    <strong
                        :class="submitted && data.incapacity_work.sections.section_61.questions.becoming_incapacitated.error !== null ? 'error-text' : ''">
                      {{ data.incapacity_work.sections.section_61.questions.becoming_incapacitated.title }}
                      <small
                          v-if="data.incapacity_work.sections.section_61.questions.becoming_incapacitated.error !== null">
                        {{ data.incapacity_work.sections.section_61.questions.becoming_incapacitated.error }}
                      </small>
                    </strong>
                  </a-col>
                  <a-col :sm="24" :md="24" :lg="24">
                    <div class="pt-5"
                         v-for="(question, q) in data.incapacity_work.sections.section_61.questions.becoming_incapacitated.questions" :key="question.value">
                      <input-check-box
                          :id="`incapacity_work.sections.section_61.questions.becoming_incapacitated.questions.${q}.value`"
                          :label="question.title"
                          :data="question.value"
                      />
                    </div>
                  </a-col>
                </a-row>
                <a-row class="pt-20">
                  <a-col :sm="24" :md="24" :lg="11"></a-col>
                  <a-col class="ant-col-textarea">
                    <input-text-area
                        id="incapacity_work.sections.section_61.questions.becoming_incapacitated_explanation.value"
                        :placeholder="data.incapacity_work.sections.section_61.questions.becoming_incapacitated_explanation.title"
                        :label="data.incapacity_work.sections.section_61.questions.becoming_incapacitated_explanation.title"
                        :data="data.incapacity_work.sections.section_61.questions.becoming_incapacitated_explanation.value"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-20" :gutter="10">
                  <a-col :sm="16" :md="16" :lg="11">
                    <strong
                        :class="submitted && data.incapacity_work.sections.section_61.questions.insurance_type.error !== null ? 'error-text' : ''">
                      {{ data.incapacity_work.sections.section_61.questions.insurance_type.title }}
                    </strong>
                  </a-col>
                  <a-col :sm="8" :md="8" :lg="13">
                    <input-radio-group
                        id="incapacity_work.sections.section_61.questions.insurance_type.value"
                        :data="data.incapacity_work.sections.section_61.questions.insurance_type.value"
                        :options="data.incapacity_work.sections.section_61.questions.insurance_type.options"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-20">
                  <a-col :sm="24" :md="24" :lg="11"></a-col>
                  <a-col class="ant-col-textarea">
                    <input-text-area id="incapacity_work.sections.section_61.questions.insurance_type.explanation.value"
                                     :placeholder="data.incapacity_work.sections.section_61.questions.insurance_type.explanation.title"
                                     :label="data.incapacity_work.sections.section_61.questions.insurance_type.explanation.title"
                                     :data="data.incapacity_work.sections.section_61.questions.insurance_type.explanation.value"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-20" :gutter="10">
                  <a-col :sm="24" :md="12" :lg="11">
                    <strong
                        :class="submitted && data.incapacity_work.sections.section_61.questions.solution.error !== null ? 'error-text' : ''">
                      {{ data.incapacity_work.sections.section_61.questions.solution.title }}
                    </strong>
                  </a-col>
                  <a-col :sm="24" :md="12" :lg="13">
                    <input-radio-group
                        id="incapacity_work.sections.section_61.questions.solution.value"
                        :data="data.incapacity_work.sections.section_61.questions.solution.value"
                        :options="data.incapacity_work.sections.section_61.questions.solution.options"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-20">
                  <a-col :sm="24" :md="24" :lg="11"></a-col>
                  <a-col class="ant-col-textarea">
                    <input-text-area v-if="data.incapacity_work.sections.section_61.questions.solution.value == 4"
                                     id="incapacity_work.sections.section_61.questions.solution.explanation.value"
                                     :label="data.incapacity_work.sections.section_61.questions.solution.explanation.title"
                                     :placeholder="data.incapacity_work.sections.section_61.questions.solution.explanation.title"
                                     :data="data.incapacity_work.sections.section_61.questions.solution.explanation.value"
                    />
                  </a-col>
                </a-row>
              </div>
              <div class="well" v-if="data.customer.has_partner.value == 1">
                <a-row class="pt-20" :gutter="50">
                  <a-col span="24">
                    <a-row>
                      <a-col span="24">
                        <h2>{{ data.incapacity_work.sections.section_62.title }}</h2>
                        <p>{{ data.incapacity_work.sections.section_62.description }}</p>
                      </a-col>
                    </a-row>
                    <a-row :gutter="50" class="pt-10">
                      <a-col :xs="12" :sm="12" :md="10" :lg="11">
                        <strong>{{
                            data.incapacity_work.sections.section_62.questions.incapacity_work_hours.title
                          }}:</strong>
                      </a-col>
                      <a-col :xs="12" :sm="12" :md="14" :lg="13">
                        <a-slider
                            @change="onChangeWorkingHours"
                            id="incapacity_work.sections.section_62.questions.incapacity_work_hours.value"
                            :value="data.incapacity_work.sections.section_62.questions.incapacity_work_hours.value"
                            :step=1
                            :min=0
                            :max=40
                            :marks="{0: '0', 8: '8', 16: '16', 24: '24', 32: '32', 40: '40'}"
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="incapacity_work" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAge, INCAPACITY_WORK, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import InputText from '../../../components/Input/InputText'
import InputTextArea from '../../../components/Input/InputTextArea'
import InputSelect from '../../../components/Input/InputSelect'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import SideNav from '../../../components/SideNav/SideNav'
import JumbaComponent from '../../../components/Jumba/JumbaComponent'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-incapacity-work',
  components: {
    SideNav,
    InputSelect,
    InputCheckBox,
    InputText,
    InputTextArea,
    InputRadioGroup,
    JumbaComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    tooOld (dateOfBirth) {
      return getAge(dateOfBirth) > 65
    },
    onChangeWorkingHours (value) {
      this.submit({ name: 'incapacity_work.sections.section_62.questions.incapacity_work_hours.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(INCAPACITY_WORK, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: INCAPACITY_WORK, data })
      if (data.status.incapacity_work) {
        router.push(nextRoute(INCAPACITY_WORK, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
