<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>Woonwensen voor de toekomst</h1>
          <p>Tijdens de looptijd van de hypotheek kan er veel veranderen. Dit noemen we life events en
            deze kunnen een behoorlijke impact hebben op de betaalbaarheid van de hypotheek.
            In deze inventarisatie vragen wij u keuzes te maken bij diverse life events wat u gewenste
            woonsituatie is in geval van een life event. U maakt deze keuzes zelfstandig.
            Uw eventuele partner krijgt ook een uitnodiging en deze keuzes kunnen afwijken.
            Hiermee kan de hypotheekadviseur u een advies op maat geven. Uiteraard betekent dit voorbereiding
            voor u maar daarmee maakt u ook bewuste keuzes. Dit belonen we met een totale korting van € 100 op
            de advies- en bemiddelingskosten, omdat uw adviseur tijdens een gesprek minder aan u hoeft te
            vragen.</p>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="housing_requirements" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HOUSING_REQUIREMENTS, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import SideNav from '../../../components/SideNav/SideNav'
import { mapActions } from 'vuex'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'housing-requirements',
  components: {
    SideNav,
    LeftOutlined,
    RightOutlined
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  methods: {
    ...mapActions('survey', ['validate']),
    onPrevious (data) {
      router.push(previousRoute(HOUSING_REQUIREMENTS, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: HOUSING_REQUIREMENTS, data })
      if (data.status.housing_requirements) {
        router.push(nextRoute(HOUSING_REQUIREMENTS, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
