<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.financing.title }}</h1>
          <p>{{ data.financing.description }}</p>
          <div class="well">
            <h2>Financiering</h2>
            <a-row :gutter="50" class="section-question">
              <a-col class="pt-20 section-question-title">
                <strong
                    :class="submitted && data.financing.sections.section_11.questions.mortgage_payments.error !== null ? 'error-text' : ''">
                  {{ data.financing.sections.section_11.questions.mortgage_payments.title }}
                </strong>
              </a-col>
              <a-col>
                <input-radio-group
                    id="financing.sections.section_11.questions.mortgage_payments.value"
                    :label="data.financing.sections.section_11.questions.mortgage_payments.title"
                    :data="data.financing.sections.section_11.questions.mortgage_payments.value"
                    :options="data.financing.sections.section_11.questions.mortgage_payments.options"
                    :error="submitted && data.financing.sections.section_11.questions.mortgage_payments.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="financing.sections.section_11.questions.mortgage_payments.explanation.value"
                                 :placeholder="data.financing.sections.section_11.questions.mortgage_payments.explanation.title"
                                 :label="data.financing.sections.section_11.questions.mortgage_payments.explanation.title"
                                 :data="data.financing.sections.section_11.questions.mortgage_payments.explanation.value"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-40 section-question">
              <a-col :sm="24" :md="24" :lg="24">
                <strong
                    :class="submitted && data.financing.sections.section_11.questions.mortgage_properties.error !== null ? 'error-text' : ''">
                  {{ data.financing.sections.section_11.questions.mortgage_properties.title }}
                  <small v-if="data.financing.sections.section_11.questions.mortgage_properties.error !== null">
                    {{ data.financing.sections.section_11.questions.mortgage_properties.error }}
                  </small>
                </strong>
              </a-col>
              <a-col :sm="24" :md="24" :lg="24">
                <div class="pt-10"
                     v-for="(question, q) in data.financing.sections.section_11.questions.mortgage_properties.questions" :key="question.value">
                  <input-check-box
                      :id="`financing.sections.section_11.questions.mortgage_properties.questions.${q}.value`"
                      :label="question.title"
                      :data="question.value"
                  />
                </div>
              </a-col>
            </a-row>
            <a-row class="pt-10 section-question">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="financing.sections.section_11.questions.mortgage_properties_explanation.value"
                                 :placeholder="data.financing.sections.section_11.questions.mortgage_properties_explanation.title"
                                 :label="data.financing.sections.section_11.questions.mortgage_properties_explanation.title"
                                 :data="data.financing.sections.section_11.questions.mortgage_properties_explanation.value"
                />
              </a-col>
            </a-row>
            <a-row class="pt-40 section-question" :gutter="50">
              <a-col class="section-question-title">
                <strong>{{ data.financing.sections.section_11.questions.insurance.title }}</strong>
              </a-col>
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col>
                <input-select
                    id="financing.sections.section_11.questions.insurance.value"
                    :data="data.financing.sections.section_11.questions.insurance.value"
                    :options="data.financing.sections.section_11.questions.insurance.options"
                    :error="submitted && data.financing.sections.section_11.questions.insurance.error !== null"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="financing" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FINANCING, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import InputTextArea from '../../../components/Input/InputTextArea'
import InputSelect from '../../../components/Input/InputSelect'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-financing',
  components: {
    InputRadioGroup,
    InputTextArea,
    SideNav,
    InputSelect,
    InputCheckBox,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['validate']),
    onPrevious (data) {
      router.push(previousRoute(FINANCING, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: FINANCING, data })
      if (data.status.financing) {
        router.push(nextRoute(FINANCING, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
