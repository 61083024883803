import axios, { getUrl } from '../helpers/axios'

export const propertyService = {
  findByPostalCodeAndNumberAndAddition
}

function findByPostalCodeAndNumberAndAddition (postalCode, number, numberLetter, numberAddition) {
  const url = `${getUrl()}/api/portal/property/by_address`

  return axios.post(url, {
    postal_code: postalCode,
    number,
    number_letter: numberLetter,
    number_addition: numberAddition
  })
    .then(response => {
      return response.data.data
    })
}
