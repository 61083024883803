import { alertService, jumbaService } from '../services'

export const initialState = {
  status: {
    loading: false
  },
  data: {
    results: [],
    result: {}
  }
}

const state = initialState

const actions = {
  search ({ dispatch, commit }, { q, limit, sort }) {
    commit('searchRequest', { q, limit, sort })
    jumbaService.search(q, limit, sort)
      .then(response => {
        if (response.status === 200) {
          commit('searchSuccess', response.data.data)
        } else {
          commit('searchFailure')
          alertService.onError('Geen resultaten')
        }
      }).catch(error => {
        commit('searchFailure', error)
        alertService.onError('Geen resultaten')
      })
  },
  radius ({ dispatch, commit }, { q, limit, sort }) {
    commit('radiusRequest', { q, limit, sort })
    jumbaService.radius(q, limit)
      .then(response => {
        if (response.status === 200) {
          commit('radiusSuccess', response.data.data)
        } else {
          commit('radiusFailure')
          alertService.onError('Geen resultaten')
        }
      }).catch(error => {
        commit('radiusFailure', error)
        alertService.onError('Geen resultaten')
      })
  },
  properties ({ dispatch, commit }, { id }) {
    commit('propertiesRequest', { id })
    jumbaService.properties(id)
      .then(response => {
        if (response.status === 200) {
          commit('propertiesSuccess', response.data.data[0])
        } else {
          commit('propertiesFailure')
          alertService.onError('Woning niet gevonden')
        }
      }).catch(error => {
        commit('propertiesFailure', error)
        alertService.onError('Woning niet gevonden')
      })
  }
}

const mutations = {
  searchRequest (state, payload) {
    state.status.loading = true
  },
  searchSuccess (state, payload) {
    state.status.loading = false
    state.data.results = payload
  },
  searchFailure (state) {
    state.status.loading = false
  },
  radiusRequest (state, payload) {
    state.status.loading = true
  },
  radiusSuccess (state, payload) {
    state.status.loading = false
    state.data.results = payload
  },
  radiusFailure (state) {
    state.status.loading = false
  },
  propertiesRequest (state, payload) {
    state.status.loading = true
  },
  propertiesSuccess (state, payload) {
    state.status.loading = false
    state.data.result = payload
  },
  propertiesFailure (state) {
    state.status.loading = false
  }
}

export const jumbaModule = {
  namespaced: true,
  state,
  actions,
  mutations
}
