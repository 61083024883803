import * as Sentry from '@sentry/vue'

export function isInIframe () {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function isMobile () {
  const isMobile = (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  if (isMobile) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is mobile',
      data: {
        browser: 'Mobile'
      }
    })
  }
  return isMobile
}

export function isSupportedBrowser () {
  if (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl' || window.location.host === 'localhost:8080') {
    return isChrome() || isFirefox() || isEdge()
  }
  return isChrome() || isFirefox()
}

export function getSupportedBrowsers () {
  if (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl' || window.location.host === 'localhost:8080') {
    return [{
      name: 'Chrome',
      logo: './assets/img/browsers/chrome.png',
      url: 'https://www.google.com/chrome/browser/desktop/index.html'
    }, {
      name: 'Firefox',
      logo: './assets/img/browsers/firefox.png',
      url: 'https://www.mozilla.org/nl/firefox/download/thanks/'
    }, {
      name: 'Edge',
      logo: './assets/img/browsers/edge.png',
      url: 'https://www.microsoft.com/nl-nl/edge'
    }]
  } else {
    return [{
      name: 'Chrome',
      logo: './assets/img/browsers/chrome.png',
      url: 'https://www.google.com/chrome/browser/desktop/index.html'
    }, {
      name: 'Firefox',
      logo: './assets/img/browsers/firefox.png',
      url: 'https://www.mozilla.org/nl/firefox/download/thanks/'
    }]
  }
}

// Opera 8.0+
export function isOpera () {
  const browserIsOpera = (navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1
  if (browserIsOpera) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is Opera',
      data: {
        browser: 'Opera'
      }
    })
  }
  return browserIsOpera
}

// Firefox 1.0+
export function isFirefox () {
  const browserIsFireFox = navigator.userAgent.indexOf('Firefox') !== -1
  if (browserIsFireFox) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is Firefox',
      data: {
        browser: 'Firefox'
      }
    })
  }
  return browserIsFireFox
}

// Chrome 1 - 79
export function isChrome () {
  const browserIsChrome = navigator.userAgent.indexOf('Chrome') !== -1
  if (browserIsChrome) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is Chrome',
      data: {
        browser: 'Chrome'
      }
    })
  }
  return browserIsChrome
}

export function isIE () {
  const browserIsIE = (navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode)
  if (browserIsIE) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is Interner Explorer',
      data: {
        browser: 'Interner Explorer'
      }
    })
  }
  return browserIsIE
}

// Edge 20+
export function isEdge () {
  const browserIsEdge = !isIE() && !!window.StyleMedia
  if (browserIsEdge) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is Edge',
      data: {
        browser: 'Edge'
      }
    })
  }
  return browserIsEdge
}

// Safari 3.0+ "[object HTMLElementConstructor]"
export function isSafari () {
  const browserIsSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification))
  if (browserIsSafari) {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      message: 'Browser is Safari',
      data: {
        browser: 'Safari'
      }
    })
  }
  return browserIsSafari
}
