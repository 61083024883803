<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>Persoonlijke kenmerken</h1>
          <p>
            Het maken van een juiste keuze begint bij uzelf: Wat is belangrijk? Hoe neemt u
            beslissingen? Foute keuzes kunnen grote (financiële) gevolgen hebben. Ook de
            communicatie met bijvoorbeeld uw partner of adviseur speelt daarbij een grote rol.
            Inzicht in uzelf en in elkaar, helpt bij het maken van de juiste keuze.
          </p>
          <div class="well">
            <h2>Hoe gaat het in zijn werk?</h2>
            <p>
              Plaats de vier schuifjes in de posities die het beste bij je passen. Hoe verder naar links of
              rechts u gaat, des te sterker is deze persoonlijke eigenschap. Binnen enkele minuten weet u meer over
              uw eigen unieke profiel.
            </p>
            <p>
              Er zijn geen goede of foute antwoorden. Iedereen is uniek.
            </p>
            <a-row>
              <a-col :sm="10">
                <strong>{{ data.personal_characteristics.question_1.title }}</strong>
                <a-tooltip placement="right">
                  <template #title>
                    <span>Sommige mensen nemen van nature het voortouw en de regie. Anderen kijken wat er op hen af komt en werken daarin graag samen. Bepaal je liever zelf de agenda of stem je dat graag met elkaar af?</span>
                  </template>
                  <i class="mdi mdi-information"/>
                </a-tooltip>
                <br/>
                <span :class="submitted && data.personal_characteristics.question_1.error !== null ? 'error-text' : ''"
                      style="text-align: center; font-size: 0.75rem">
                                    {{ data.personal_characteristics.question_1.error }}
                                </span>
              </a-col>
              <a-col :sm="12">
                <a-slider
                    @change="onChangeSliderQuestion1"
                    id="slider-1"
                    :value="data.personal_characteristics.question_1.value"
                    :step=1
                    :min=0
                    :max=4
                    tooltipPlacement="bottom"
                    :tipFormatter="onTipFormatter1"
                    :marks="{0: 'Meegaand', 2: 'Neutraal', 4: 'Leidingnemend'}"
                />
              </a-col>
            </a-row>
            <a-row>
              <a-col :sm="10">
                <strong>{{ data.personal_characteristics.question_2.title }}</strong>
                <a-tooltip placement="right">
                  <template #title>
                    <span>In de omgang met mensen zijn sommige mensen stil of afwachtend. Voor anderen kan er niet genoeg contact zijn. Ben je van nature meer introvert of extravert?</span>
                  </template>
                  <i class="mdi mdi-information"/>
                </a-tooltip>
                <br/>
                <span :class="submitted && data.personal_characteristics.question_2.error !== null ? 'error-text' : ''"
                      style="text-align: center; font-size: 0.75rem">
                                    {{ data.personal_characteristics.question_2.error }}
                                </span>
              </a-col>
              <a-col :sm="12">
                <a-slider
                    @change="onChangeSliderQuestion2"
                    id="slider-2"
                    :value="data.personal_characteristics.question_2.value"
                    :step=1
                    :min=0
                    :max=4
                    tooltipPlacement="bottom"
                    :tipFormatter="onTipFormatter2"
                    :marks="{0: 'Introvert', 2: 'Neutraal', 4: 'Extravert'}"
                />
              </a-col>
            </a-row>
            <a-row>
              <a-col :sm="10">
                <strong>{{ data.personal_characteristics.question_3.title }}</strong>
                <a-tooltip placement="right">
                  <template #title>
                    <span>Sommige mensen hakken van nature snel knopen door. Anderen hebben meer tijd nodig om zaken af te wegen. Ben je meer van de confrontatie of van het compromis?</span>
                  </template>
                  <i class="mdi mdi-information"/>
                </a-tooltip>
                <br/>
                <span :class="submitted && data.personal_characteristics.question_3.error !== null ? 'error-text' : ''"
                      style="text-align: center; font-size: 0.75rem">
                                    {{ data.personal_characteristics.question_3.error }}
                                </span>
              </a-col>
              <a-col :sm="12">
                <a-slider
                    @change="onChangeSliderQuestion3"
                    id="slider-3"
                    :value="data.personal_characteristics.question_3.value"
                    :step=1
                    :min=0
                    :max=4
                    tooltipPlacement="bottom"
                    :tipFormatter="onTipFormatter3"
                    :marks="{0: 'Besluitvaardig', 2: 'Neutraal', 4: 'Harmonieus'}"
                />
              </a-col>
            </a-row>
            <a-row>
              <a-col :sm="10">
                <strong>{{ data.personal_characteristics.question_4.title }}</strong>
                <a-tooltip placement="right">
                  <template #title>
                    <span>Sommige mensen hebben veel aandacht voor details. Andere mensen kijken meer naar de grote lijnen. Ben je van nature meer planmatig of handel je meer op gevoel?</span>
                  </template>
                  <i class="mdi mdi-information"/>
                </a-tooltip>
                <br/>
                <span :class="submitted && data.personal_characteristics.question_4.error !== null ? 'error-text' : ''"
                      style="text-align: center; font-size: 0.75rem">
                                    {{ data.personal_characteristics.question_4.error }}
                                </span>
              </a-col>
              <a-col :sm="12">
                <a-slider
                    id="slider-4"
                    @change="onChangeSliderQuestion4"
                    :value="data.personal_characteristics.question_4.value"
                    :step=1
                    :min=0
                    :max=4
                    tooltipPlacement="bottom"
                    :tipFormatter="onTipFormatter4"
                    :marks="{0: 'Spontaan', 2: 'Neutraal', 4: 'Planmatig'}"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-50">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="personal_characteristics" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile, nextRoute, PERSONAL_CHARACTERISTICS, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-personal-characteristics',
  components: { SideNav, LeftOutlined, RightOutlined },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onTipFormatter1 (value) {
      switch (value) {
        case 0:
          return 'Ik pas me aan en hou rekening met anderen'
        case 1:
          return 'Ik pas me redelijk makkelijk aan'
        case 2:
          return 'Ik ben soms meegaand, soms leidingnemend'
        case 3:
          return 'Ik ben redelijk zelfstandig'
        case 4:
          return 'LeidingnemendIk neem graag zelf de leiding'
      }
      return value
    },
    onTipFormatter2 (value) {
      switch (value) {
        case 0:
          return 'Ik ben gereserveerd en soms wat stil'
        case 1:
          return 'Ik ben redelijk bescheiden'
        case 2:
          return 'Ik ben soms introvert, soms extravert'
        case 3:
          return 'Ik ben redelijk open'
        case 4:
          return 'Ik ben enthousiast en praat veel'
      }
      return value
    },
    onTipFormatter3 (value) {
      switch (value) {
        case 0:
          return 'Ik ben kritisch en soms ongeduldig'
        case 1:
          return 'Ik ben redelijk bevragend'
        case 2:
          return 'Ik ben soms uitdagend, soms harmonieus'
        case 3:
          return 'Ik ben redelijk mild'
        case 4:
          return 'Ik ben harmonieus en geduldig'
      }
      return value
    },
    onTipFormatter4 (value) {
      switch (value) {
        case 0:
          return 'Ik ben spontaan en instinctief'
        case 1:
          return 'Ik ben redelijk improviserend'
        case 2:
          return 'Ik ben soms spontaan, soms planmatig'
        case 3:
          return 'Ik ben redelijk precies'
        case 4:
          return 'Ik ben gedetailleerd en perfectionistisch'
      }
      return value
    },
    onChangeSliderQuestion1 (value) {
      this.submit({ name: 'personal_characteristics.question_1.value', value })
    },
    onChangeSliderQuestion2 (value) {
      this.submit({ name: 'personal_characteristics.question_2.value', value })
    },
    onChangeSliderQuestion3 (value) {
      this.submit({ name: 'personal_characteristics.question_3.value', value })
    },
    onChangeSliderQuestion4 (value) {
      this.submit({ name: 'personal_characteristics.question_4.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(PERSONAL_CHARACTERISTICS, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: PERSONAL_CHARACTERISTICS, data })
      if (data.status.personal_characteristics) {
        router.push(nextRoute(PERSONAL_CHARACTERISTICS, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
