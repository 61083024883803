import { createRouter, createWebHistory } from 'vue-router'
import RegisterComponent from '../features/Login/views/RegisterComponent.vue'
import LoginComponent from '../features/Login/views/LoginComponent.vue'
import DebugComponent from '../features/Debug/views/DebugComponent.vue'
import DebugIntro from '../features/Debug/views/DebugIntro.vue'
import IntroComponent from '../features/Step/views/IntroComponent.vue'
import AQOPIComponent from '../features/Step/views/AQOPIComponent.vue'
import PersonalDetails from '../features/Step/views/PersonalDetails.vue'
import PersonalCharacteristics from '../features/Step/views/PersonalCharacteristics.vue'
import CurrentLivingCondition from '../features/Step/views/CurrentLivingCondition.vue'
import Financing from '../features/Step/views/Financing.vue'
import HousingRequirements from '../features/Step/views/HousingRequirements.vue'
import Moving from '../features/Step/views/Moving.vue'
import Children from '../features/Step/views/Children.vue'
import Unemployment from '../features/Step/views/Unemployment.vue'
import IncapacityWork from '../features/Step/views/IncapacityWork.vue'
import DeathOfPartner from '../features/Step/views/DeathOfPartner.vue'
import Divorce from '../features/Step/views/Divorce.vue'
import Pension from '../features/Step/views/Pension.vue'
import Mortgage from '../features/Step/views/Mortgage.vue'
import MortgageAndPension from '../features/Step/views/MortgageAndPension.vue'
import FutureIncome from '../features/Step/views/FutureIncome.vue'
import Questions from '../features/Step/views/Questions.vue'
import CompleteComponent from '../features/Step/views/CompleteComponent.vue'
import SuccessComponent from '../features/Step/views/SuccessComponent.vue'
import Sustainability from '../features/Step/views/Sustainability'
import {
  CHILDREN,
  COMPLETE,
  CURRENT_LIVING_CONDITION,
  DEATH_OF_PARTNER,
  DIVORCE,
  FINANCING,
  FUTURE_INCOME,
  HOUSING_REQUIREMENTS,
  INCAPACITY_WORK,
  INTRO,
  MORTGAGE,
  MORTGAGE_AND_PENSION,
  MOVING,
  PENSION,
  PERSONAL_CHARACTERISTICS,
  PERSONAL_DETAILS,
  QUESTIONS_FOR_THE_ADVISOR,
  SUCCESS,
  SUSTAINABILITY,
  UNEMPLOYMENT
} from './routes'
import ForgotPassword from '../features/Login/views/ForgotPassword'
import ForgotUsername from '../features/Login/views/ForgotUsername'
import ResetPassword from '../features/Login/views/ResetPassword'
import ResetEmail from '../features/Login/views/ResetEmail'
import { isExpired } from './date'
import { alertService } from '@/services'
import * as Sentry from '@sentry/vue'

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [{
    path: '/',
    name: 'home',
    component: LoginComponent
  }, {
    path: '/debug',
    name: 'debug',
    component: DebugComponent
  }, {
    path: '/debug_intro',
    name: 'debug-intro',
    component: DebugIntro
  }, {
    path: '/login',
    name: 'login',
    component: LoginComponent
  }, {
    path: '/register',
    name: 'register',
    component: RegisterComponent
  }, {
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPassword
  }, {
    path: '/reset_email',
    name: 'reset_email',
    component: ResetEmail
  }, {
    path: '/forgot_password',
    name: 'forgot_password',
    component: ForgotPassword
  }, {
    path: '/forgot_username',
    name: 'forgot_username',
    component: ForgotUsername
  }, {
    path: '/intro',
    name: INTRO,
    component: IntroComponent
  }, {
    path: '/aqopi',
    name: 'aqopi',
    component: AQOPIComponent
  }, {
    path: '/personal_details',
    name: PERSONAL_DETAILS,
    component: PersonalDetails
  }, {
    path: '/personal_characteristics',
    name: PERSONAL_CHARACTERISTICS,
    component: PersonalCharacteristics
  }, {
    path: '/current_living_condition',
    name: CURRENT_LIVING_CONDITION,
    component: CurrentLivingCondition
  }, {
    path: '/financing',
    name: FINANCING,
    component: Financing
  }, {
    path: '/housing_requirements',
    name: HOUSING_REQUIREMENTS,
    component: HousingRequirements
  }, {
    path: '/sustainability',
    name: SUSTAINABILITY,
    component: Sustainability
  }, {
    path: '/moving',
    name: MOVING,
    component: Moving
  }, {
    path: '/children',
    name: CHILDREN,
    component: Children
  }, {
    path: '/unemployment',
    name: UNEMPLOYMENT,
    component: Unemployment
  }, {
    path: '/incapacity_work',
    name: INCAPACITY_WORK,
    component: IncapacityWork
  }, {
    path: '/death_of_partner',
    name: DEATH_OF_PARTNER,
    component: DeathOfPartner
  }, {
    path: '/divorce',
    name: DIVORCE,
    component: Divorce
  }, {
    path: '/pension',
    name: PENSION,
    component: Pension
  }, {
    path: '/mortgage',
    name: MORTGAGE,
    component: Mortgage
  }, {
    path: '/mortgage_and_pension',
    name: MORTGAGE_AND_PENSION,
    component: MortgageAndPension
  }, {
    path: '/future_income',
    name: FUTURE_INCOME,
    component: FutureIncome
  }, {
    path: '/questions_for_the_advisor',
    name: QUESTIONS_FOR_THE_ADVISOR,
    component: Questions
  }, {
    path: '/complete',
    name: COMPLETE,
    component: CompleteComponent
  }, {
    path: '/success',
    name: SUCCESS,
    component: SuccessComponent
  }, {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }, {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0 })
  const publicPages = ['/debug', '/login', '/register', '/forgot_password', '/forgot_username', '/reset_password', '/reset_email']
  const authRequired = !publicPages.includes(to.path)
  const auth = JSON.parse(localStorage.getItem('auth'))
  if (to.fullPath === from.fullPath) {
    next('/intro') // same
  } else if (auth !== null && isExpired(auth.expires_at)) {
    Sentry.addBreadcrumb({
      type: 'error',
      level: 'error',
      message: 'Sessie verlopen',
      data: {
        auth
      }
    })
    alertService.onError('Uw sessie is verlopen')
    localStorage.setItem('auth', JSON.stringify(null))
    if (auth.redirect_fail) {
      next(auth.redirect_fail)
    } else {
      next('/login')
    }
  } else if (auth !== null && to.path === '/') {
    next('/intro') // logged in
  } else if (authRequired && (auth === null || !auth.access_token)) {
    next('/login') // not allowed
  } else {
    next()
  }
})
