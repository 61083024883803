import axios, { getUrl } from '../helpers/axios'

export const jumbaService = {
  search,
  properties,
  status,
  radius
}

function search (q, limit, sort) {
  return axios.get(`${getUrl()}/api/portal/jumba/properties/radius?${q}&limit=${limit}&sort=${sort}`)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.error(error)
    })
}

function radius (q, limit, sort) {
  return axios.get(`${getUrl()}/api/portal/jumba/properties/radius?${q}&limit=${limit}&sort=${sort}`)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.error(error)
    })
}

function properties (id) {
  return axios.get(`${getUrl()}/api/portal/jumba/properties/${id}`)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.error(error)
    })
}

function status (id) {
  return axios.get(`${getUrl()}/api/portal/jumba/properties/${id}/status`)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.error(error)
    })
}
