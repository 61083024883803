<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <a-row class="pt-10">
            <a-col :xs="24" :sm="24" :md="24" :lg="24">
              <h1>Bedankt, uw inventarisatie is compleet!</h1>
              <h2 v-if="isHypotheekwacht">Druk op de knop "Gegevens delen en ga naar Hypotheekwacht".</h2>
              <h2 v-else>Druk op de knop "Gegevens delen met mijn adviseur".</h2>
              <p v-if="isHypotheekwacht">
                Wij hebben uw gegevens verzameld. U kunt nu aan de slag met De Hypotheekwacht.
                In het tabblad "Hypotheekoverzicht" in De Hypotheekwacht kunt u
                uw gegevens inzien en indien noodzakelijk, wijzigen.
              </p>
              <p v-if="!isHypotheekwacht">
                Na het versturen van de gegevens kan uw adviseur voor u aan de slag.<br/>
                Indien van toepassing ontvangt uw partner, na het klikken op de onderstaande knop, een eigen uitnodiging.
              </p>
            </a-col>
          </a-row>
          <a-row class="well pt-10" v-cloak @drop.prevent="dropFile" @dragover.prevent
                 style="display: none; padding: 25px">
            <a-col :xs="24" :sm="24" :md="24" :lg="24">
              <h2>Bestanden</h2>
              <p>Klik hier om uw bestanden te uploaden <input type="file" @change="selectFile" multiple></p>
              <p v-if="data.files.length > 0">
                Hieronder vind u een overzicht van de bestanden die we hebben verzameld.
              </p>
            </a-col>
            <a-col :xs="12" :sm="8" :md="4" :lg="4" v-for="(file,idx) in data.files" :key="idx">
              <div class="ant-card">
                <div class="ant-card-cover">
                  <img :src="`/assets/img/${file.type.toLowerCase()}.png`"/>
                </div>
                <div class="ant-card-body">
                  <div class="ant-card-meta">
                    <div class="ant-card-meta-detail">
                      <div class="ant-card-meta-title">
                        {{ file.filename }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a-button class="btn-small danger" @click="removeFile(file.filename)">
                Verwijderen
              </a-button>
            </a-col>
          </a-row>
          <a-row class="pt-50">
            <a-col v-if="false" :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" :disabled="data.status.loading" @click="onPrevious(data)">
                &nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" size="large" :disabled="data.status.loading" @click="onPost()">
                <RightOutlined v-if="!data.status.loading"/>
                <img v-show="data.status.loading"
                     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                <span v-if="isHypotheekwacht">&nbsp;Gegevens delen en ga naar De Hypotheekwacht</span>
                <span v-else>&nbsp;Gegevens delen met mijn adviseur</span>
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="complete" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { COMPLETE, isEigenhuis, isHypotheekwacht, isMobile, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import { RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'CompleteComponent',
  components: { SideNav, RightOutlined },
  data () {
    return {
      files: [],
      loading: false,
      submitted: false,
      isHypotheekwacht: isHypotheekwacht(this.$store.state.survey.customer.invitation.type.value),
      isEigenhuis: isEigenhuis(),
      isMobile: isMobile(),
      invitationType: this.$store.state.survey.customer.invitation.type.value
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['postCustomer', 'submitFile', 'deleteFile']),
    selectFile (e) {
      e.target.files.forEach(f => {
        this.submitFile(f)
      })
    },
    removeFile (filename) {
      this.deleteFile(filename)
    },
    dropFile (e) {
      if (e.dataTransfer) {
        const droppedFiles = e.dataTransfer.files
        if (!droppedFiles) return
        ([...droppedFiles]).forEach(f => {
          this.submitFile(f)
        })
      }
    },
    onPrevious (data) {
      router.push(previousRoute(COMPLETE, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onPost (e) {
      this.postCustomer()
    }
  }
}
</script>
