<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.mortgage.title }}</h1>
          <p>{{ data.mortgage.description }}</p>
          <div class="well pt-30" v-if="isEigenhuis">
            <a-row :gutter="50">
              <a-col :sm="24">
                <h2>{{ data.mortgage.sections.section_20.title }}</h2>
                <p>{{ data.mortgage.sections.section_20.description }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50">
              <a-col :sm="24" :md="24" :lg="24">
                <strong
                    :class="submitted && data.mortgage.sections.section_20.questions.question_1.error !== null ? 'error-text' : ''">
                  {{ data.mortgage.sections.section_20.questions.question_1.title }}
                </strong>
              </a-col>
              <a-col :sm="24" :md="24" :lg="24">
                <input-radio-group
                    id="mortgage.sections.section_20.questions.question_1.value"
                    :data="data.mortgage.sections.section_20.questions.question_1.value"
                    :label="data.mortgage.sections.section_20.questions.question_1.title"
                    :options="data.mortgage.sections.section_20.questions.question_1.options"
                    :error="submitted && data.mortgage.sections.section_20.questions.question_1.error !== null"
                />
                <br/>
              </a-col>
              <a-col :sm="24" :md="24" :lg="24">
                <i>{{ data.mortgage.sections.section_20.note }}</i>
              </a-col>
            </a-row>
          </div>
          <div class="well pt-30">
            <a-row :gutter="50">
              <a-col :sm="24">
                <h2>{{ data.mortgage.sections.section_21.title }}</h2>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20">
              <a-col span="24">
                <strong>Hypotheekrenteaftrek</strong>
                <p>{{ data.mortgage.sections.section_21.description }}</p>
                <table class="ant-table">
                  <thead class="ant-table-head">
                  <tr class="ant-table-row">
                    <th></th>
                    <th>Wel renteaftrek</th>
                    <th>Geen renteaftrek</th>
                  </tr>
                  </thead>
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_1.value"
                      class="pt-5"
                      :label="data.mortgage.sections.section_21.questions.question_1.title"
                      :data="data.mortgage.sections.section_21.questions.question_1.value"
                      :options="data.mortgage.sections.section_21.questions.question_1.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_1.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_2.value"
                      class="pt-5"
                      :label="data.mortgage.sections.section_21.questions.question_2.title"
                      :data="data.mortgage.sections.section_21.questions.question_2.value"
                      :options="data.mortgage.sections.section_21.questions.question_2.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_2.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_3.value"
                      class="pt-5"
                      :label="data.mortgage.sections.section_21.questions.question_3.title"
                      :data="data.mortgage.sections.section_21.questions.question_3.value"
                      :options="data.mortgage.sections.section_21.questions.question_3.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_3.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_4.value"
                      class="pt-5"
                      :label="data.mortgage.sections.section_21.questions.question_4.title"
                      :data="data.mortgage.sections.section_21.questions.question_4.value"
                      :options="data.mortgage.sections.section_21.questions.question_4.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_4.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_5.value"
                      class="pt-5"
                      :label="data.mortgage.sections.section_21.questions.question_5.title"
                      :data="data.mortgage.sections.section_21.questions.question_5.value"
                      :options="data.mortgage.sections.section_21.questions.question_5.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_5.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_6.value"
                      class="pt-5"
                      :label="data.mortgage.sections.section_21.questions.question_6.title"
                      :data="data.mortgage.sections.section_21.questions.question_6.value"
                      :options="data.mortgage.sections.section_21.questions.question_6.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_6.error !== null"
                  />
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24" :md="24" :lg="24">
                <strong
                    :class="submitted && data.mortgage.sections.section_21.questions.experience.error !== null ? 'error-text' : ''">
                  {{ data.mortgage.sections.section_21.questions.experience.title }}
                </strong>
              </a-col>
              <a-col :sm="24" :md="24" :lg="24">
                <input-radio-group
                    id="mortgage.sections.section_21.questions.experience.value"
                    :data="data.mortgage.sections.section_21.questions.experience.value"
                    :label="data.mortgage.sections.section_21.questions.experience.title"
                    :options="data.mortgage.sections.section_21.questions.experience.options"
                    :error="submitted && data.mortgage.sections.section_21.questions.experience.error !== null"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="mortgage.sections.section_21.questions.experience.explanation.value"
                                 :data="data.mortgage.sections.section_21.questions.experience.explanation.value"
                                 :label="data.mortgage.sections.section_21.questions.experience.explanation.title"
                                 :placeholder="data.mortgage.sections.section_21.questions.experience.explanation.title"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24" :md="24" :lg="24">
                <strong>In hoeverre bent u bekend met de volgende financiële begrippen?</strong>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col :sm="24" :md="24" :lg="24">
                <table class="ant-table">
                  <thead class="ant-table-head">
                  <tr class="ant-table-row">
                    <th></th>
                    <th>Niet</th>
                    <th>Beperkt</th>
                    <th>Volledig</th>
                  </tr>
                  </thead>
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_13.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_13.value"
                      :label="data.mortgage.sections.section_21.questions.question_13.title"
                      :options="data.mortgage.sections.section_21.questions.question_13.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_13.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_14.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_14.value"
                      :label="data.mortgage.sections.section_21.questions.question_14.title"
                      :options="data.mortgage.sections.section_21.questions.question_14.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_14.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_15.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_15.value"
                      :label="data.mortgage.sections.section_21.questions.question_15.title"
                      :options="data.mortgage.sections.section_21.questions.question_15.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_15.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_16.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_16.value"
                      :label="data.mortgage.sections.section_21.questions.question_16.title"
                      :options="data.mortgage.sections.section_21.questions.question_16.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_16.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_17.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_17.value"
                      :label="data.mortgage.sections.section_21.questions.question_17.title"
                      :options="data.mortgage.sections.section_21.questions.question_17.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_17.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_18.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_18.value"
                      :label="data.mortgage.sections.section_21.questions.question_18.title"
                      :options="data.mortgage.sections.section_21.questions.question_18.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_18.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_19.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_19.value"
                      :label="data.mortgage.sections.section_21.questions.question_19.title"
                      :options="data.mortgage.sections.section_21.questions.question_19.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_19.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_20.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_20.value"
                      :label="data.mortgage.sections.section_21.questions.question_20.title"
                      :options="data.mortgage.sections.section_21.questions.question_20.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_20.error !== null"
                  />
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24" :md="24" :lg="24">
                <strong>In hoeverre bent u bekend met de volgende hypotheekvormen of geldleningen?</strong>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col span="24">
                <table class="ant-table">
                  <thead class="ant-table-head">
                  <tr class="ant-table-row">
                    <th></th>
                    <th>Niet</th>
                    <th>Beperkt</th>
                    <th>Volledig</th>
                  </tr>
                  </thead>
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_7.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_7.value"
                      :label="data.mortgage.sections.section_21.questions.question_7.title"
                      :options="data.mortgage.sections.section_21.questions.question_7.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_7.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_8.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_8.value"
                      :label="data.mortgage.sections.section_21.questions.question_8.title"
                      :options="data.mortgage.sections.section_21.questions.question_8.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_8.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_9.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_9.value"
                      :label="data.mortgage.sections.section_21.questions.question_9.title"
                      :options="data.mortgage.sections.section_21.questions.question_9.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_9.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_10.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_10.value"
                      :label="data.mortgage.sections.section_21.questions.question_10.title"
                      :options="data.mortgage.sections.section_21.questions.question_10.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_10.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_11.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_11.value"
                      :label="data.mortgage.sections.section_21.questions.question_11.title"
                      :options="data.mortgage.sections.section_21.questions.question_11.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_11.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_21.questions.question_12.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.question_12.value"
                      :label="data.mortgage.sections.section_21.questions.question_12.title"
                      :options="data.mortgage.sections.section_21.questions.question_12.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.question_12.error !== null"
                  />
                </table>
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="mortgage.sections.section_21.questions.mortgage_types_explanation.value"
                                 :data="data.mortgage.sections.section_21.questions.mortgage_types_explanation.value"
                                 :label="data.mortgage.sections.section_21.questions.mortgage_types_explanation.title"
                                 :placeholder="data.mortgage.sections.section_21.questions.mortgage_types_explanation.title"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="mortgage.sections.section_21.questions.form_saving.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.form_saving.value"
                      :label="data.mortgage.sections.section_21.questions.form_saving.title"
                      :options="data.mortgage.sections.section_21.questions.form_saving.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.form_saving.error !== null"
                  />
                  <input-radio
                      v-if="data.mortgage.sections.section_21.questions.form_saving.value == 1"
                      id="mortgage.sections.section_21.questions.continue_saving.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_21.questions.continue_saving.value"
                      :label="data.mortgage.sections.section_21.questions.continue_saving.title"
                      :options="data.mortgage.sections.section_21.questions.continue_saving.options"
                      :error="submitted && data.mortgage.sections.section_21.questions.continue_saving.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="mortgage.sections.section_21.questions.saving_explanation.value"
                                 :placeholder="data.mortgage.sections.section_21.questions.saving_explanation.title"
                                 :label="data.mortgage.sections.section_21.questions.saving_explanation.title"
                                 :data="data.mortgage.sections.section_21.questions.saving_explanation.value"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well pt-30">
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24" :md="24" :lg="24">
                <h2>{{ data.mortgage.sections.section_22.title }}</h2>
                <p>{{ data.mortgage.sections.section_22.description }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col :sm="24" :md="24" :lg="13">
                {{ data.mortgage.sections.section_22.questions.question_1.title }}
              </a-col>
              <a-col :sm="24" :md="24" :lg="11">
                <input-select
                    id="mortgage.sections.section_22.questions.question_1.value"
                    :data="data.mortgage.sections.section_22.questions.question_1.value"
                    :options="data.mortgage.sections.section_22.questions.question_1.options"
                    :error="submitted && data.mortgage.sections.section_22.questions.question_1.error !== null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col :sm="24" :md="24" :lg="13">
                {{ data.mortgage.sections.section_22.questions.question_2.title }}
              </a-col>
              <a-col :sm="24" :md="24" :lg="11">
                <input-select
                    id="mortgage.sections.section_22.questions.question_2.value"
                    :data="data.mortgage.sections.section_22.questions.question_2.value"
                    :options="data.mortgage.sections.section_22.questions.question_2.options"
                    :error="submitted && data.mortgage.sections.section_22.questions.question_2.error !== null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-5">
              <a-col :sm="24" :md="24" :lg="13">
                {{ data.mortgage.sections.section_22.questions.question_3.title }}
              </a-col>
              <a-col :sm="24" :md="24" :lg="11">
                <input-select
                    id="mortgage.sections.section_22.questions.question_3.value"
                    :data="data.mortgage.sections.section_22.questions.question_3.value"
                    :options="data.mortgage.sections.section_22.questions.question_3.options"
                    :error="submitted && data.mortgage.sections.section_22.questions.question_3.error !== null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col :sm="24" :md="24" :lg="13">
                {{ data.mortgage.sections.section_22.questions.question_4.title }}
              </a-col>
              <a-col :sm="24" :md="24" :lg="11">
                <input-select
                    id="mortgage.sections.section_22.questions.question_4.value"
                    :data="data.mortgage.sections.section_22.questions.question_4.value"
                    :options="data.mortgage.sections.section_22.questions.question_4.options"
                    :error="submitted && data.mortgage.sections.section_22.questions.question_4.error !== null"
                />
              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col :sm="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="mortgage.sections.section_22.questions.question_5.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_22.questions.question_5.value"
                      :label="data.mortgage.sections.section_22.questions.question_5.title"
                      :options="data.mortgage.sections.section_22.questions.question_5.options"
                      :error="submitted && data.mortgage.sections.section_22.questions.question_5.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_22.questions.question_6.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_22.questions.question_6.value"
                      :label="data.mortgage.sections.section_22.questions.question_6.title"
                      :options="data.mortgage.sections.section_22.questions.question_6.options"
                      :error="submitted && data.mortgage.sections.section_22.questions.question_6.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_22.questions.question_7.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_22.questions.question_7.value"
                      :label="data.mortgage.sections.section_22.questions.question_7.title"
                      :options="data.mortgage.sections.section_22.questions.question_7.options"
                      :error="submitted && data.mortgage.sections.section_22.questions.question_7.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_22.questions.question_8.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_22.questions.question_8.value"
                      :label="data.mortgage.sections.section_22.questions.question_8.title"
                      :options="data.mortgage.sections.section_22.questions.question_8.options"
                      :error="submitted && data.mortgage.sections.section_22.questions.question_8.error !== null"
                  />
                  <input-radio
                      id="mortgage.sections.section_22.questions.question_9.value"
                      class="pt-5"
                      :data="data.mortgage.sections.section_22.questions.question_9.value"
                      :label="data.mortgage.sections.section_22.questions.question_9.title"
                      :options="data.mortgage.sections.section_22.questions.question_9.options"
                      :error="submitted && data.mortgage.sections.section_22.questions.question_9.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="mortgage.sections.section_22.questions.explanation.value"
                                 :data="data.mortgage.sections.section_22.questions.explanation.value"
                                 :label="data.mortgage.sections.section_22.questions.explanation.title"
                                 :placeholder="data.mortgage.sections.section_22.questions.explanation.title"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="mortgage" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { alertService } from '../../../services'
import { isEigenhuis, isMobile, MORTGAGE, nextRoute, previousRoute, router } from '../../../helpers'
import InputTextArea from '../../../components/Input/InputTextArea'
import InputSelect from '../../../components/Input/InputSelect'
import InputRadio from '../../../components/Input/InputRadio'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import SideNav from '../../../components/SideNav/SideNav'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-mortgage',
  components: { InputTextArea, InputSelect, InputRadio, SideNav, InputRadioGroup, LeftOutlined, RightOutlined },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile(),
      isEigenhuis: isEigenhuis()
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onPrevious (data) {
      router.push(previousRoute(MORTGAGE, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: MORTGAGE, data })
      if (data.status.mortgage) {
        router.push(nextRoute(MORTGAGE, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
