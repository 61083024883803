<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="login-block">
      <h1 v-if="invitationType === 'financial_advice' || invitationType === 'partner_financial_advice'">Welkom bij Mijn Advies Voorbereiden</h1>
      <h1 v-else>Welkom bij Mijn Hypotheek Voorbereiden</h1>
      <p>U dient een gebruikersnaam en wachtwoord aan te maken om de inventarisatie te starten.
        <strong>Na het registreren kunt u meteen inloggen.</strong>
      </p>
      <form @submit.prevent="onRegister">
        <div class="form-group">
          <label>Gebruikersnaam&nbsp;</label>
          <a-tooltip placement="right">
            <template #title>
              <span>De gebruikersnaam mag geen !#$%^&*()+ bevatten en moet minimaal 4 karakters bevatten.</span>
            </template>
            <i class="mdi mdi-information"/>
          </a-tooltip>
          <a-input
              type="text"
              :value="username"
              label="Gebruikersnaam"
              placeholder="Gebruikersnaam"
              name="username"
              autocomplete="off"
              v-on:keyup.enter="onRegister"
              :class="{ 'form-control': true, 'is-invalid': submitted && !username }"
              disabled
          />
          <div v-show="submitted && errors.username !== null" class="invalid-feedback error-text">
            {{ errors.username }}
          </div>
        </div>
        <div class="form-group">
          <label>Wachtwoord&nbsp;</label>
          <a-tooltip placement="right">
            <template #title>
              <span>Het wachtwoord moet minimaal 8 tekens bevatten.</span>
            </template>
            <i class="mdi mdi-information"/>
          </a-tooltip>
          <a-input type="password" :value="password" placeholder="********" name="password"
                   @input="setPassword" v-on:keyup.enter="onRegister"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password !== null" class="invalid-feedback error-text">
            {{ errors.password }}
          </div>
        </div>
        <div class="form-group">
          <label>Bevestig wachtwoord</label>
          <a-input type="password" :value="passwordConfirm" placeholder="********" name="passwordConfirm"
                   @input="setPasswordConfirm" v-on:keyup.enter="onRegister"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }"/>
          <div v-show="submitted && errors.password_confirm !== null" class="invalid-feedback error-text">
            {{ errors.password_confirm }}
          </div>
        </div>
        <div class="form-group">
          <a-button :disabled="status.loading" @click="onRegister" class="inverted">
            <img v-show="status.loading"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>&nbsp;&nbsp;Registreren
          </a-button>
        </div>
        <div class="form-group link">
          &copy; {{ this.style.name }}
        </div>
        <div class="form-group link slogan" v-if="this.style.use_slogan">
          {{ this.style.slogan }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isMobile } from '../../../helpers'

export default {
  name: 'RegisterComponent',
  data () {
    return {
      password: '',
      passwordConfirm: '',
      submitted: false,
      isMobile: isMobile(),
      invitationType: this.$store.state.survey.customer.invitation.type.value
    }
  },
  mounted: function () {
    this.validate({ token: this.$route.query.token })
  },
  computed: {
    ...mapState('auth', ['status', 'errors', 'username']),
    ...mapState('survey', ['style'])
  },
  methods: {
    ...mapActions('auth', ['register', 'validate']),
    setPassword (e) {
      this.password = e.target.value
    },
    setPasswordConfirm (e) {
      this.passwordConfirm = e.target.value
    },
    onRegister (e) {
      this.submitted = true
      const { username, password, passwordConfirm } = this
      this.register({ username, password, passwordConfirm, token: this.$route.query.token })
    }
  }
}
</script>
