<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="login-block">
      <h1>E-mailadres instellen</h1>
      <p>U kunt hieronder een nieuw e-mailadres opgeven.</p>
      <form @submit.prevent="onReset">
        <div class="form-group">
          <label>E-mailadres</label>
          <a-input type="email" :value="email" placeholder="example@gmail.com" name="email"
                   @input="setEmail" v-on:keyup.enter="onReset"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !email }"/>
          <div v-show="submitted && !email" class="invalid-feedback error-text">E-mailadres is verplicht</div>
        </div>
        <div class="form-group">
          <label>E-mailadres bevestigen</label>
          <a-input type="email" :value="emailConfirm" placeholder="example@gmail.com" name="emailConfirm"
                   @input="setEmailConfirm" v-on:keyup.enter="onReset"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !email }"/>
          <div v-show="submitted && !emailConfirm" class="invalid-feedback error-text">E-mailadres is verplicht</div>
        </div>
        <div class="form-group">
          <label>Code</label>
          <a-input type="text" :value="code" placeholder="FGajsd81" name="code" @input="setCode"
                   v-on:keyup.enter="onReset" :class="{'form-control': true, 'is-invalid': submitted && !code}"/>
          <div v-show="submitted && !code" class="invalid-feedback error-text">Code is verplicht</div>
        </div>
        <div class="form-group">
          <label>Geboortedatum</label>
          <input-date
              id="customer.dateOfBirth.value"
              @input="setDateOfBirth"
          />
          <div v-show="submitted && !dateOfBirth" class="invalid-feedback error-text">Geboortedatum is verplicht</div>
        </div>
        <div class="form-group">
          <a-button :disabled="status.loading" @click="onReset" class="inverted">
            <img v-show="status.loading"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
            Opslaan
          </a-button>
        </div>
        <div class="form-group link">
          &copy; {{ this.style.name }}
        </div>
        <div class="form-group link slogan" v-if="this.style.use_slogan">
          {{ this.style.slogan }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { alertService } from '../../../services'
import InputDate from '../../../components/Input/InputDate'
import { isMobile } from '../../../helpers'

export default {
  name: 'reset-email',
  components: { InputDate },
  data () {
    return {
      email: '',
      emailConfirm: '',
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('auth', ['status', 'errors']),
    ...mapState('survey', ['style'])
  },
  methods: {
    ...mapActions('auth', ['resetEmail']),
    setCode (e) {
      this.code = e.target.value
    },
    setEmail (e) {
      this.email = e.target.value
    },
    setDateOfBirth (e) {
      this.dateOfBirth = e.target.value
    },
    setEmailConfirm (e) {
      this.emailConfirm = e.target.value
    },
    onReset (e) {
      this.submitted = true
      const { email, emailConfirm, code, dateOfBirth } = this
      if (email !== emailConfirm) {
        alertService.onError('E-mailadressen komen niet overeen')
      } else if (!code) {
        alertService.onError('Vul een code in')
      } else if (!dateOfBirth) {
        alertService.onError('Vul een geldige geboortedatum')
      } else if (email) {
        this.resetEmail({ email, code, date_of_birth: dateOfBirth })
      }
    }
  }
}
</script>
