import axios, { getProxyUrl, getUrl } from '../helpers/axios'
import * as Sentry from '@sentry/vue'

export const authService = {
  register,
  validate,
  forgotPassword,
  forgotUsername,
  changePassword,
  resetPassword,
  setUser,
  login,
  requestTwoFactorCode,
  logout,
  getTenantSetting
}

function register (username, password, token) {
  return axios.post(`${getUrl()}/api/portal/auth/register`, { username, password, token })
    .then(response => {
      return response
    }, (error) => {
      return error.response.data
    })
}

function validate (token) {
  Sentry.addBreadcrumb({
    level: 'info',
    message: 'validating token'
  })
  return axios.post(`${getUrl()}/api/portal/auth/token/validate?include=customer`, { token })
    .then(response => {
      if (response.data?.data && response.status === 200) {
        return {
          success: response.data?.data?.success ?? false,
          username: response.data?.data?.customer?.data?.username
        }
      }
      Sentry.addBreadcrumb({
        level: 'info',
        message: 'token validated'
      })
      return response
    }, (error) => {
      Sentry.addBreadcrumb({
        type: 'error',
        level: 'error',
        message: 'validating token failed',
        data: {
          token
        }
      })
      Sentry.captureException(error)
      return error.response.data
    })
}

function forgotUsername (email) {
  return axios.post(`${getUrl()}/api/portal/auth/recover/username`, { email })
    .then(response => {
      return response
    }, (error) => {
      return error.response.data
    })
}

function forgotPassword (username) {
  return axios.post(`${getUrl()}/api/portal/auth/recover/password`, { username })
    .then(response => {
      return response
    }, (error) => {
      return error.response.data
    })
}

function resetPassword (password, token) {
  return axios.post(`${getUrl()}/api/portal/auth/reset/password`, { token, password })
    .then(response => {
      return response
    }, (error) => {
      return error.response.data
    })
}

function changePassword (currentPassword, newPassword, newPasswordConfirmation) {
  return axios.post(`${getUrl()}/api/portal/auth/change/password`, {
    current_password: currentPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirmation
  })
    .then(response => {
      return response
    }, (error) => {
      return error.response.data
    })
}

// eslint-disable-next-line
function resetEmail (email, code, dateOfBirth) {
  return axios.post(`${getUrl()}/api/portal/auth/reset/email`, { email, code, dateOfBirth })
    .then(response => {
      return response
    }, (error) => {
      return error.response.data
    })
}

function setUser () {
  return new Promise((resolve, reject) => {
    resolve({
      data: {
        access_token: 'eyJ1eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjIwNDc1MDNlNDllNmExOTI1ODA3YWQyYjQyM2M0Njk4NTdhYzQwMDg0NTM2NzA5YTQ0MmIxNDc3NzJmYTRhZTM3NDAyY2M0MjI1Y2I2M2U3In0.eyJhdWQiOiIxMDA3NyIsImp0aSI6IjIwNDc1MDNlNDllNmExOTI1ODA3YWQyYjQyM2M0Njk4NTdhYzQwMDg0NTM2NzA5YTQ0MmIxNDc3NzJmYTRhZTM3NDAyY2M0MjI1Y2I2M2U3IiwiaWF0IjoxNjEwMzU1Nzk1LCJuYmYiOjE2MTAzNTU3OTUsImV4cCI6MTY0MTg5MTc5NSwic3ViIjoiNjMwNTAiLCJzY29wZXMiOlsicG9ydGFsIl0sInRpZCI6IioifQ.ZfPlfMuJzT-GItzRunUHLbFyW3f4jPbSVRXtf5y8TQDM70mB_T421cEIkdh69RsAWP8WcZ1e8BDRNelmfjMSv7SSjD7Ys6ga-OXuwcIRbqEupKmfiUtP2BzISPZ8Vtw7iAnzI-v1NB8ZP8dGQFn3EKFd19cRrNA0OhUSOikNpZoVRi7QrskFuLD8j-GNsg3YmnJvxxmbJ8d0YYeFPIw9nUC5E4pu1Gj7UFBBPNsHIfan7gbj8GYRHDoApOwbZ8LoYbnow6OzZGOIrqTVMF2wNBlzao-PdeQ8vOEwDRstjQPcK-439tgrZas-A6o7sPu7pW2168H6BthJlEEZTKexTcz0QaCzPPNeEtXiMd65OcerddWeUaHlZ2RV6mLFc5viI4BbJmpa0jNiCiGgonqZ7Njz3Oij-yAwu8ClEGMvUQK0pwhwVdcbC0JJJTtqEyfX4XnXxnxia--XyOnFYJKRCrj0jDjHaJHlzC9f8lBTI8GWk7DM8DuZXr3sWp0jODyetgKpr2F5_uwAed9Gq9AfLu1X-k78kLv7qT9__6rr4UT5WZA13-e2OZnNCLiT1CcdYJ4NXBEdGf3tLvRbSSd1iefErHGSQcyvFzZQub4PR5xWJwkyWNIK9Z6q91EKZyGB26tVeCJUD_uiIwcohjEvFYhPdwOUAJ0_R8BYDBX_-Oo',
        expire_time: '2030-01-01T09:00:00.000000Z',
        expires_in: 100000000,
        token_type: 'Bearer',
        redirect_success: null,
        redirect_fail: null
      }
    })
  })
}

function login (username, password, token, twoFactorEnabled, twoFactorCode) {
  const data = {
    username,
    password,
    token,
    scope: 'portal',
    grant_type: token ? 'customer_token' : 'customer_password'
  }

  if (twoFactorEnabled) {
    data.grant_type = 'customer_password_two_factor'
    data.otp = twoFactorCode
  }

  return axios.post(`${getProxyUrl()}/api/login`, data, { withCredentials: true })
    .then(response => {
      // return
      return response
    }, (error) => {
      return error
    })
}

function requestTwoFactorCode (username) {
  return axios.post(`${getUrl()}/api/portal/auth/two_factor_code/email`, { username })
    .then(response => {
      // return
      return response
    }, (error) => {
      return error
    })
}

function logout () {
  console.log('auth.service.logout')
  localStorage.clear()
}

function getTenantSetting (value) {
  return axios.get(`${getUrl()}/api/portal/tenant/setting/${value}`).then(response => response, (error) => error.response.data)
}
