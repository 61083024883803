import axios from 'axios'

export default axios.create({
  transformRequest: [function (data, headers) {
    headers.Authorization = auth()
    return JSON.stringify(data)
  }],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export function flag () {
  return (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl') ? 'veha' : 'ddat'
}

export function getUrl () {
  if (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl') {
    return 'https://eigenhuis.ddat.nl'
  } else if (window.location.host === 'localhost:8080' || window.location.host === 'localhost:8082') {
    return 'http://dossierdata.localhost'
  }
  const domain = window.location.host.split('.')
  return `https://${domain[0]}.${window.API_URL}`
}

export function getProxyUrl () {
  if (window.location.host === 'localhost:8080' || window.location.host === 'localhost:8082') {
    return 'http://dossierdata.assessment.portal.proxy.localhost'
  }

  return ''
}

export function auth () {
  const auth = JSON.parse(localStorage.getItem('auth'))
  return auth && auth.access_token ? `Bearer ${auth.access_token}` : ''
}

// Vue.use(VueAxios, axios)
