<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="login-block">
      <h1>Gebruikersnaam vergeten</h1>
      <p>
        Bent u uw gebruikersnaam vergeten? Vul het onderstaande formulier om de gegevens op te sturen naar uw
        e-mailadres.
      </p>
      <form @submit.prevent="onForgot">
        <div class="form-group">
          <label>E-mailadres</label>
          <a-input type="email" :value="email" label="" placeholder="E-mailadres" name="email"
                   v-on:keyup.enter="onForgot" @input="setEmail"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !email }"/>
          <div v-show="submitted && !email" class="invalid-feedback error-text">E-mailadres is verplicht</div>
        </div>
        <div class="form-group">
          <a-button :disabled="status.loading" @click="onForgot" class="inverted">
            <img v-show="status.loading"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
            Versturen
          </a-button>
        </div>
        <div class="form-group link" @click="onLogin">
          <span>Inloggen</span>
        </div>
        <div class="form-group link" @click="onForgotPassword">
          <span>Wachtwoord vergeten?</span>
        </div>
        <div class="form-group link">
          &copy; {{ this.style.name }}
        </div>
        <div class="form-group link slogan" v-if="this.style.use_slogan">
          {{ this.style.slogan }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isMobile, router } from '../../../helpers'

export default {
  name: 'forgot_username',
  data () {
    return {
      email: '',
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('auth', ['status', 'errors']),
    ...mapState('survey', ['style'])
  },
  methods: {
    ...mapActions('auth', ['forgotUsername']),
    setEmail (e) {
      this.email = e.target.value
    },
    onLogin (e) {
      router.push('/login')
    },
    onForgotPassword (e) {
      router.push('/forgot_password')
    },
    onForgot (e) {
      this.submitted = true
      const { email } = this
      if (email) {
        this.forgotUsername({ email })
      }
    }
  }
}
</script>
