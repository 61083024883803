<template>
  <div class="radio-group">
    <div v-for="option in options" :key="option.value" class="input-group pt-5">
      <div class="form-check">
        <a-radio @change="onSubmit" :checked="data == option.value"
                 :name="id" :value="option.value"/>
        <label class="form-check-label" v-if="option.show">{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-radio-group',
  props: ['id', 'label', 'data', 'options'],
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (e) {
      this.submit({ name: this.id, value: e.target.value })
    }
  }
}
</script>
