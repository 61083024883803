<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.questions_for_the_advisor.title }}</h1>
          <p>{{ data.questions_for_the_advisor.description }}</p>
          <a-row align="top" justify="start" class="pt-10">
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_1.value', !data.questions_for_the_advisor.sections.section_101.questions.question_1.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_1.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_1.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_1.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_1.explanation.value"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_1.explanation.title"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_1.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_1.explanation.value"
                />
              </div>
            </a-col>
            <a-col span="2"></a-col>
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_2.value', !data.questions_for_the_advisor.sections.section_101.questions.question_2.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_2.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_2.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_2.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_2.explanation.value"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_2.explanation.title"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_2.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_2.explanation.value"
                />
              </div>
            </a-col>
          </a-row>
          <a-row align="top" justify="start" class="pt-10">
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_3.value', !data.questions_for_the_advisor.sections.section_101.questions.question_3.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_3.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_3.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_3.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_3.explanation.value"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_3.explanation.title"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_3.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_3.explanation.value"
                />
              </div>
            </a-col>
            <a-col span="2"></a-col>
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_4.value', !data.questions_for_the_advisor.sections.section_101.questions.question_4.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_4.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_4.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_4.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_4.explanation.value"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_4.explanation.title"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_4.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_4.explanation.value"
                />
              </div>
            </a-col>
          </a-row>
          <a-row align="top" justify="start" class="pt-10">
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_5.value', !data.questions_for_the_advisor.sections.section_101.questions.question_5.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_5.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_5.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_5.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_5.explanation.value"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_5.explanation.title"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_5.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_5.explanation.value"
                />
              </div>
            </a-col>
            <a-col span="2"></a-col>
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_6.value', !data.questions_for_the_advisor.sections.section_101.questions.question_6.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_6.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_6.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_6.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_6.explanation.value"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_6.explanation.title"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_6.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_6.explanation.value"
                />
              </div>
            </a-col>
          </a-row>
          <a-row align="top" justify="start" class="pt-10">
            <a-col span="11">
              <a-button
                  @click="onChange('questions_for_the_advisor.sections.section_101.questions.question_7.value', !data.questions_for_the_advisor.sections.section_101.questions.question_7.value)"
                  size="large"
                  style="width: 100%"
                  :class="data.questions_for_the_advisor.sections.section_101.questions.question_7.value == 1 ? 'primary' : 'inverted'"
              >
                {{ data.questions_for_the_advisor.sections.section_101.questions.question_7.title }}
              </a-button>
              <div v-if="data.questions_for_the_advisor.sections.section_101.questions.question_7.value == 1">
                <input-text-area class="pt-10"
                                 id="questions_for_the_advisor.sections.section_101.questions.question_7.explanation.value"
                                 :placeholder="data.questions_for_the_advisor.sections.section_101.questions.question_7.explanation.title"
                                 :label="data.questions_for_the_advisor.sections.section_101.questions.question_7.explanation.title"
                                 :data="data.questions_for_the_advisor.sections.section_101.questions.question_7.explanation.value"
                />
              </div>
            </a-col>
          </a-row>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Laatste stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="questions_for_the_advisor" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile, nextRoute, previousRoute, QUESTIONS_FOR_THE_ADVISOR, router } from '../../../helpers'
import { mapActions } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'
import InputTextArea from '../../../components/Input/InputTextArea'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-questions_for_the_advisor',
  components: { SideNav, InputTextArea, LeftOutlined, RightOutlined },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  data () {
    return {
      isMobile: isMobile()
    }
  },
  methods: {
    ...mapActions('survey', ['validate', 'submit']),
    onChange (name, value) {
      this.submit({ name, value })
    },
    onPrevious (data) {
      router.push(previousRoute(QUESTIONS_FOR_THE_ADVISOR, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: QUESTIONS_FOR_THE_ADVISOR, data })
      if (data.status.questions_for_the_advisor) {
        router.push(nextRoute(QUESTIONS_FOR_THE_ADVISOR, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
