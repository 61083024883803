<template>
  <div>
    <label :class="error ? '' : ''">{{ label }}
      <a-tooltip v-if="tooltip" placement="right">
        <template #title>
          <span>{{ tooltip }}</span>
        </template>
        <i class="mdi mdi-information"/>
      </a-tooltip>
    </label>
    <div class="input-group">
      <a-select :defaultValue="data" style="width: 100%" @change="onSubmit" :value="data" :name="id">
        <a-select-option v-for="(option, idx) in options" :key="idx" :value="option.value">{{
            option.label
          }}
        </a-select-option>
      </a-select>
      <div v-if="error" class="invalid-feedback error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-select',
  props: ['id', 'label', 'data', 'options', 'error', 'tooltip'],
  data () {
    return {
      errors: false
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (value) {
      this.submit({ name: this.id, value })
    }
  }
}
</script>
