import Toast from 'v-toast'

export const alertService = {
  onSuccess,
  onError
}

function onSuccess (message) {
  Toast.info({
    message,
    duration: 3000
  })
}

function onError (message) {
  Toast.error({
    message,
    duration: 3000
  })
}
