<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <div v-if="this.isVisiblePensions(data)">
            <h1>Toekomstig inkomen</h1>
            <div class="well">
              <a-row :gutter="10">
                <a-col :sm="16">
                  <h2>AOW-pensioen</h2>
                </a-col>
              </a-row>
              <a-row :gutter="10" v-if="false">
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-radio
                          :id="`customer.pensions.aow_uitkering.value`"
                          :label="data.customer.pensions.aow_uitkering.title"
                          :data="data.customer.pensions.aow_uitkering.value"
                          :options="data.customer.pensions.aow_uitkering.options"
                          :error="submitted ? data.customer.pensions.aow_uitkering.error : null"
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-radio
                          :id="`customer.pensions.aow_gerechtigd.value`"
                          :label="data.customer.pensions.aow_gerechtigd.title"
                          :data="data.customer.pensions.aow_gerechtigd.value"
                          :options="data.customer.pensions.aow_gerechtigd.options"
                          :error="submitted ? data.customer.pensions.aow_gerechtigd.error : null"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-money
                          :id="`customer.pensions.aow_alleenstaand.value`"
                          :label="data.customer.pensions.aow_alleenstaand.title"
                          :data="data.customer.pensions.aow_alleenstaand.value"
                          :error="submitted ? data.customer.pensions.aow_alleenstaand.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-money
                          :id="`customer.pensions.aow_samenwonend.value`"
                          :label="data.customer.pensions.aow_samenwonend.title"
                          :data="data.customer.pensions.aow_samenwonend.value"
                          :error="submitted ? data.customer.pensions.aow_samenwonend.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div class="well">
              <a-row :gutter="10">
                <a-col :sm="16">
                  <h2>Werknemerspensioen</h2>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-money
                          :id="`customer.pensions.bruto_pensioenbedrag.value`"
                          :label="data.customer.pensions.bruto_pensioenbedrag.title"
                          :data="data.customer.pensions.bruto_pensioenbedrag.value"
                          :error="submitted ? data.customer.pensions.bruto_pensioenbedrag.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div class="well">
              <a-row :gutter="10">
                <a-col :sm="16">
                  <h2>Partnerpensioen</h2>
                </a-col>
              </a-row>
              <a-row :gutter="10">
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-money
                          :id="`customer.pensions.bruto_partnerpensioenbedrag.value`"
                          :label="data.customer.pensions.bruto_partnerpensioenbedrag.title"
                          :data="data.customer.pensions.bruto_partnerpensioenbedrag.value"
                          :error="submitted ? data.customer.pensions.bruto_partnerpensioenbedrag.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div class="well" v-if="false">
              <a-row :gutter="10">
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-number
                          :id="`customer.pensions.vanaf_leeftijd_jaren.value`"
                          :label="data.customer.pensions.vanaf_leeftijd_jaren.title"
                          :data="data.customer.pensions.vanaf_leeftijd_jaren.value"
                          :error="submitted ? data.customer.pensions.vanaf_leeftijd_jaren.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-number
                          :id="`customer.pensions.vanaf_leeftijd_maanden.value`"
                          :label="data.customer.pensions.vanaf_leeftijd_maanden.title"
                          :data="data.customer.pensions.vanaf_leeftijd_maanden.value"
                          :error="submitted ? data.customer.pensions.vanaf_leeftijd_maanden.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :sm="24" :md="12" class="pt-10">
                  <a-row :gutter="10">
                    <a-col :sm="24" :md="22">
                      <input-radio
                          :id="`customer.pensions.tot_overlijden.value`"
                          :label="data.customer.pensions.tot_overlijden.title"
                          :data="data.customer.pensions.tot_overlijden.value"
                          :options="data.customer.pensions.tot_overlijden.options"
                          :error="submitted ? data.customer.pensions.tot_overlijden.error : null"
                          disabled="disabled"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="future_income" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { FUTURE_INCOME, isMobile, nextRoute, previousRoute, router, showPensions } from '@/helpers'
import SideNav from '../../../components/SideNav/SideNav'
import { alertService } from '@/services'
import InputNumber from '@/components/Input/InputNumber'
import InputMoney from '@/components/Input/InputMoney'
import InputRadio from '@/components/Input/InputRadio'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-future-income',
  components: {
    InputNumber,
    InputMoney,
    InputRadio,
    SideNav,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['validate', 'submit', 'addPension', 'removePension']),
    isVisiblePensions (data) {
      return showPensions(data.customer.invitation.type.value)
    },
    onPrevious (data) {
      router.push(previousRoute(FUTURE_INCOME, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    showOpgebouwdeSpaarwaarde (value) {
      if (value === 5) {
        return false
      } else if (value === 1) {
        return false
      } else if (value === 2) {
        return false
      } else if (value === 'none') {
        return false
      }
      return true
    },
    lenders (data) {
      const options = [{ value: 'none', label: 'Maak een keuze', show: true }]
      data.hdn_companies.companies.forEach((company) => {
        options.push({ value: company.id, label: company.name, show: true })
      })
      return options
    },
    products (data, geldverstrekkerId, inputId) {
      const options = [{ value: 'none', label: 'Maak een keuze', show: true }]
      if (!geldverstrekkerId) {
        return options
      }
      data.hdn_company_labels.labels.forEach((label) => {
        if (geldverstrekkerId === label.company_id) {
          options.push({ value: label.id, label: label.name, show: true })
        }
      })

      if (options.length === 2 && options[1]) {
        this.submit({ name: inputId, value: options[1].value })
      } else {
        this.submit({ name: inputId, value: 'none' })
      }

      return options
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: FUTURE_INCOME, data })
      if (data.status.mortgage_and_pension) {
        router.push(nextRoute(FUTURE_INCOME, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
