<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.death_of_partner.title }}</h1>
          <p>{{ data.death_of_partner.description }}</p>
          <strong>Risico's / gevolgen:</strong>
          <ul>
            <li>Uw netto hypotheeklasten gaan omhoog</li>
            <li>Uw eigen inkomen gaat mogelijk achteruit</li>
            <li>U zult zorg moeten besteden aan uw naasten</li>
          </ul>
          <div v-if="data.customer.has_partner.value == -1 || data.customer.has_partner.value == 0">
            Volgens de ingevoerde gegevens heeft u geen partner.
          </div>
          <div v-if="data.customer.has_partner.value == 1">
            <div class="well">
              <a-row class="pt-20" :gutter="50">
                <a-col span="24">
                  <h2>{{ data.death_of_partner.sections.section_71.title }}</h2>
                  <p>{{ data.death_of_partner.sections.section_71.description }}</p>
                </a-col>
                <a-col :xs="24">
                  <strong
                      :class="submitted && data.death_of_partner.sections.section_71.questions.current_home.error !== null ? 'error-text' : ''">
                    {{ data.death_of_partner.sections.section_71.questions.current_home.title }}
                  </strong>
                </a-col>
                <a-col :xs="24">
                  <input-radio-group
                      id="death_of_partner.sections.section_71.questions.current_home.value"
                      :data="data.death_of_partner.sections.section_71.questions.current_home.value"
                      :label="data.death_of_partner.sections.section_71.questions.current_home.title"
                      :options="data.death_of_partner.sections.section_71.questions.current_home.options"
                      :error="submitted && data.death_of_partner.sections.section_71.questions.current_home.error !== null"
                  />
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50"
                     v-if="data.death_of_partner.sections.section_71.questions.current_home.value == 0">
                <a-col span="24">
                  <a-row :gutter="50">
                    <a-col span="12">
                      <h3>Het huis waarin ik zou willen wonen:</h3>
                    </a-col>
                    <a-col span="12">
                      <h3>In de buurt van:</h3>
                    </a-col>
                    <a-col :xs="24" :lg=12>
                      <input-select
                          id="death_of_partner.sections.section_71.questions.jumba.home_type.value"
                          :label="data.death_of_partner.sections.section_71.questions.jumba.home_type.title"
                          :data="data.death_of_partner.sections.section_71.questions.jumba.home_type.value"
                          :options="data.death_of_partner.sections.section_71.questions.jumba.home_type.options"
                          :error="submitted && data.death_of_partner.sections.section_71.questions.jumba.home_type.error !== null"
                      />
                    </a-col>
                    <a-col :xs="12" :lg="6">
                      <input-text
                          id="death_of_partner.sections.section_71.questions.jumba.search_postal_code.value"
                          :label="data.death_of_partner.sections.section_71.questions.jumba.search_postal_code.title"
                          :data="data.death_of_partner.sections.section_71.questions.jumba.search_postal_code.value"
                          :error="submitted && data.death_of_partner.sections.section_71.questions.jumba.search_postal_code.error !== null"
                      />
                    </a-col>
                    <a-col :xs="12" :lg="6">
                      <input-select
                          id="death_of_partner.sections.section_71.questions.jumba.search_radius.value"
                          :label="data.death_of_partner.sections.section_71.questions.jumba.search_radius.title"
                          :data="data.death_of_partner.sections.section_71.questions.jumba.search_radius.value"
                          :options="data.death_of_partner.sections.section_71.questions.jumba.search_radius.options"
                          :error="submitted && data.death_of_partner.sections.section_71.questions.jumba.search_radius.error !== null"
                      />
                    </a-col>
                    <a-col :sm="24" class="pt-20">
                      <jumba-component
                          id="death_of_partner.sections.section_71.questions.jumba"
                          :value="data.death_of_partner.sections.section_71.questions.jumba.jumba_id.value"
                          :postal_code="data.death_of_partner.sections.section_71.questions.jumba.search_postal_code.value"
                          :radius="data.death_of_partner.sections.section_71.questions.jumba.search_radius.value"
                          :type="data.death_of_partner.sections.section_71.questions.jumba.home_type.value"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50">
                <a-col :xs="24" :sm="12" :md="10" :lg="12">
                  <strong>{{ data.death_of_partner.sections.section_71.questions.working_time.title }}</strong>
                </a-col>
                <a-col :xs="24" :sm="12" :md="14" :lg="12">
                  <a-slider
                      @change="onChangeWorkingTime"
                      id="death_of_partner.sections.section_71.questions.working_time.value"
                      :value="data.death_of_partner.sections.section_71.questions.working_time.value"
                      :step=1
                      :min=0
                      :max=40
                      :marks="{0: '0', 8: '8', 16: '16', 24: '24', 32: '32', 40: '40'}"
                  />
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50">
                <a-col :sm="24" :md="24" :lg="24">
                  <strong
                      :class="submitted && data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.error !== null ? 'error-text' : ''">
                    {{ data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.title }}
                    <small
                        v-if="data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.error !== null">
                      {{ data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.error }}
                    </small>
                  </strong>
                </a-col>
                <a-col :sm="24" :md="24" :lg="24">
                  <div class="pt-5"
                       v-for="(question, q) in data.death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.questions" :key="question.value">
                    <input-check-box
                        :id="`death_of_partner.sections.section_71.questions.my_partner_is_going_to_die.questions.${q}.value`"
                        :label="question.title"
                        :data="question.value"
                    />
                  </div>
                </a-col>
              </a-row>
              <a-row class="pt-20">
                <a-col :sm="24" :md="24" :lg="11"></a-col>
                <a-col class="ant-col-textarea">
                  <input-text-area id="death_of_partner.sections.section_71.questions.other_explanation.value"
                                   :placeholder="data.death_of_partner.sections.section_71.questions.other_explanation.title"
                                   :label="data.death_of_partner.sections.section_71.questions.other_explanation.title"
                                   :data="data.death_of_partner.sections.section_71.questions.other_explanation.value"
                  />
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50">
                <a-col :sm="24" :md="24" :lg="24">
                  <strong
                      :class="submitted && data.death_of_partner.sections.section_71.questions.partner_dies.error !== null ? 'error-text' : ''">
                    {{ data.death_of_partner.sections.section_71.questions.partner_dies.title }}
                    <small v-if="data.death_of_partner.sections.section_71.questions.partner_dies.error !== null">
                      {{ data.death_of_partner.sections.section_71.questions.partner_dies.error }}
                    </small>
                  </strong>
                </a-col>
                <a-col :sm="24" :md="24" :lg="24">
                  <div class="pt-5"
                       v-for="(question, q) in data.death_of_partner.sections.section_71.questions.partner_dies.questions" :key="question.value">
                    <input-check-box
                        :id="`death_of_partner.sections.section_71.questions.partner_dies.questions.${q}.value`"
                        :label="question.title"
                        :data="question.value"
                    />
                  </div>
                </a-col>
              </a-row>
              <a-row class="pt-20">
                <a-col :sm="24" :md="24" :lg="11"></a-col>
                <a-col class="ant-col-textarea">
                  <input-text-area id="death_of_partner.sections.section_71.questions.no_insurance_explanation.value"
                                   :placeholder="data.death_of_partner.sections.section_71.questions.no_insurance_explanation.title"
                                   :label="data.death_of_partner.sections.section_71.questions.no_insurance_explanation.title"
                                   :data="data.death_of_partner.sections.section_71.questions.no_insurance_explanation.value"
                  />
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50">
                <a-col :xs="24" :sm="12" :md="12" :lg="11">
                  <strong
                      :class="submitted && data.death_of_partner.sections.section_71.questions.solution.error !== null ? 'error-text' : ''">
                    {{ data.death_of_partner.sections.section_71.questions.solution.title }}
                  </strong>
                </a-col>
                <a-col :xs="24" :sm="12" :md="12" :lg="13">
                  <input-radio-group
                      id="death_of_partner.sections.section_71.questions.solution.value"
                      :data="data.death_of_partner.sections.section_71.questions.solution.value"
                      :options="data.death_of_partner.sections.section_71.questions.solution.options"
                  />
                </a-col>
              </a-row>
              <a-row class="pt-20">
                <a-col :sm="24" :md="24" :lg="11"></a-col>
                <a-col class="ant-col-textarea">
                  <input-text-area v-if="data.death_of_partner.sections.section_71.questions.solution.value == 4"
                                   id="death_of_partner.sections.section_71.questions.solution.explanation.value"
                                   :placeholder="data.death_of_partner.sections.section_71.questions.solution.explanation.title"
                                   :label="data.death_of_partner.sections.section_71.questions.solution.explanation.title"
                                   :data="data.death_of_partner.sections.section_71.questions.solution.explanation.value"
                  />
                </a-col>
              </a-row>
            </div>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="death_of_partner" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DEATH_OF_PARTNER, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import InputText from '../../../components/Input/InputText'
import InputTextArea from '../../../components/Input/InputTextArea'
import InputSelect from '../../../components/Input/InputSelect'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import SideNav from '../../../components/SideNav/SideNav'
import JumbaComponent from '../../../components/Jumba/JumbaComponent'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-death-of-partner',
  components: {
    InputCheckBox,
    SideNav,
    InputSelect,
    InputText,
    InputTextArea,
    InputRadioGroup,
    JumbaComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onChangeWorkingTime (value) {
      this.submit({ name: 'death_of_partner.sections.section_71.questions.working_time.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(DEATH_OF_PARTNER, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: DEATH_OF_PARTNER, data })
      if (data.status.death_of_partner) {
        router.push(nextRoute(DEATH_OF_PARTNER, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
