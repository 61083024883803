<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.pension.title }}</h1>
          <p>{{ data.pension.description }}</p>
          <strong>Risico's / gevolgen:</strong>
          <ul>
            <li>Uw nette hypotheeklasten gaan omhoog</li>
            <li>Uw besteedbaar inkomen neemt af, u zult moeten bezuinigen</li>
          </ul>
          <div class="well">
            <a-row class="pt-20" :gutter="50">
              <a-col span="24">
                <h2>{{ data.pension.sections.section_91.title }}</h2>

              </a-col>
            </a-row>
            <a-row :gutter="50" class="pt-10">
              <a-col :xs="24">
                <strong
                    :class="submitted && data.pension.sections.section_91.questions.current_home.error !== null ? 'error-text' : ''">
                  {{ data.pension.sections.section_91.questions.current_home.title }}
                </strong>
              </a-col>
              <a-col :xs="24">
                <input-radio-group
                    id="pension.sections.section_91.questions.current_home.value"
                    :data="data.pension.sections.section_91.questions.current_home.value"
                    :label="data.pension.sections.section_91.questions.current_home.title"
                    :options="data.pension.sections.section_91.questions.current_home.options"
                    :error="submitted && data.pension.sections.section_91.questions.current_home.error !== null"
                />
              </a-col>
              <a-col span="24" v-if="data.pension.sections.section_91.questions.current_home.value == 0">
                <a-row :gutter="50" class="pt-20">
                  <a-col span="12">
                    <h3>Het huis waarin ik zou willen wonen:</h3>
                  </a-col>
                  <a-col span="12">
                    <h3>In de buurt van:</h3>
                  </a-col>
                  <a-col :xs="24" :lg=12>
                    <input-select
                        id="pension.sections.section_91.questions.jumba.home_type.value"
                        :label="data.pension.sections.section_91.questions.jumba.home_type.title"
                        :data="data.pension.sections.section_91.questions.jumba.home_type.value"
                        :options="data.pension.sections.section_91.questions.jumba.home_type.options"
                        :error="submitted && data.pension.sections.section_91.questions.jumba.home_type.error !== null"
                    />
                  </a-col>
                  <a-col :xs="12" :lg="6">
                    <input-text
                        id="pension.sections.section_91.questions.jumba.search_postal_code.value"
                        :label="data.pension.sections.section_91.questions.jumba.search_postal_code.title"
                        :data="data.pension.sections.section_91.questions.jumba.search_postal_code.value"
                        :error="submitted && data.pension.sections.section_91.questions.jumba.search_postal_code.error !== null"
                    />
                  </a-col>
                  <a-col :xs="12" :lg="6">
                    <input-select
                        id="pension.sections.section_91.questions.jumba.search_radius.value"
                        :label="data.pension.sections.section_91.questions.jumba.search_radius.title"
                        :data="data.pension.sections.section_91.questions.jumba.search_radius.value"
                        :options="data.pension.sections.section_91.questions.jumba.search_radius.options"
                        :error="submitted && data.pension.sections.section_91.questions.jumba.search_radius.error !== null"
                    />
                  </a-col>
                  <a-col :sm="24" class="pt-20">
                    <jumba-component
                        id="pension.sections.section_91.questions.jumba"
                        :value="data.pension.sections.section_91.questions.jumba.jumba_id.value"
                        :postal_code="data.pension.sections.section_91.questions.jumba.search_postal_code.value"
                        :radius="data.pension.sections.section_91.questions.jumba.search_radius.value"
                        :type="data.pension.sections.section_91.questions.jumba.home_type.value"
                    />
                  </a-col>
                </a-row>
              </a-col>
              <a-col :xs="24" class="pt-20">
                <strong>Naar welke oplossing gaat uw voorkeur uit?</strong>
              </a-col>
              <a-col :xs="24" class="pt-10">
                <input-check-box
                    id="pension.sections.section_91.questions.sell_the_house.value"
                    :label="data.pension.sections.section_91.questions.sell_the_house.title"
                    :data="data.pension.sections.section_91.questions.sell_the_house.value"
                />
              </a-col>
              <a-col :xs="24" class="pt-10">
                <input-check-box
                    id="pension.sections.section_91.questions.change_mortgage.value"
                    :label="data.pension.sections.section_91.questions.change_mortgage.title"
                    :data="data.pension.sections.section_91.questions.change_mortgage.value"
                />
              </a-col>
              <a-col :xs="24" class="pt-10">
                <input-check-box
                    id="pension.sections.section_91.questions.other.value"
                    :label="data.pension.sections.section_91.questions.other.title"
                    :data="data.pension.sections.section_91.questions.other.value"
                />
              </a-col>
            </a-row>
            <a-row class="pt-10">
              <a-col :xs="24" :sm="12" :md="12" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area v-if="data.pension.sections.section_91.questions.other.value == 1"
                                 id="pension.sections.section_91.questions.other_explanation.value"
                                 :data="data.pension.sections.section_91.questions.other_explanation.value"
                                 :placeholder="data.pension.sections.section_91.questions.other_explanation.title"
                                 :label="data.pension.sections.section_91.questions.other_explanation.title"/>
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24">
                <strong>
                  Veranderingen in uw persoonlijke of financiële situatie kunnen gevolgen hebben voor
                  de betaalbaarheid van uw hypotheeklasten. Hoe waarschijnlijk is het dat onderstaande
                  veranderingen binnen vijf jaar gaan plaatsvinden?
                </strong>
              </a-col>
              <a-col :sm="24" class="pt-10">
                <table class="ant-table">
                  <thead class="ant-table-head">
                  <tr class="ant-table-row">
                    <th></th>
                    <th>Onwaarschijnlijk</th>
                    <th>Kans is aanwezig</th>
                    <th>Zeer waarschijnlijk</th>
                  </tr>
                  </thead>
                  <input-radio
                      id="pension.sections.section_91.questions.moving.value"
                      :data="data.pension.sections.section_91.questions.moving.value"
                      :label="data.pension.sections.section_91.questions.moving.title"
                      :options="data.pension.sections.section_91.questions.moving.options"
                      :error="submitted && data.pension.sections.section_91.questions.moving.error !== null"
                  />
                  <input-radio
                      id="pension.sections.section_91.questions.heritage.value"
                      :data="data.pension.sections.section_91.questions.heritage.value"
                      :label="data.pension.sections.section_91.questions.heritage.title"
                      :options="data.pension.sections.section_91.questions.heritage.options"
                      :error="submitted && data.pension.sections.section_91.questions.heritage.error !== null"
                  />
                  <input-radio
                      id="pension.sections.section_91.questions.relevant_change.value"
                      :label="data.pension.sections.section_91.questions.relevant_change.title"
                      :data="data.pension.sections.section_91.questions.relevant_change.value"
                      :options="data.pension.sections.section_91.questions.relevant_change.options"
                      :error="submitted && data.pension.sections.section_91.questions.relevant_change.error !== null"
                  />
                </table>
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="pension.sections.section_91.questions.changes_financial_state_explanation.value"
                                 :data="data.pension.sections.section_91.questions.changes_financial_state_explanation.value"
                                 :placeholder="data.pension.sections.section_91.questions.changes_financial_state_explanation.title"
                                 :label="data.pension.sections.section_91.questions.changes_financial_state_explanation.title"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20" :gutter="10">
              <a-col :sm="24" :md="24" :lg="24">
                <strong
                    :class="submitted && data.pension.sections.section_91.questions.partner_dies.error !== null ? 'error-text' : ''">
                  {{ data.pension.sections.section_91.questions.partner_dies.title }}
                  <small v-if="data.pension.sections.section_91.questions.partner_dies.error !== null">
                    {{ data.pension.sections.section_91.questions.partner_dies.error }}
                  </small>
                </strong>
              </a-col>
              <a-col :sm="24" :md="24" :lg="24">
                <div class="pt-5"
                     v-for="(question, q) in data.pension.sections.section_91.questions.partner_dies.questions" :key="question.value">
                  <input-check-box
                      :id="`pension.sections.section_91.questions.partner_dies.questions.${q}.value`"
                      :label="question.title"
                      :data="question.value"
                  />
                </div>
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area id="pension.sections.section_91.questions.no_insurance_explanation.value"
                                 :placeholder="data.pension.sections.section_91.questions.no_insurance_explanation.title"
                                 :label="data.pension.sections.section_91.questions.no_insurance_explanation.title"
                                 :data="data.pension.sections.section_91.questions.no_insurance_explanation.value"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well" v-if="data.customer.has_partner.value == 1">
            <a-row class="pt-30">
              <a-col span="24">
                <h2>{{ data.pension.sections.section_92.title }}</h2>
                <p>{{ data.pension.sections.section_92.description }}</p>
              </a-col>
              <a-col span="24">
                <a-row :gutter="50">
                  <a-col :sm="12" :md="10" :lg="12">
                    <strong>{{ data.pension.sections.section_92.questions.working_time.title }}</strong>
                  </a-col>
                  <a-col :sm="12" :md="14" :lg="12">
                    <a-slider
                        @change="onChangeWorkingTime"
                        id="pension.sections.section_92.questions.working_time.value"
                        :value="data.pension.sections.section_92.questions.working_time.value"
                        :step=1
                        :min=0
                        :max=40
                        :marks="{0: '0', 8: '8', 16: '16', 24: '24', 32: '32', 40: '40'}"
                    />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row class="pt-30">
              <a-col :sm="24" :md="12" :lg="11">
                <strong
                    :class="submitted && data.pension.sections.section_92.questions.solution.error !== null ? 'error-text' : ''">
                  {{ data.pension.sections.section_92.questions.solution.title }}
                </strong>
              </a-col>
              <a-col :sm="24" :md="12" :lg="13">
                <input-radio-group
                    label=""
                    id="pension.sections.section_92.questions.solution.value"
                    :data="data.pension.sections.section_92.questions.solution.value"
                    :options="data.pension.sections.section_92.questions.solution.options"
                />
              </a-col>
            </a-row>
            <a-row class="pt-20">
              <a-col :sm="24" :md="24" :lg="11"></a-col>
              <a-col class="ant-col-textarea">
                <input-text-area v-if="data.pension.sections.section_92.questions.solution.value == 4"
                                 id="pension.sections.section_92.questions.solution.explanation.value"
                                 :placeholder="data.pension.sections.section_92.questions.solution.explanation.title"
                                 :label="data.pension.sections.section_92.questions.solution.explanation.title"
                                 :data="data.pension.sections.section_92.questions.solution.explanation.value"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="pension" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAge, isMobile, nextRoute, PENSION, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import InputText from '../../../components/Input/InputText'
import InputTextArea from '../../../components/Input/InputTextArea'
import InputCheckBox from '../../../components/Input/InputCheckBox'
import InputSelect from '../../../components/Input/InputSelect'
import InputRadio from '../../../components/Input/InputRadio'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import SideNav from '../../../components/SideNav/SideNav'
import JumbaComponent from '../../../components/Jumba/JumbaComponent'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-pension',
  components: {
    InputCheckBox,
    SideNav,
    InputSelect,
    InputRadio,
    InputText,
    InputTextArea,
    InputRadioGroup,
    JumbaComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    tooOld (dateOfBirth) {
      return getAge(dateOfBirth) > 65
    },
    onChangeWorkingTime (value) {
      this.submit({ name: 'pension.sections.section_92.questions.working_time.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(PENSION, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: PENSION, data })
      if (data.status.pension) {
        router.push(nextRoute(PENSION, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
