<template>
  <div>
    <label :class="error ? '' : ''">{{ label }}
      <a-tooltip v-if="tooltip" placement="right">
        <template #title>
          <span>{{ tooltip }}</span>
        </template>
        <i class="mdi mdi-information"/>
      </a-tooltip>
    </label>
    <div class="input-group">
      <a-input @input="onSubmit" type="text" :value="data" :name="id" class="form-control"
               :style="style()"/>
      <div v-if="error" class="invalid-feedback error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-text',
  props: ['id', 'label', 'data', 'error', 'tooltip'],
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (e) {
      this.submit({ name: this.id, value: e.target.value })
      this.$emit('input', e.target.value)
    },
    style () {
      return {}
    }
  }
}
</script>
