x
<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.children.title }}</h1>
          <p>{{ data.children.description }}</p>
          <strong>Risico's:</strong>
          <ul>
            <li>De woonbehoefte wijzigt</li>
            <li>Bestedingsruimte wijzigt</li>
          </ul>
          <div v-if="getChildren(data.customer.children).length > 0">
                        <span v-if="getChildren(data.customer.children).length == 1">
                            Volgens de ingevoerde gegevens heeft u momenteel 1 thuiswonend kind:
                        </span>
            <span v-if="getChildren(data.customer.children).length > 1">
                            Volgens de ingevoerde gegevens heeft u momenteel
                            {{ getChildren(data.customer.children).length }} thuiswonende kinderen:
                        </span>
            <ul v-if="getChildren(data.customer.children).length > 0">
              <li v-for="child in getChildren(data.customer.children)" :key="child.id">
                {{ child.first_name.value }} {{ child.last_name.value }} {{
                  child.date_of_birth.value ?
                      `(${child.date_of_birth.value})` : ''
                }}
              </li>
            </ul>
          </div>
          <div v-if="getChildren(data.customer.children).length == 0">
            Volgens de ingevoerde gegevens heeft u momenteel geen thuiswonende kinderen.
          </div>
          <div class="well">
            <h2 class="pt-10">{{ data.children.sections.section_41.title }}</h2>
            <a-row :gutter="50" class="pt-20">
              <a-col :sm="24" :md="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="children.sections.section_41.questions.family_expansion.value"
                      :data="data.children.sections.section_41.questions.family_expansion.value"
                      :label="data.children.sections.section_41.questions.family_expansion.title"
                      :options="data.children.sections.section_41.questions.family_expansion.options"
                      :error="submitted && data.children.sections.section_41.questions.family_expansion.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="50"
                   v-if="data.children.sections.section_41.questions.family_expansion.value == 1"
                   class="pt-20">
              <a-col :sm="12" :md="12" :lg="12">
                {{ data.children.sections.section_41.questions.hours.title }}
              </a-col>
              <a-col :sm="12" :md="12" :lg="12">
                <a-slider
                    @change="onChangeSlider1"
                    id="slider-7"
                    :value="data.children.sections.section_41.questions.hours.value"
                    :step=4
                    :min=0
                    :max=40
                    :marks="{0: '0', 8: '8', 16: '16', 24: '24', 32: '32', 40: '40'}"
                />
              </a-col>
            </a-row>
          </div>
          <div class="well" v-if="data.children.sections.section_41.questions.family_expansion.value == 1">
            <h2 class="pt-10">{{ data.children.sections.section_42.title }}</h2>
            <a-row :gutter="50">
              <a-col :sm="24" :md="24">
                <table class="ant-table">
                  <tbody class="ant-table-body">
                  <input-radio
                      id="children.sections.section_42.questions.moving.value"
                      :data="data.children.sections.section_42.questions.moving.value"
                      :label="data.children.sections.section_42.questions.moving.title"
                      :options="data.children.sections.section_42.questions.moving.options"
                      :error="submitted && data.children.sections.section_42.questions.moving.error !== null"
                  />
                  </tbody>
                </table>
              </a-col>
            </a-row>
            <a-row :gutter="10" class="py-20"
                   v-if="data.children.sections.section_42.questions.moving.value == 1">
              <a-col :sm="12" :md="8" :lg="12">
                <input-select
                    id="children.sections.section_42.questions.jumba.home_type.value"
                    :label="data.children.sections.section_42.questions.jumba.home_type.title"
                    :data="data.children.sections.section_42.questions.jumba.home_type.value"
                    :options="data.children.sections.section_42.questions.jumba.home_type.options"
                    :error="submitted && data.children.sections.section_42.questions.jumba.home_type.error !== null"
                />
              </a-col>
              <a-col :sm="12" :md="8" :lg="6">
                <input-text
                    id="children.sections.section_42.questions.jumba.search_postal_code.value"
                    :label="data.children.sections.section_42.questions.jumba.search_postal_code.title"
                    :data="data.children.sections.section_42.questions.jumba.search_postal_code.value"
                    :error="submitted && data.children.sections.section_42.questions.jumba.search_postal_code.error !== null"
                />
              </a-col>
              <a-col :sm="12" :md="8" :lg="6">
                <input-select
                    id="children.sections.section_42.questions.jumba.search_radius.value"
                    :label="data.children.sections.section_42.questions.jumba.search_radius.title"
                    :data="data.children.sections.section_42.questions.jumba.search_radius.value"
                    :options="data.children.sections.section_42.questions.jumba.search_radius.options"
                    :error="submitted && data.children.sections.section_42.questions.jumba.search_radius.error !== null"
                />
              </a-col>
              <a-col :sm="24" :md="24" :lg="24" class="pt-20">
                <jumba-component
                    id="children.sections.section_42.questions.jumba"
                    :value="data.children.sections.section_42.questions.jumba.jumba_id.value"
                    :postal_code="data.children.sections.section_42.questions.jumba.search_postal_code.value"
                    :radius="data.children.sections.section_42.questions.jumba.search_radius.value"
                    :type="data.children.sections.section_42.questions.jumba.home_type.value"
                />
              </a-col>
            </a-row>
            <a-row v-if="data.children.sections.section_42.questions.moving.value == 1">
              <a-col :sm=24>
                <a-row :gutter="50" class="pt-30">
                  <a-col :sm="24" :md="24" :lg="11">
                    <strong>{{ data.children.sections.section_42.questions.financing.title }}</strong>
                  </a-col>
                  <a-col :sm="24" :md="24" :lg="13">
                    <input-radio-group
                        id="children.sections.section_42.questions.financing.value"
                        :label="data.children.sections.section_42.questions.financing.title"
                        :data="data.children.sections.section_42.questions.financing.value"
                        :options="data.children.sections.section_42.questions.financing.options"
                        :error="submitted && data.children.sections.section_42.questions.financing.error !== null"
                    />
                  </a-col>
                </a-row>
                <a-row class="pt-10">
                  <a-col :sm="24" :md="24" :lg="11"></a-col>
                  <a-col class="ant-col-textarea">
                    <input-text-area v-if="data.children.sections.section_42.questions.financing.value == 5"
                                     id="children.sections.section_42.questions.financing.explanation.value"
                                     :data="data.children.sections.section_42.questions.financing.explanation.value"
                                     :label="data.children.sections.section_42.questions.financing.explanation.title"
                                     :placeholder="data.children.sections.section_42.questions.financing.explanation.title"
                    />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="children" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CHILDREN, getAge, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import InputSelect from '../../../components/Input/InputSelect'
import InputRadio from '../../../components/Input/InputRadio'
import InputText from '../../../components/Input/InputText'
import InputTextArea from '../../../components/Input/InputTextArea'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import SideNav from '../../../components/SideNav/SideNav'
import JumbaComponent from '../../../components/Jumba/JumbaComponent'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-children',
  components: {
    SideNav,
    InputSelect,
    InputRadioGroup,
    InputTextArea,
    InputRadio,
    InputText,
    JumbaComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    tooOld (dateOfBirth) {
      return getAge(dateOfBirth) > 50
    },
    getChildren (children) {
      const arr = []
      children.forEach(child => {
        if (child.lives_at_home) {
          arr.push(child)
        }
      })
      return arr
    },
    onChangeSlider1 (value) {
      this.submit({ name: 'children.sections.section_41.questions.hours.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(CHILDREN, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: CHILDREN, data })
      if (data.status.children) {
        router.push(nextRoute(CHILDREN, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
