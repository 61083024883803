<template>
  <tbody>
    <tr class="ant-table-row">
      <td :class="error ? 'ant-table-row-cell error-text' : 'ant-table-row-cell'">{{ label }}
        <a-tooltip v-if="tooltip" placement="right">
          <template #title>
            <span v-html="tooltip" />
          </template>
          <i class="mdi mdi-information"/>
        </a-tooltip>
      </td>
      <td class="ant-table-row-cell mt-15" v-for="option in options" :key="option.value">
        <a-radio @change="onSubmit" :checked="data == option.value" :name="id" :value="option.value" :disabled="disabled"/>
        <label class="form-check-label" v-if="option.show">{{ option.label }}</label>
      </td>
    </tr>
    <tr class="ant-table-row" v-if="description">
      <td class="ant-table-row-cell">
        <i v-html="description"></i>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'input-radio',
  props: ['id', 'label', 'data', 'options', 'error', 'tooltip', 'description', 'disabled'],
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onSubmit (e) {
      this.submit({ name: this.id, value: e.target.value })
    }
  }
}
</script>
