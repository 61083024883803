<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile">
          <h1>{{ data.divorce.title }}</h1>
          <p>{{ data.divorce.description }}</p>
          <strong>Risico's / gevolgen:</strong>
          <ul>
            <li>Uw nette hypotheeklasten gaan omhoog</li>
            <li>Kan de woning nog wel betaald worden?</li>
          </ul>
          <div v-if="data.customer.has_partner.value == -1 || data.customer.has_partner.value == 0">
            Volgens de ingevoerde gegevens heeft u geen partner.
          </div>
          <div v-if="data.customer.has_partner.value == 1">
            <div class="well">
              <a-row class="pt-20" :gutter="50">
                <a-col span="24">
                  <h2>{{ data.divorce.sections.section_81.title }}</h2>
                  <p>{{ data.divorce.sections.section_81.description }}</p>
                </a-col>
                <a-col :xs="24">
                  <strong
                      :class="submitted && data.divorce.sections.section_81.questions.current_home.error !== null ? 'error-text' : ''">
                    {{ data.divorce.sections.section_81.questions.current_home.title }}
                  </strong>
                </a-col>
                <a-col :xs="24">
                  <input-radio-group
                      id="divorce.sections.section_81.questions.current_home.value"
                      :data="data.divorce.sections.section_81.questions.current_home.value"
                      :label="data.divorce.sections.section_81.questions.current_home.title"
                      :options="data.divorce.sections.section_81.questions.current_home.options"
                      :error="submitted && data.divorce.sections.section_81.questions.current_home.error !== null"
                  />
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50"
                     v-if="data.divorce.sections.section_81.questions.current_home.value == 0">
                <a-col span="24">
                  <a-row :gutter="50">
                    <a-col span="12">
                      <h3>Het huis waarin ik zou willen wonen:</h3>
                    </a-col>
                    <a-col span="12">
                      <h3>In de buurt van:</h3>
                    </a-col>
                    <a-col :xs="24" :lg=12>
                      <input-select
                          id="divorce.sections.section_81.questions.jumba.home_type.value"
                          :label="data.divorce.sections.section_81.questions.jumba.home_type.title"
                          :data="data.divorce.sections.section_81.questions.jumba.home_type.value"
                          :options="data.divorce.sections.section_81.questions.jumba.home_type.options"
                          :error="submitted && data.divorce.sections.section_81.questions.jumba.home_type.error !== null"
                      />
                    </a-col>
                    <a-col :xs="12" :lg="6">
                      <input-text
                          id="divorce.sections.section_81.questions.jumba.search_postal_code.value"
                          :label="data.divorce.sections.section_81.questions.jumba.search_postal_code.title"
                          :data="data.divorce.sections.section_81.questions.jumba.search_postal_code.value"
                          :error="submitted && data.divorce.sections.section_81.questions.jumba.search_postal_code.error !== null"
                      />
                    </a-col>
                    <a-col :xs="12" :lg="6">
                      <input-select
                          id="divorce.sections.section_81.questions.jumba.search_radius.value"
                          :label="data.divorce.sections.section_81.questions.jumba.search_radius.title"
                          :data="data.divorce.sections.section_81.questions.jumba.search_radius.value"
                          :options="data.divorce.sections.section_81.questions.jumba.search_radius.options"
                          :error="submitted && data.divorce.sections.section_81.questions.jumba.search_radius.error !== null"
                      />
                    </a-col>
                    <a-col :sm="24" class="pt-20">
                      <jumba-component
                          id="divorce.sections.section_81.questions.jumba"
                          :value="data.divorce.sections.section_81.questions.jumba.jumba_id.value"
                          :postal_code="data.divorce.sections.section_81.questions.jumba.search_postal_code.value"
                          :radius="data.divorce.sections.section_81.questions.jumba.search_radius.value"
                          :type="data.divorce.sections.section_81.questions.jumba.home_type.value"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-row class="pt-20" :gutter="50">
                <a-col :xs="24" :sm="12" :md="10" :lg="12">
                  <strong>{{ data.divorce.sections.section_81.questions.working_time.title }}</strong>
                </a-col>
                <a-col :xs="24" :sm="12" :md="14" :lg="12">
                  <a-slider
                      @change="onChangeWorkingTime"
                      id="divorce.sections.section_81.questions.working_time.value"
                      :value="data.divorce.sections.section_81.questions.working_time.value"
                      :step=1
                      :min=0
                      :max=40
                      :marks="{0: '0', 8: '8', 16: '16', 24: '24', 32: '32', 40: '40'}"
                  />
                </a-col>
              </a-row>
            </div>
          </div>
          <a-row class="pt-30">
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary" @click="onPrevious(data)">
                <LeftOutlined />&nbsp;&nbsp;Vorige
              </a-button>
            </a-col>
            <a-col :xs="12" :sm="12" :md="12" :lg="12">
              <a-button class="primary right-align" @click="onSubmit(data)">
                Volgende stap&nbsp;&nbsp;<RightOutlined />
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="divorce" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DIVORCE, isMobile, nextRoute, previousRoute, router } from '../../../helpers'
import { mapActions } from 'vuex'
import InputText from '../../../components/Input/InputText'
import InputSelect from '../../../components/Input/InputSelect'
import InputRadioGroup from '../../../components/Input/InputRadioGroup'
import SideNav from '../../../components/SideNav/SideNav'
import JumbaComponent from '../../../components/Jumba/JumbaComponent'
import { alertService } from '../../../services'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default {
  name: 'step-divorce',
  components: {
    InputRadioGroup,
    SideNav,
    InputSelect,
    InputText,
    JumbaComponent,
    LeftOutlined,
    RightOutlined
  },
  data () {
    return {
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    data () {
      return this.$store.state.survey
    }
  },
  methods: {
    ...mapActions('survey', ['submit', 'validate']),
    onChangeWorkingTime (value) {
      this.submit({ name: 'divorce.sections.section_81.questions.working_time.value', value })
    },
    onPrevious (data) {
      router.push(previousRoute(DIVORCE, data.customer.has_partner.value, data.customer.invitation.type.value))
    },
    onSubmit (data) {
      this.submitted = true
      this.validate({ key: DIVORCE, data })
      if (data.status.divorce) {
        router.push(nextRoute(DIVORCE, data.customer.has_partner.value, data.customer.invitation.type.value))
      } else {
        alertService.onError('Niet alle velden zijn ingevuld')
      }
    }
  }
}
</script>
