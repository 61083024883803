import axios from '../helpers/axios'
import * as Sentry from '@sentry/vue'

export const tenantService = {
  style,
  logo,
  heroImage
}

function logo () {
  if (window.location.host === 'localhost:8080') {
    return new Promise((resolve, reject) => {
      resolve({ status: 200, data: './assets/logo-dossierdata.png' })
    })
  } else if (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl') {
    return new Promise((resolve, reject) => {
      resolve({ status: 200, data: './assets/logo-eigenhuis.svg' })
    })
  }
  const domain = window.location.host.split('.')
  return axios.get(`${window.PROXY_URL}/api/tenant/${domain[0]}/logo/json`, { withCredentials: true })
    .then(response => {
      return { status: 200, data: 'data:image/png;base64, ' + response.data.data.body }
    }).catch(() => {
      return { status: 200, data: './assets/logo-dossierdata.png' }
    })
}

function heroImage () {
  if (window.location.host === 'localhost:8080') {
    return new Promise((resolve, reject) => {
      resolve({ status: 200, data: './assets/hero-image.jpg' })
    })
  } else if (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl') {
    return new Promise((resolve, reject) => {
      resolve({ status: 200, data: './assets/hero-image.jpg' })
    })
  }
  const domain = window.location.host.split('.')
  return axios.get(`${window.PROXY_URL}/api/tenant/${domain[0]}/header/json`, { withCredentials: true })
    .then(response => {
      return { status: 200, data: 'data:image/png;base64, ' + response.data.data.body }
    }).catch(() => {
      return { status: 200, data: './assets/hero-image.jpg' }
    })
}

function style () {
  if (window.location.host === 'localhost:8080' || window.location.host === 'localhost:8082') {
    return new Promise((resolve, reject) => {
      resolve({
        status: 200,
        data: {
          tenant_id: 999,
          name: 'DossierData',
          full_name: 'DossierData',
          primary_color: '#333333',
          secondary_color: '#3E9BD0',
          tertiary_color: '#3E9BD0',
          use_slogan: true,
          slogan: 'Het nazorgpakket voor financieel adviseurs',
          use_contact_email: true,
          contact_email: 'support@dossierdata.nl',
          use_support_email: true,
          support_email: 'support@dossierdata.nl'
        }
      })
    })
  } else if (window.location.host === 'mijnhypotheekvoorbereiden.nl' || window.location.host === 'mijnadviesvoorbereiden.nl') {
    return new Promise((resolve, reject) => {
      resolve({
        status: 200,
        data: {
          tenant_id: 140,
          name: 'Vereniging Eigen Huis',
          full_name: 'Vereniging Eigen Huis',
          primary_color: '#ff8201',
          secondary_color: '#ed1c24',
          tertiary_color: '#2c2895',
          use_slogan: true,
          slogan: 'Sta sterker',
          use_contact_email: true,
          contact_email: 'info@eigenhuis.nl',
          use_support_email: true,
          support_email: 'support@eigenhuis.nl'
        }
      })
    })
  }

  const domain = window.location.host.split('.')
  return axios.get(`${window.PROXY_URL}/api/tenant/${domain[0]}`, { withCredentials: true })
    .then(response => {
      return { status: 200, data: response.data.data }
    }).catch(function (error) {
      console.error(error)
      Sentry.captureException(error)
    })
}
