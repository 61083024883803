import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { router } from './helpers'
import store from './store'
import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const app = createApp(App)

app.config.productionTip = false
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(Antd)

Sentry.init({
  app,
  dsn: 'https://839b08641b524de990131777258e075c@sentry.ddat.io/3',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      // tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1
})

Sentry.setTag('username', store.state.auth.username)
Sentry.setTag('invitation_type', store.state.survey.customer.invitation.type.value)

app.mount('#app')
