<template>
  <a-card v-if="item.image" hoverable @click="clicked" :style="value === item.id ? 'border: 2px solid #FF8201' : ''"
          :class="value === item.id ? 'active' : ''" style="width: 250px; height: 250px;">
    <img :src="getImage(item)" slot="cover" style="max-width: 200px; max-height: 200px"/>
    <a-card-meta :title="getDescription(item)"/>
  </a-card>
</template>

<script>
import { env } from '../../helpers/env'

export default {
  name: 'jumba-card',
  props: ['value', 'item'],
  methods: {
    getTitle (item) {
      if (!item.address) {
        return ''
      }
      return `${item.address.street} ${item.address.number} ${item.address.number_addition}`
    },
    getDescription (item) {
      if (!item.value_high) {
        return ''
      }
      return `€ ${(item.value_high).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.')}`
    },
    getImage (item) {
      if (item.image) {
        return item.image + `?validate=true&key=${env.jumbaAPIKey}`
      }
      return ''
    },
    clicked () {
      this.$emit('clicked')
    }
  }
}
</script>
