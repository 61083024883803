<template>
  <div class="content">
    <div class="container">
      <div class="flex">
        <div class="main" v-if="this.isMobile">
          <div class="well">
            <p>Deze website is niet beschikbaar op mobiel.</p>
          </div>
        </div>
        <div class="main" v-if="!this.isMobile && !this.isSupportedBrowser">
          <a-row :gutter="50" class="pt-10">
            <a-col :sm="24">
              <h1>Browser not supported</h1>
              <p>We zien dat u een browser gebruikt die wij helaas niet ondersteunen. Kies één van de onderstaande
                browsers.</p>
            </a-col>
            <a-col :sm="12" :md="12" :lg="8" v-for="browser in browsers" :key="browser.name">
              <div :class="browsers.length > 1 ? 'cta center' : 'center'">
                <img :src="browser.logo" style="height: 160px; width: 160px"/>
                <h3>{{ browser.name }}</h3>
                <a-button class="primary" @click="getBrowser(browser)">
                  Downloaden
                </a-button>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="main" v-if="!this.isMobile && this.isSupportedBrowser">
          <a-row>
            <a-col :sm="24">
              <h1>Persoonlijke gegevens</h1>
              <p v-if="isHypotheekWacht()">
                De gegevens die wij nodig hebben halen we op uit de systemen van de Belastingdienst, MijnOverheid en
                Mijnpensioenoverzicht.nl. Zorg dat u uw DigiD inloggegevens en mobiele telefoon bij de hand heeft.
                Het ophalen duurt een paar minuten en voldoet aan alle veiligheid- en privacy-eisen van deze tijd.
              </p>
              <p v-else>
                De gegevens die wij nodig hebben, halen we op uit de systemen van bijvoorbeeld de overheid, UWV en de
                Belastingdienst. Zorg dat u uw DigiD inloggegevens en mobiele telefoon bij de hand heeft. Het ophalen
                duurt ongeveer 5 minuten en voldoet aan alle veiligheid- en privacy-eisen van deze tijd.
              </p>
              <div id="aqopi-frame">
                <div class="iframe_loading" v-if="iFrameLoading">
                  <a-spin size="large"/>
                </div>
                <iframe id="iwize-data-frame" allowtransparency="true"></iframe>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar">
          <side-nav step="aqopi" :loading="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getQueryList, getSupportedBrowsers, isHypotheekwacht, isMobile, router } from '@/helpers'
import { flag } from '@/helpers/axios'
import SideNav from '../../../components/SideNav/SideNav'
import { alertService, customerService } from '@/services'
import { hash } from '@/helpers/string'

export default {
  name: 'AQOPIComponent',
  components: { SideNav },
  data () {
    return {
      result: {},
      isSupportedBrowser: true,
      browsers: getSupportedBrowsers(),
      started: false,
      finished: false,
      isMobile: isMobile(),
      iFrameLoading: false
    }
  },
  computed: {
    ...mapState('survey', ['isHypotheekwacht']),
    data () {
      return {
        ...this.$store.state.survey,
        hbx: this.$store.state.auth.iwize_integration
      }
    }
  },
  mounted: function () {
    this.makeIframe()
  },
  methods: {
    ...mapActions('survey', ['submitAqopi', 'getCustomer', 'getHDNCompanies', 'getHDNCompanyLabels']),
    isHypotheekWacht () {
      return isHypotheekwacht(this.data.customer.invitation.type.value)
    },
    makeIframe () {
      const queryList = getQueryList(this.data.customer.invitation.type.value)
      const encodedSources = window.btoa(JSON.stringify(queryList)).replace(/=/g, '%3D')
      const style = JSON.stringify({
        color: '333333',
        'background-color': 'e8e8e8',
        'border-color': 'e8e8e8',
        'primary-color': this.data.style.primary.color.substr(1),
        'primary-background-color': this.data.style.primary.backgroundColor.substr(1)
      })
      let url = 'https://iwize.nl/dossierdata-web/index.html'
      url = `${url}?configs=${encodedSources}}`
      url = `${url}&use-stub=${this.$route.query.use_stub ? this.$route.query.use_stub : false}`
      url = `${url}&is-hypotheekwacht=${this.isHypotheekWacht()}`
      url = `${url}&ext=${flag()}`
      url = `${url}&dd_id=${hash(this.data.customer.id.value)}`
      if (this.data.customer.has_partner) {
        url = `${url}&dd_partner_id=${hash(this.data.customer.partner.id.value)}`
      } else {
        url = `${url}&dd_partner_id=null`
      }
      url = `${url}&kantoor=${this.data.customer.office ? encodeURI(this.data.customer.office.name.value) : ''}`
      url = `${url}&colors=${btoa(style)}`
      url = `${url}&show-test-page=${this.$route.query.test ? this.$route.query.test : false}`
      url = `${url}&hbx=${this.data.hbx === true}`

      this.iFrameLoading = true
      customerService.getIwizeToken(this.data.customer.invitation.id.value).then((result) => {
        if (!isHypotheekwacht(this.data.customer.invitation.type.value)) {
          url = `${url}&aanvrager-jwt=${result.data.data.token}`
        }
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.iFrameLoading = false
        document.getElementById('iwize-data-frame').src = url
        window.addEventListener('message', this.handleMessageEvent, false)
      })
    },
    handleMessageEvent (event) {
      if (event.origin === 'http://localhost:8080' || event.origin === 'https://iwize.nl') {
        if (event.data.type === 'NotAvailable') {
          alertService.onError('De dienst is tijdelijk niet beschikbaar')
        } else if (event.data.type === 'TechnicalError') {
          alertService.onError(event.data.message)
        } else if (event.data.type === 'BrowserNotSupported') {
          alertService.onError('Uw browser wordt niet ondersteund')
          this.isSupportedBrowser = false
        } else if (event.data.type === 'Canceled') {
          alertService.onError('De aanvraag is geannuleerd')
          router.push('/intro')
        } else if (event.data.type === 'Started') {
          this.started = true
        } else if (event.data.type === 'Success') {
          this.result = event.data
          this.submitAqopi(event.data)
          router.push('/personal_details')
        } else if (event.data.type === 'Finished') {
          this.result = event.data
          this.submitAqopi(event.data)
          router.push('/personal_details')
        }
      }
    },
    onDownload (e) {
      if (this.result && this.result.summaryPdf) {
        const link = document.createElement('a')
        link.download = 'overzicht-opgehaalde-gegevens.pdf'
        link.href = this.result.summaryPdf
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    getBrowser (browser) {
      const win = window.open(browser.url, '_blank')
      win.focus()
    },
    onPrevious (e) {
      router.push('/intro')
    },
    onSubmit () {
      if (this.result) {
        this.submitAqopi(this.result)
        router.push('/personal_details')
      }
    }
  }
}
</script>
