<template>
  <div class="content">
    <div class="login-bg" v-bind:style="{ backgroundImage: 'url(' + style.hero_image + ')' }">
      <div class="hero-image"></div>
    </div>
    <div class="login-block">
      <h1>Inloggen</h1>
      <form @submit.prevent="onLogin">
        <div class="form-group">
          <a-input type="text" :value="username" label="Gebruikersnaam" placeholder="Gebruikersnaam"
                   name="username" v-on:keyup.enter="onLogin" @input="setUsername"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !username }" autocomplete="off"/>
          <div v-show="submitted && !username" class="invalid-feedback error-text">Username is required</div>
        </div>
        <div class="form-group">
          <a-input type="password" :value="password" placeholder="Wachtwoord" name="password"
                   @input="setPassword" v-on:keyup.enter="onLogin"
                   :class="{ 'form-control': true, 'is-invalid': submitted && !password }" autocomplete="off"/>
          <div v-show="submitted && !password" class="invalid-feedback error-text">Password is required</div>
        </div>
        <div class="form-group" v-if="twoFactorEnabled">
          <a-input type="text" :value="twoFactorCode" placeholder="2FA code" name="twoFactorCode"
                   @input="setTwoFactorCode" v-on:keyup.enter="onLogin"
                   :class="{'form-control': true, 'is-invalid': submitted && (twoFactorEnabled && !twoFactorCode)}" autocomplete="off"/>
          <div v-show="submitted && (twoFactorEnabled && !twoFactorCode)" class="invalid-feedback error-text">2FA code is required</div>
        </div>
        <div class="form-group">
          <a-button :disabled="status.loading" @click="onLogin" class="inverted">
            <img v-show="status.loading" alt="Laden..." style="margin-right: 0.3rem"
                 src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
            Inloggen
          </a-button>
        </div>
        <div class="form-group link" @click="onForgotUsername">
          <span>Gebruikersnaam vergeten?</span>
        </div>
        <div class="form-group link" @click="onForgotPassword">
          <span>Wachtwoord vergeten?</span>
        </div>
        <div class="form-group link">
          &copy; {{ this.style.name }}
        </div>
        <div class="form-group link slogan" v-if="this.style.use_slogan">
          {{ this.style.slogan }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import '../../../services/auth.service.js'
import { mapActions, mapState } from 'vuex'
import { isMobile } from '../../../helpers'

export default {
  data () {
    return {
      username: this.$route.query.username,
      password: '',
      twoFactorCode: '',
      submitted: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState('auth', ['status', 'errors', 'twoFactorEnabled']),
    ...mapState('survey', ['style'])
  },
  mounted: function () {
    const token = this.$route.query.token

    if (token) {
      const redirectSuccess = this.$route.query.redirect_success ? decodeURI(this.$route.query.redirect_success) : null
      const redirectFailure = this.$route.query.redirect_fail ? decodeURI(this.$route.query.redirect_fail) : null
      const { twoFactorEnabled, twoFactorCode } = this
      this.login({
        token, redirectSuccess, redirectFailure, twoFactorEnabled, twoFactorCode
      })
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    onLoad (e) {
      const token = this.$route.query.token

      if (token) {
        const redirectSuccess = this.$route.query.redirect_success ? decodeURI(this.$route.query.redirect_success) : null
        const redirectFailure = this.$route.query.redirect_fail ? decodeURI(this.$route.query.redirect_fail) : null
        const { twoFactorEnabled, twoFactorCode } = this
        this.login({
          token, redirectSuccess, redirectFailure, twoFactorEnabled, twoFactorCode
        })
      }
    },
    setUsername (e) {
      this.username = e.target.value
    },
    setPassword (e) {
      this.password = e.target.value
    },
    setTwoFactorCode (e) {
      this.twoFactorCode = e.target.value
    },
    onForgotUsername (e) {
      this.$router.push('forgot_username')
    },
    onForgotPassword (e) {
      this.$router.push('forgot_password')
    },
    onLogin (e) {
      this.submitted = true
      const { username, password, twoFactorEnabled, twoFactorCode } = this
      if (username && password) {
        this.login({
          username,
          password,
          invitationToken: this.$route.query.invitation_token,
          redirectSuccess: decodeURI(this.$route.query.redirect_success),
          redirectFailure: decodeURI(this.$route.query.redirect_fail),
          twoFactorEnabled,
          twoFactorCode
        })
      }
    }
  }
}
</script>
